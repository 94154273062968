import io from "socket.io-client";
import { TEG_APP } from "lib/constants";

let BallSortSocket;
let currentGameId;
let sockId;

export const connectSocket = () => {
  BallSortSocket = io(TEG_APP.ENDPOINT);
  BallSortSocket.on('connect', () => {
    console.log("******************", BallSortSocket.id);
    console.log("My socket id is: ", BallSortSocket.id); // an alphanumeric id...
    sockId = BallSortSocket.id;
    console.log("****************** ", BallSortSocket.id);
 });
};

export const getSocetId = () => {
  // return BallSortSocket.id;
  return sockId;
};

export const joinMiniGame = (gameId) => {
  currentGameId = gameId
  let user = JSON.parse(localStorage.getItem("userInfo"))
  BallSortSocket.emit("join_minigame", gameId, "BallSort", user && user.firstName ? user.firstName : "user", () =>
    console.log(`Sent join emission to server.`)
  );
};

export const getUpdatedState = (GameScene) => {
  BallSortSocket.on("minigame_state_update", (data) => {
    // console.log(data)
    GameScene.updateVisibleCursors(data)
    // GameScene.updateScoreBoard(data.targetsHit)
    return data;
  });
};

export const sendCursorUpdate = (cursorData) => {
  BallSortSocket.emit("minigame_send_cursor_update", cursorData);
};

export const userStartedDraggingBall = (ballType) => {
    BallSortSocket.emit("user_started_dragging_ball", ballType);
};    

export const userStoppedDraggingBall = (ballType) => {
    BallSortSocket.emit("user_stopped_dragging_ball", ballType);
}; 

export const addBallToTube = (tubeId, ballType) => {
  BallSortSocket.emit("add_ball_to_tube", currentGameId, tubeId, ballType);
};  

export const releaseBallsFromTube = (tubeId) => {
  BallSortSocket.emit("release_balls_from_tube", currentGameId, tubeId);
}; 

export const ListenForBallAddedBySomeone = (GameScene) => {
  BallSortSocket.on("ball_added_to_tube_by_someone", (tubeIndex, ballType) => {
    GameScene.ballAddedToTubeBySomeone(tubeIndex, ballType)
  });
}

export const ListenForResetingBallLocation = (GameScene) => {
  BallSortSocket.on("reset_ball_sort_location", (ballType) => {
    GameScene.resetBallLocation(ballType)
  });
}


export const ListenForBallReleaseBySomeone = (GameScene) => {
  BallSortSocket.on("balls_released_by_someone", (tubeIndex) => {
    GameScene.ballReleasedBySomeone(tubeIndex)
  });
}


export const disconnect = () => {
  if (!BallSortSocket) return true;

  BallSortSocket.emit("disconnect_minigame", currentGameId, "BallSort");

  BallSortSocket.removeAllListeners();
  BallSortSocket.disconnect();
};

