import Phaser from "phaser";
import Ball from "./Ball";
import Tube from "./Tube";
import { Vector2 } from "three";
import * as BallSortSocket from "../ballSortSocket";

export default class BallSortGameScene extends Phaser.Scene {
    constructor() {
        super({ key: 'BallSortGameScene', active: false});
        console.log("GameScene constructor ball sort");
    }

    create() {
        console.log("create ball sort");
        let scene=this;
        this.canvas = this.sys.canvas;
        this.canvas.style.cursor = 'none';
        this.crossHair = this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, "crosshair");
        this.crossHair.setDepth(5); 
        this.input.on('pointermove', function (pointer) {
            scene.crossHair.x = pointer.x;
            scene.crossHair.y = pointer.y;
        });


        this.bg = this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, "bg");
        this.logo=this.add.image(this.game.renderer.width * 0.274, this.game.renderer.height * 0.148, "logo");
        this.ballsort=this.add.image(this.game.renderer.width*0.774, this.game.renderer.height/2, "ballsort").setDepth(1);
        this.box1=this.add.image(this.game.renderer.width*0.274, this.game.renderer.height*0.472, "boxB");
        this.box2=this.add.image(this.game.renderer.width*0.274, this.game.renderer.height*0.818, "boxB2");

        let dev=0.065;
        this.blueball=new Ball(this,this.game.renderer.width*0.111,this.game.renderer.height*0.501,"blueball").setDepth(2);
        this.greenball=new Ball(this,this.game.renderer.width*(0.111+dev),this.game.renderer.height*0.501,"greenball").setDepth(2);
        this.redball=new Ball(this,this.game.renderer.width*(0.111+dev*2),this.game.renderer.height*0.501,"redball").setDepth(2);
        this.yellowball=new Ball(this,this.game.renderer.width*(0.111+dev*3),this.game.renderer.height*0.501,"yellowball").setDepth(2);
        this.orangeball=new Ball(this,this.game.renderer.width*(0.111+dev*4),this.game.renderer.height*0.501,"orangeball").setDepth(2);
        this.pinkball=new Ball(this,this.game.renderer.width*(0.111+dev*5),this.game.renderer.height*0.501,"pinkball").setDepth(2);

        let tubeDev=0.05;
        this.tube1=new Tube(this,this.game.renderer.width*0.625,this.game.renderer.height*0.503,"tube","tubeH","release",0,this.game.renderer.height);
        this.tube2=new Tube(this,this.game.renderer.width*(0.625+tubeDev),this.game.renderer.height*0.503,"tube","tubeH","release",1,this.game.renderer.height);
        this.tube3=new Tube(this,this.game.renderer.width*(0.625+tubeDev*2),this.game.renderer.height*0.503,"tube","tubeH","release",2,this.game.renderer.height);
        this.tube4=new Tube(this,this.game.renderer.width*(0.625+tubeDev*3),this.game.renderer.height*0.503,"tube","tubeH","release",3,this.game.renderer.height);
        this.tube5=new Tube(this,this.game.renderer.width*(0.625+tubeDev*4),this.game.renderer.height*0.503,"tube","tubeH","release",4,this.game.renderer.height);
        this.tube6=new Tube(this,this.game.renderer.width*(0.625+tubeDev*5),this.game.renderer.height*0.503,"tube","tubeH","release",5,this.game.renderer.height);
        this.tube7=new Tube(this,this.game.renderer.width*(0.625+tubeDev*6),this.game.renderer.height*0.503,"tube","tubeH","release",6,this.game.renderer.height);

        this.balls=[this.blueball,this.greenball,this.redball,this.yellowball,this.orangeball,this.pinkball];
        this.tubes=[this.tube1, this.tube2, this.tube3, this.tube4, this.tube5, this.tube6, this.tube7];

        this.alreadyRenderedCursors = [];
        this.alreadyRenderedNames = [];
        this.updateCursor();

        // get user state after every 500 ms
        BallSortSocket.getUpdatedState(this);
        BallSortSocket.ListenForBallAddedBySomeone(this)
        BallSortSocket.ListenForBallReleaseBySomeone(this)
        BallSortSocket.ListenForResetingBallLocation(this)
    }

    checkBallOver(gameObject)
    {
        let index=this.findSelectedBall();
        if(index!==null)
        {
          gameObject.highLight();
            this.balls[index].onTube=true;
            this.balls[index].tube=gameObject;
        }
    }
    ballOut()
    {
        let index=this.findSelectedBall();
        if(index!==null)
        {
            this.balls[index].onTube=false;
        }
    }

    findSelectedBall()
    {
        for(var i=0;i<this.balls.length;i++)
        { 
          if(this.balls[i].isDragging && BallSortSocket.getSocetId() === this.balls[i].userSocketId)
            {
              return i;
            }
        }
        return null;
    }

    updateCursor = () => {
        this.cursorInterval = setInterval(() => {
          const cursorX = this.game.input.activePointer.x;
          const cursorY = this.game.input.activePointer.y;
    
          BallSortSocket.sendCursorUpdate(new Vector2(cursorX, cursorY));
        }, 400);
      };

    update() {

    }

    ballAddedToTubeBySomeone = (tubeIndex, ballType) => {
      const currBall = this.balls.find((ball) => ball.sprite === ballType);
        if(currBall){
          this.tubes[tubeIndex].dropBall(currBall.texture.key);
          this.isDragging=false;
          currBall.x=currBall.xVal;
          currBall.y=currBall.yVal;
          currBall.onTube=false;
          // currBall.ballDrop();
      }
    }

    resetBallLocation = (ballType) => {
      const currBall = this.balls.find((ball) => ball.sprite === ballType);
        if(currBall){
          currBall.isDragging=false;
          currBall.x=currBall.xVal;
          currBall.y=currBall.yVal;
      }
    }

    ballReleasedBySomeone = (tubeIndex) => {
      this.tubes[tubeIndex].tubeStack.release(this, this.tubes[tubeIndex].release)
    }

    updateVisibleCursors = (data) => {
        const scene = this
        let otherUsers = data.usersList.filter((user) => user.id !== BallSortSocket.getSocetId())
    
        // delete previously rendered cursors and user names
        while(scene.alreadyRenderedCursors.length > 0) {
          scene.alreadyRenderedCursors.pop().destroy(true);
          scene.alreadyRenderedNames.pop().destroy(true); // to add name as well
        } 
    
        otherUsers.forEach(function(user) {
          let xPos = user?.cursors?.x;
          let yPos = user?.cursors?.y;
          let name = user.name ? user.name : "user";
    
          if(xPos && yPos){
            let cursorImage = scene.add.sprite(xPos, yPos, 'crosshair')
            cursorImage.setDepth(5)

            // show  dragged balls 
            if(user.hasBall) { 
              const currBall = scene.balls.find((ball) => ball.sprite === user.ballType);
              if(currBall){
                currBall.x = xPos;
                currBall.y = yPos;
                currBall.isDragging=true;
              }
            }

            // if you want to add name as well
            let cursorText = scene.add.text(xPos + 20,yPos + 20, name, { fontFamily: "Arial", fontSize: 25, color: "red" })
            cursorText.setDepth(5)
    
            scene.alreadyRenderedCursors.push(cursorImage)
            scene.alreadyRenderedNames.push(cursorText) // to add name as well
          }
        })
      }

      cleanup() {
        clearInterval(this.cursorInterval)
      }
}

