import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import {
  Route
} from 'react-router-dom';
import {getCookie} from '../common/utils/auth'
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "../redux/actionCreators";

function PrivateRoute({state, user, history, component: Component, ...rest}) {
  const [isVerified, setIsVerified] = useState(false);

  const userState = useSelector(state => state.userReducer);
  const dispatch = useDispatch();

  function verify_user() {
    setIsVerified(true);
    const cookie = getCookie('token');

    if (!cookie) {
      history.push('/login');
    }
    return true;
  }

  if (!isVerified) {
    verify_user();
    return <div>Please wait...</div>
  }
  return (
    <Route
      {...rest}
      render={props => <Component {...props} />
      }
    />
  );
}

export default withRouter(PrivateRoute);
