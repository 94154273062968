import {LIST_STORES, STORE_SUCCESS, STORE_ERROR} from "../actions";

const initialState = {
  stores: [],
  isLoading: false,
  error: null
};

export function storeReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_STORES:
      return {
        stores: [],
        isLoading: true,
        error: null
      };
    case STORE_SUCCESS:
      return {
        stores: action.payload.stores || state.stores,
        isLoading: false,
        error: null
      };
    case STORE_ERROR:
      return {
        stores: [],
        isLoading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}
