/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  FormControl,
  Button,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ajax } from "rxjs/ajax";
import { config } from "../../../enviroment";
import {
  setCookie,
  getCookie,
  eraseCookie,
  PLAYER_COOKIE_PREFIX,
} from "../../../lib/data";
import { getCountries } from "../../../redux/actionCreators";
import { Typeahead } from "react-bootstrap-typeahead";
import OMITBLogin from "../Themes/OMITB/Login";

// Styles
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./PlayerAuthModule.scss";

import { count } from "rxjs/operators";
import getThemeSettings from 'Pages/PlayerDashboard/themeSettings.js'
import mixpanel from "lib/mixpanel"
import { useFlags } from 'launchdarkly-react-client-sdk'


const PlayerAuthModule = (props) => {
  const params = new URLSearchParams(window.location.search)
  const { playerLoginPassword } = useFlags()
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    pass: playerLoginPassword ? "" : (params.get('auth') || ""),
    receiveEmails: true,
    terms: false,
  });
  const [isSubmitted, setSubmitted] = useState(false);
  const [isPasswordCorrect, setPasswordCorrect] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  useEffect(() => {
    window.d_site = window.d_site || 'D07134CC7090CA23F925DFCD'
    const script = document.createElement('script')
    script.src = 'https://widget.privy.com/assets/widget.js'
    script.async = true
    script.defer = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, []);

  const onFormUpdate = (e) => {
    let value =
      ['receiveEmails', 'terms'].includes(e.target.name)
        ? e.target.checked
        : validateDuringTyping(e.target.name, e.target.value);

    setFormData({ ...formData, [e.target.name]: value });
  };

  const validateDuringTyping = (name, value) => {
    const re = /^[A-Za-z0-9]+$/
    switch (name) {
      case "pass":
        setPasswordCorrect(true);
        return (re.test(value) || value === '') && value.length <= 4 ? value.toUpperCase() : formData.pass;
      case "postalCode":
        return (re.test(value) || value === '') && value.length <= 8 ? value.toUpperCase() : formData.postalCode;
      default:
        return value;
    }
  };

  const validateAfterSubmission = () => {
    const keys = Object.keys(formData);
    for (let i in keys) {
      if (!isValid(keys[i])) {
        return false;
      }
    }
    return true;
  };

  const isValid = (name) => {
    switch (name) {
      case "email":
        if (formData[name]) {
          const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(formData[name]);
        } else {
          if (props.theme === 'omitb') {
            if (!formData[name]) return false
            return formData[name].length > 0;
          }
        }
      case "pass":
        return playerLoginPassword ? (formData[name].length === 4) : true
      case "receiveEmails":
        return true;
      case "postalCode":
        if (props.theme === 'omitb') {
          if (!formData[name]) return false
          return formData[name].length > 0;
        }
        return true;
      case "firstName":
        if (props.theme === 'omitb') {
          if (!formData[name]) return false
          return formData[name].length > 0;
        }
        return true;
      case "lastName":
        if (props.theme === 'omitb') {
          if (!formData[name]) return false
          return formData[name].length > 0;
        }
        return true;
      case "terms":
        return formData[name];
      case "country":
        if (props.theme === 'omitb') return true
        return formData[name].length > 0;
      default:
        return formData[name].length > 0;
    }
  };

  const onSubmit = () => {
    setSubmitted(true);
    const areAllValuesCorrect = validateAfterSubmission();
    if (areAllValuesCorrect) {
      setLoading(true);
      const header = { "Content-Type": "application/json" };
      ajax
        .post(`${config.raUrl}/game/login/${props.gameId}`, formData, header)
        .subscribe(
          (data) => {
            const { response } = data;
            if (!response.success) {
              setPasswordCorrect(false);
              console.log(response.message);
            } else {
              props.setAuthenticated(true);
              setCookiesForLogin(response.customerId);
            }
            localStorage.setItem('userInfo', JSON.stringify(formData))
            setLoading(false);
          },
          (err) => {
            console.log(err.response);
            setLoading(false);
          }
        );
    }
  };

  const setCookiesForLogin = (customerId) => {
    eraseCookie(`${PLAYER_COOKIE_PREFIX}_auth`);
    eraseCookie(`${PLAYER_COOKIE_PREFIX}_name`);
    eraseCookie(`${PLAYER_COOKIE_PREFIX}_email`);
    eraseCookie(`${PLAYER_COOKIE_PREFIX}_customer_id`);
    setCookie(
      `${PLAYER_COOKIE_PREFIX}_auth`,
      `${props.gameId}_${formData.pass}`
    );
    setCookie(`${PLAYER_COOKIE_PREFIX}_name`, `${formData.firstName}`);
    setCookie(`${PLAYER_COOKIE_PREFIX}_email`, `${formData.email}`);
    setCookie(`${PLAYER_COOKIE_PREFIX}_customer_id`, customerId);
  };

  const themeSettings = getThemeSettings(props.theme)
  const containerStyles = (theme) => {
    const bgImage = theme === 'rugrats'
      ? `url(${themeSettings.playerLoginBg})`
      : `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${themeSettings.playerLoginBg})`
    return {
      backgroundImage: bgImage,
    }
  }
  
  if (props.theme === 'omitb') return (
    <OMITBLogin
      isPasswordCorrect={isPasswordCorrect}
      playerLoginPassword={playerLoginPassword}
      formData={formData}
      setFormData={setFormData}
      onFormUpdate={onFormUpdate}
      isSubmitted={isSubmitted}
      isValid={isValid}
      onSubmit={onSubmit}
      isLoading={isLoading}
      countries={userState.countries}
    />
  )
  return (
    <div className={`player-auth-container ${props.theme}`} style={containerStyles(props.theme)}>
      {props.theme === 'rugrats' &&
        <img id="dino" src={themeSettings.playerLoginDino} />
      }
      <div className="player-auth">
        <div className="logo-container">
          <img src={themeSettings.playerLoginLogo} />
        </div>
        <div className="form-container" style={{position:'relative'}}>
          {(!isPasswordCorrect && !playerLoginPassword) &&
            <div style={{color:'red', position:'absolute', left:'50%', transform:'translateX(-50%)', top:'-30px'}}>Incorrect Player Password</div>
          }
          <Row>
            <Col>
              <InputGroup>
                <FormControl
                  placeholder={`First Name`}
                  aria-label="firstName"
                  aria-describedby="basic-addon1"
                  name="firstName"
                  value={formData.firstName}
                  onChange={(e) => onFormUpdate(e)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup>
                <OverlayTrigger
                  key={"email-validation"}
                  placement={"right"}
                  overlay={
                    isSubmitted && !isValid("email") ? (
                      <Tooltip
                        className="validation-tooltip"
                        id={`tooltip-right`}
                      >
                        Email must be a valid <strong>email address</strong>
                      </Tooltip>
                    ) : (
                      <Tooltip className="d-none"></Tooltip>
                    )
                  }
                >
                  <FormControl
                    placeholder={`Email`}
                    aria-label="email"
                    aria-describedby="basic-addon1"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={(e) => onFormUpdate(e)}
                    isInvalid={isSubmitted && !isValid("email")}
                  />
                </OverlayTrigger>
              </InputGroup>
            </Col>
          </Row>
          {playerLoginPassword &&
            <Row>
              <Col>
                <InputGroup>
                  <OverlayTrigger
                    key={"fname-validation"}
                    placement={"right"}
                    overlay={
                      isSubmitted && (!isValid("pass") || !isPasswordCorrect) ? (
                        <Tooltip
                          className="validation-tooltip"
                          id={`tooltip-right`}
                        >
                          {!isValid("pass") ? (
                            <span>
                              <strong>Password</strong> is a required field.
                            </span>
                          ) : !isPasswordCorrect ? (
                            "That password doesn�t seem to be correct. Try again!"
                          ) : (
                            ""
                          )}
                        </Tooltip>
                      ) : (
                        <Tooltip className="d-none"></Tooltip>
                      )
                    }
                  >
                    <FormControl
                      placeholder={`4-Digit Game Password *`}
                      aria-label="pass"
                      aria-describedby="basic-addon1"
                      name="pass"
                      value={formData.pass}
                      onChange={(e) => onFormUpdate(e)}
                      isInvalid={
                        isSubmitted && (!isValid("pass") || !isPasswordCorrect)
                      }
                    />
                  </OverlayTrigger>
                </InputGroup>
              </Col>
            </Row>
          }
          <Row className="justify-content-md-center mt-3">
            <Col>
              <InputGroup>
                <Form.Group controlId="formBasicCheckbox" className="m-0">
                  <Form.Check
                    type="checkbox"
                    label="Sign me up to receive email updates from The Escape Game including new games, locations and products."
                    name="receiveEmails"
                    checked={formData.receiveEmails}
                    onChange={(e) => onFormUpdate(e)}
                  />
                </Form.Group>
              </InputGroup>
            </Col>
          </Row>
          <Row className="justify-content-md-center mt-0">
            <Col>
              <InputGroup>
                <Form.Group controlId="termsCheckbox" className="m-0 d-flex align-items-center">
                  <Form.Check>
                    <Form.Check.Input
                      type="checkbox"
                      name="terms"
                      checked={formData.terms}
                      onChange={(e) => onFormUpdate(e)}
                      isInvalid={isSubmitted && !isValid("terms")}
                    />
                    <Form.Check.Label>I agree to The Escape Game’s <a className="text-danger" href="https://theescapegame.com/privacy-policy" target="_blank">Privacy Policy</a> and <a className="text-danger" href="https://theescapegame.com/terms" target="_blank">Terms of Service</a>.</Form.Check.Label>
                    <Form.Control.Feedback type="invalid">You must agree before submitting.</Form.Control.Feedback>
                  </Form.Check>
                </Form.Group>
              </InputGroup>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Button onClick={onSubmit} disabled={isLoading}>
              {isLoading ? "SUBMITTING" : "SUBMIT"}
            </Button>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default PlayerAuthModule;
