import Phaser from "phaser";
import GolfBall from "./GolfBall";
import * as PuttPuttSocket from "../puttPutSocket";
import { Vector2 } from "three";

export default class PuttGameScene extends Phaser.Scene {
    constructor() {
        super({ key: 'PuttGameScene', active: false});
        console.log("Putt constructor");
    }

    create() {
        let scene=this;
        // console.log(game);
        this.isRunning=true;
       /*  this.canvas = this.sys.canvas;
        this.canvas.style.cursor = 'none';
        this.crossHair = this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, "crosshair");
        this.crossHair.setDepth(5); 
        this.input.on('pointermove', function (pointer) {
            scene.crossHair.x = pointer.x;
            scene.crossHair.y = pointer.y;
        }); */

        this.form = this.add.dom().createFromCache("dom");
        this.form.originX = 0;
        this.form.originY = 0;

        this.bg = this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, "bg");
        this.logo=this.add.image(this.game.renderer.width * 0.218, this.game.renderer.height * 0.148, "logo");
        this.course=this.add.image(this.game.renderer.width*0.719, this.game.renderer.height*0.5, "course");

        this.bottomWall=this.physics.add.staticImage(1643, 1059, "bottomWall").setScale(1.1,5);
        this.bottomWall.refreshBody();
        this.bottomWall.alpha=0.001;
        this.rightWall=this.physics.add.staticImage(1899, 540, "rightWall").setScale(5,1.1);
        this.rightWall.refreshBody();
        this.rightWall.alpha=0.001;
        this.topWall=this.physics.add.staticImage(1380, 20, "topWall").setScale(1.1,5);
        this.topWall.refreshBody();
        this.topWall.alpha=0.001;
        this.leftBottomWall=this.physics.add.staticImage(1387, 805, "leftBottomWall").setScale(5,1.1);
        this.leftBottomWall.refreshBody();
        this.leftBottomWall.alpha=0.001;
        this.middleWall=this.physics.add.staticImage(1100, 535, "middleWall").setScale(1.1,5);
        this.middleWall.refreshBody();
        this.middleWall.alpha=0.001;
        this.leftTopWall=this.physics.add.staticImage(861, 278, "leftTopWall").setScale(5,1.1);
        this.leftTopWall.refreshBody();
        this.leftTopWall.alpha=0.001;
        

        this.bridgeWallTop=this.physics.add.staticImage(1390, 137, "bridgeTop");
        this.bridgeWallTop.alpha=0.001;
        this.bridgeWallBottom=this.physics.add.staticImage(1390, 258, "bridgeBottom");
        this.bridgeWallBottom.alpha=0.001;
        this.riverBottom=this.physics.add.staticImage(1386, 390, "riverBottom");
        this.riverBottom.alpha=0.001;
        this.riverBottomLeft=this.physics.add.staticImage(1310, 325, "riverBottomLeft");
        this.riverBottomLeft.alpha=0.001;
        this.riverBottomRight=this.physics.add.staticImage(1470, 330, "riverBottomLeft");
        this.riverBottomRight.alpha=0.001;
        this.riverTopRight=this.physics.add.staticImage(1470,102, "riverTopRight");
        this.riverTopRight.alpha=0.001;
        this.riverTopLeft=this.physics.add.staticImage(1310,102, "riverTopLeft");
        this.riverTopLeft.alpha=0.001;
        this.riverTop=this.physics.add.staticImage(1386,80, "riverTop");
        this.riverTop.alpha=0.001;

        this.hole=this.physics.add.staticImage(1035, 283,"hole");
        this.hole.body.setSize(20, 20, 1, 0);
        this.hole.alpha=0.001;

        this.tree=this.physics.add.staticImage(1825, 965,"hole").setScale(2,2);
        this.tree.alpha=0.001;
        this.tree2=this.physics.add.staticImage(1440, 665,"hole").setScale(2,2);
        this.tree2.alpha=0.001;
        this.tree3=this.physics.add.staticImage(1455, 600,"hole").setScale(2,2);
        this.tree3.alpha=0.001;
        this.tree4=this.physics.add.staticImage(1720, 510,"hole").setScale(2,2);
        this.tree4.alpha=0.001;
        this.tree5=this.physics.add.staticImage(1500, 285,"hole").setScale(2,2);
        this.tree5.alpha=0.001;
        this.tree6=this.physics.add.staticImage(1275, 300,"hole").setScale(2,2);
        this.tree6.alpha=0.001;
        this.tree7=this.physics.add.staticImage(1245, 105,"hole").setScale(2,2);
        this.tree7.alpha=0.001;


        this.walls=[this.bottomWall,this.rightWall,this.topWall,this.leftBottomWall,this.middleWall,this.leftTopWall,this.bridgeWallTop,this.bridgeWallBottom,this.riverBottom,this.riverBottomLeft,this.riverBottomRight,this.riverTop,this.riverTopLeft,this.riverTopRight,this.triangle1,this.tree,this.tree2,this.tree3,this.tree4,this.tree5,this.tree6,this.tree7];

        this.box1=this.add.image(this.game.renderer.width*0.218, this.game.renderer.height*0.413, "boxE");

        this.blueBox=this.add.image(this.game.renderer.width*0.352, this.game.renderer.height*0.413, "blueBox");
        this.swing1=this.add.image(this.game.renderer.width*0.327, this.game.renderer.height*0.443, "swing");
        this.swing2=this.add.image(this.game.renderer.width*0.351, this.game.renderer.height*0.443, "swing");
        this.swing3=this.add.image(this.game.renderer.width*0.375, this.game.renderer.height*0.443, "swing");
        this.swings=[this.swing1,this.swing2,this.swing3];
        this.swingIndex=0;
        this.user = null


        // this.box2=this.add.image(this.game.renderer.width*0.218, this.game.renderer.height*0.684, "boxP");
        this.box2=this.add.image(this.game.renderer.width*0.218, this.game.renderer.height*0.684, "boxE");


        this.ball= new GolfBall(this,this.game.renderer.width*0.854, this.game.renderer.height*0.910, "ball","power");

        this.input.on('pointermove',function(pointer){
            let forceX=pointer.x-scene.ball.x;
            let forceY=pointer.y-scene.ball.y;
            scene.ball.updateForces(forceX,forceY);

        });
        this.input.on('pointerdown',()=>{
            if(this.ball.body.speed==0)
            {
                this.ball.shootBall();
            }
        });

        this.physics.add.collider(this.ball,this.walls);
        this.physics.add.overlap(this.ball,this.hole,this.enterHole);

        this.updateBallLocation();

        PuttPuttSocket.gameWon(this); // when game is won by some other user
        PuttPuttSocket.onTurnChange(this);
        PuttPuttSocket.anotherUserHadAShot(this);

        // get user state after every 500 ms
        PuttPuttSocket.getUpdatedState(this);
    }

    updateBallLocation = () => {
        // this.cursorInterval = setInterval(() => {
        //   const cursorX = this.ball.x;
        //   const cursorY = this.ball.y;
    
        //   PuttPuttSocket.sendBallLocation(new Vector2(cursorX, cursorY));
        // }, 400);
      };

    SomeoneHadAShot = (forceX, forceY) => {
        console.log("someone took a shot:", forceX, forceY)
        // this.ball.updateForces(forceX,forceY);
        this.ball.shootBallBySomeoneElse(forceX,forceY);
    };


    update() {
        if(this.isRunning)
        {
            if(this.ball.body.speed < 50 && this.ball.body.speed != 0)
            {
                this.swing();
                this.ball.body.stop();
                this.ball.power.setVisible(true);
            }
            this.ball.power.setPosition(this.ball.x,this.ball.y);
        }
        
    }

    enterHole(ball,hole)
    {
        if(ball.body.speed<1500)
        {
            clearInterval(this.cursorInterval)
            ball.win();
            PuttPuttSocket.gameCompleted()
        }
    }

    swing()
    {
        // this.swingIndex++;
        PuttPuttSocket.registerUserSwing()
        if(this.swingIndex==this.swings.length)
        {
            this.nextTurn();
            // this.resetSwings();
        }
    }
    swingByOtherUser() {
        // if(this.swingIndex === 3) {
        //     this.swingIndex = 0; // reset swing index
        // }
        this.swings[this.swingIndex].setTexture('swingSpent');
        // this.swingIndex++;
    }
    nextTurn()
    {
        console.log("NEXT TURN");
        this.ball.isEnabled=false;
    }
    resetSwings()
    {
        for(var i=0;i<this.swings.length;i++)
        {
            this.swings[i].setTexture("swing");
        }
        this.swingIndex=0;
    }

    updateGameState = (data) => { // updated game state from socket
        const scene = this
        if(!data) return
        let activeUser = data.users.find((user) => user.hasTurn === true)
        let user = data.users.find((user) => user.id === PuttPuttSocket.getSocetId())

        scene.user = user
        if(data.gameStatus === "finished") {
            clearInterval(this.cursorInterval)
            PuttPuttSocket.disconnect()
        }

        if(!activeUser){
            scene.ball.isEnabled = false
        } else if(data.gameStatus === "active") {
            if(data?.turnNumber === 0){
                this.resetSwings()
            }
            if(data?.turnNumber !== scene.swingIndex){
                scene.swings[scene.swingIndex].setTexture('swingSpent');
            }
            scene.swingIndex = data.turnNumber

            this.form.getChildByID("swingsLeft").innerHTML = `${activeUser.name} has ${3-data?.turnNumber} swings left`
            this.form.getChildByID("current").innerHTML = `${activeUser.name} is putting.`
            let nextUser = this.findNextUser(data)
            if(nextUser){
                this.form.getChildByID("next").innerHTML = `${nextUser.name} is up next` 
            }

            // enable swing if current user is you
            if(activeUser.id === PuttPuttSocket.getSocetId()) {
                scene.ball.isEnabled = true
            } else {
                scene.ball.isEnabled = false
            }

            // update ball location if some other user is playing -- 
            // *********************************
            /*if(activeUser.id !== PuttPuttSocket.getSocetId() && data.ballLocation) {
                this.ball.x = data.ballLocation.x
                this.ball.y = data.ballLocation.y
            }*/
            // *********************************
        }
    }

    findNextUser = (data) => {
        if(data.users.length === 1) { // only one user
            return null
        }
        let activeUserIndex = data.users.findIndex((user) => user.hasTurn === true)

        if(activeUserIndex === data.users.length-1 && data.users.length !== 1) { // last user
            return data.users[0] // first user  gets the turn again
        }
        else if(data.users[activeUserIndex+1]) {
            return data.users[activeUserIndex+1]
        }
    }

    resetBallLocation = () => {
        // reset ball location when 1st turn
        this.resetSwings()
        this.ball.stopBall();
        this.ball.x = this.game.renderer.width*0.854
        this.ball.y = this.game.renderer.height*0.910
    }

    onGameWon = (winner) => {
        if(this.cursorInterval) {
            clearInterval(this.cursorInterval)
            PuttPuttSocket.disconnect()
        }
        this.isRunning=false;
        this.scene.start('End', {
            winner: winner?.name
        });
        // this.destroy();
    }

    cleanup() {
        clearInterval(this.cursorInterval)
      }
}

