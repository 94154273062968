
export const formatTimer = (time) => {
	let minutes = Math.floor(time / 60)
	if (minutes < 10) {
			minutes = `0${minutes}`
	}
	let seconds = time % 60;
	if (seconds < 10) {
			seconds = `0${seconds}`
	}
	return `${minutes}:${seconds}`
}

export const getInitialTimerState = (timerEvents, startTime = 3600) => {
	let newTimeRemaining = startTime
	let lastStart = null
	let timerState = true
	timerEvents.every(timerEvent => {
		let timeRemainingChange = 0
		if (timerEvent.event === 'start') {
			lastStart = timerEvent.timestamp
		} else if (timerEvent.event === 'pause') {
			timeRemainingChange = (timerEvent.timestamp - lastStart)/1000
			lastStart = null
		} else if (timerEvent.event === 'timerUpdated') {
			timeRemainingChange = timerEvent.change*-1
		}
		newTimeRemaining = newTimeRemaining - timeRemainingChange
		return true
	})
	if (lastStart) {
		newTimeRemaining = newTimeRemaining - (Date.now() - lastStart)/1000
		timerState = false
	}
	return [Math.max(0, Math.round(newTimeRemaining)), timerState]
}
