import Phaser from "phaser";
import Block from "./Block";

export default class Quadrant extends Phaser.GameObjects.Container {
    constructor(scene, x, y,sprite,size,id) {
      super(scene, x, y);
      this.scene = scene;
      this.sprite=sprite;
      this.x = x;
      this.y = y;
      this.quadDev=85;
      this.quadId=id;
      this.quad=new Array(size*size);
 
      var index=0;
      for(var i=0;i<size;i++)
      {
          for(var j=0;j<size;j++)
          {
            this.quad[index]=new Block(this.scene,this.quadDev*j,this.quadDev*i,this.sprite,this.quadId,index);
            index++;
          }
      }
      this.add(this.quad);

      scene.add.existing(this);
  

    }
  }
  