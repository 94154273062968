import React from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import './Profile.scss';

const SettingsHeader = ({ history, selected }) => {
  const home = () => {
    history.push("/settings/password");
  };

  return (
    <div className="settings-tabs-container">
      <Navbar bg="light" variant="light">
        <Nav variant="pills" defaultActiveKey="/settings">
          <Nav.Item>
            <Nav.Link active={selected === 1} onClick={() => history.push("/settings/password")}>
              Password
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link active={selected === 2} onClick={() => history.push("/settings/xola")}>
              Xola
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar>
    </div>
  );
};

export default SettingsHeader;
