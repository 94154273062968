import {mergeMap, map, catchError} from 'rxjs/operators';
import { from } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';

import {
 LIST_ROOMS
} from '../actions/roomActions';
import {roomSuccess, roomError, userSuccess, userError} from '../actionCreators';
import {config} from '../../enviroment';
import {getCookie, setCookie} from '../../common/utils/auth';
const header = { 'Content-Type': 'application/json' };
const getAuth = () => {
  return {
    ...header,
    'Authorization': `Bearer ${getCookie('token')}`
  }
}

export const listRoomEpic = action$ => action$.pipe(
  ofType(LIST_ROOMS),
  mergeMap((action) => from(new Promise((resolve, reject) => {
    ajax.getJSON(`${config.raUrl}/experiences/${action.payload.storeId}`, getAuth())
      .subscribe((userResponse) => {
        resolve(userResponse);
        action.payload.onComplete(userResponse);
      }, (err) => {
        reject(err.response);
      })
  })).pipe(
    map(response =>  roomSuccess(response)),
    catchError(error => Promise.resolve(roomError(error)))
  ))
)
