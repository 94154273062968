import React, { useEffect } from "react";
import BlockGridGame from "./phaser/";
import * as BlockGridSocket from "./blockGridSocket";

export default function BlockGrid(props) {
  const { gameId } = props

  useEffect(() => {
    BlockGridSocket.connectSocket();
    BlockGridSocket.joinMiniGame(gameId)
    const blockGridGame = new BlockGridGame() 
    return () => {
      blockGridGame.cleanup()
      BlockGridSocket.disconnect()
    }
  }, [gameId])

  return (
    <div id="minigame_container"></div>
  )
}
