import { TEG_APP } from "./constants";

export const fetchAssets = async (gameId) => {
  try {
    const getAssets = await fetch(`${TEG_APP.ENDPOINT}/api/assets/${gameId}`)
    const assets = await getAssets.json()
    return assets
  } catch (err) {
    console.log(err);
  }
}

export const fetchGameAndSetGameMedia = async (gameId, setGameMedia, itemGrid = false) => {
  if (itemGrid) {
    return fetchGameDataItemGrid(gameId, setGameMedia)
  }
  // fetchGameDataV1(gameId, setGameMedia)
  fetchGameDataV2(gameId, setGameMedia)
}

export const fetchTrackingData = async (eventId, setTracking) => {
  const trackingUnparsed = await fetch(`${TEG_APP.ENDPOINT}/api/tracking/${eventId}`);
  const tracking = await trackingUnparsed.json()
  setTracking(tracking)
}

export const fetchGameDataV1 = async (gameId, setGameMedia) => {
  try {
    const getGameRes = await fetch(`${TEG_APP.ENDPOINT}/api/game/${gameId}`);
    const getGameInventoryRes = await fetch(
      `${TEG_APP.ENDPOINT}/api/inventory/${gameId}`
    );
    const game = await getGameRes.json();
    const inventory = await getGameInventoryRes.json();

    const baseInventory = inventory.filter(
      (item) => item.inventoryType === "base"
    );
    const lockInventory = inventory.filter(
      (item) => item.inventoryType === "lock"
    );
    const foundedToys = inventory.filter(
      (item) => item.inventoryType === "rugrats-toys"
    );

    setGameMedia({
      images: game.assets.images,
      baseInventory,
      lockInventory,
      foundedToys,
      scans: game.assets.scans,
      videos: game.assets.videos,
      sounds: game.assets.sounds,
      minigames: game.assets.minigames,
      playerPassword: game.player_password,
      tracking: game.tracking,
      customTheming: JSON.parse(game.custom_theming),
      starttime: game.starttime
    });
  } catch (err) {
    console.log(err);
  }
}

export const fetchGameDataV2 = async (gameId, setGameMedia) => {
  try {
    const getGameRes = await fetch(`${TEG_APP.ENDPOINT}/api/gameData/${gameId}`);
    const game = await getGameRes.json();

    const baseInventory = game.inventory.filter(
      (item) => item.inventoryType === 1 // "base"
    );
    const lockInventory = game.inventory.filter(
      (item) => item.inventoryType === 2 // "lock"
    );
    const foundedToys = game.inventory.filter(
      (item) => item.inventoryType === 501 // "rugrats-toys"
    );

    setGameMedia({
      baseInventory,
      lockInventory,
      foundedToys,
      scans: game.assets.scans,
      videos: game.assets.videos,
      sounds: game.assets.sounds,
      minigames: game.assets.minigames,
      playerPassword: game.player_password,
      tracking: game.tracking,
      customTheming: JSON.parse(game.custom_theming),
      starttime: game.starttime
    });
  } catch (err) {
    console.log(err);
  }
}

export const fetchGameDataItemGrid = async (gameId, setGameMedia) => {
  try {
    const getGameRes = await fetch(`${TEG_APP.ENDPOINT}/api/game/${gameId}`);
    const getGameInventoryRes = await fetch(
      `${TEG_APP.ENDPOINT}/api/inventory/${gameId}`
    );
    const assets = await fetchAssets(gameId);
    const game = await getGameRes.json();
    const inventory = await getGameInventoryRes.json();

    const foundedToys = inventory.filter(
      (item) => item.inventoryType === "rugrats-toys"
    );
    let baseInventory = [], lockInventory = [], scans = [], videos = [], minigames = []
    assets.items.forEach((item) => {
      if (item.item_type === 'inventory') baseInventory.push({
        data: { src: item.src_url, lowResSrc: "" },
        displayName: item.label_external,
        id: item.id,
        internalName: item.label_internal,
        inventoryType: 'base',
        mediaType: 'image'
      })
      if (item.item_type === 'lock') lockInventory.push({
        data: { src: item.src_url, lowResSrc: "" },
        displayName: item.label_external,
        id: item.id,
        internalName: item.label_internal,
        inventoryType: 'lock',
        mediaType: 'image'
      })
      if (item.item_type === 'scan') scans.push({
        data: { src: item.src_url, name: item.label_external, thumbnail: item.icon_url },
        id: item.id,
      })
      if (item.item_type === 'video') videos.push({
        data: { src: item.src_url, name: item.label_external },
        id: item.id,
        type: 'video'
      })
      if (item.item_type === 'minigame') minigames.push({
        data: { src: item.src_url, name: item.label_external },
        id: item.id,
        type: 'minigame'
      })
    })

    setGameMedia({
      images: game.assets.images,
      baseInventory,
      lockInventory,
      foundedToys,
      scans: scans,
      videos: videos,
      minigames: minigames,
      sounds: game.assets.sounds,
      playerPassword: game.player_password,
      tracking: game.tracking,
      customTheming: JSON.parse(game.custom_theming),
      starttime: game.starttime
    });
    return assets
  } catch (err) {
    console.log(err);
  }
}

export const verifyHost = async (gameId, hostPassword) => {
  try {
    const getGameRes = await fetch(`${TEG_APP.ENDPOINT}/api/verify/${gameId}?auth=${hostPassword}`);
    const game = await getGameRes.json()
    return { isValid: !!game[0][0], token: game.token };
  } catch (err) {
    return false
  }
};

export const getGameData = async (gameId, hostPassword) => {
  try {
    const getGameRes = await fetch(`${TEG_APP.ENDPOINT}/api/verify/${gameId}?auth=${hostPassword}`);
    const game = await getGameRes.json()
    
    return game;
  } catch (err) {
    return false
  }
};

export const validatePlayer = async (gameId, gamePass, host_token='') => {
  try {
    let url = `${TEG_APP.ENDPOINT}/api/game/validate/${gameId}?auth=${gamePass}`;
    if(host_token !== '') {
      url += `&host_token=${host_token}`
    }
    const getGameRes = await fetch(
      url
    );
    const game = await getGameRes.json();
    return game;
  } catch (err) {
    console.log(err);
  }
};

export const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};
export const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
export const eraseCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const PLAYER_COOKIE_PREFIX = "escp_player";
