export const setCookie = (cookieName, cookieValue, exdays) => {
  const currentDate = new Date();
  currentDate.setTime(currentDate.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = 'expires=' + currentDate.toUTCString();
  document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/';
}
export const getCookie = (cookieName) => {
  const name = cookieName + '=';
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
}
export const removeCookie = () => {
  document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = 'id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}
