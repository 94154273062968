import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Table, Button } from 'react-bootstrap';
import {  TEG_APP } from '../../lib/constants'
import { useLocation } from 'react-router-dom'
import './GuideGameOverview.scss';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const GuideGameOverview  = (props) => {
    // const ENDPOINT = 'http://localhost:5000';
   // const ENDPOINT = 'https://api-remoteadventures-stag.theescapegame.com';
    const query = useQuery();
    const ENDPOINT = TEG_APP.ENDPOINT;
    const today = moment().subtract(1.5, 'hours').format('YYYY-MM-DD HH:mm:ss');
    const [games, setGames] = useState([]);

    useEffect(() => {
        let dateParam = '';
        if (query.get('date') && query.get('date') !== '') {
            dateParam = '?date='+query.get('date');
        }
        axios.get(`${ENDPOINT}/api/games${dateParam}`).then((data) => {
            setGames(data.data);
            console.log(data.data);
        });
    }, [today]);

    return(
        <Table>
            <thead>
                <tr>
                    <th>Store</th>
                    <th>Experience</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Host Dashboard</th>
                    <th>Player Dashboard</th>
                    <th>Meeting Link</th>
                </tr>
            </thead>
            <tbody>
                {games.map((game, i) => (
                    <tr key={i}>
                        <td>{game.title}</td>
                        <td>{game.store_identity}</td>
                        <td>{moment(game.arrival).format('MM-DD-YYYY')}</td>
                        <td>{moment(game.arrival).utc().format('hh:mm A')}</td>
                        {/*<td><Button href={`https://remoteadventures.theescapegame.com/guide/${game.x_event_id}`} target="_blank">Host Dashboard</Button></td>*/}
                        {/*<td><Button href={`https://remoteadventures.theescapegame.com/play/${game.x_event_id}`} target="_blank">Player Dashboard</Button></td>*/}
                        <td><Button href={`/host/${game.x_event_id}?auth=${game.host_password}`} target="_blank">Host Dashboard</Button></td>
                        <td><Button href={`/play/${game.x_event_id}?auth=${game.player_password}`} target="_blank">Player Dashboard</Button></td>
                        <td><Button href={game.ra_zoom_link} target="_blank" disabled={!game.ra_zoom_link}>Zoom Meeting</Button></td>

                    </tr>
                ))}
            </tbody>
        </Table>
    )
};

export default GuideGameOverview;
