/* eslint-disable */

import React, {useState, useEffect} from "react";

// Styles
import "./Ruins.scss";
import {Col, Container, Row} from "react-bootstrap";

// Image Imports
import lockLogo from "../../../../images/teg_lock_logo.png";
import ruinsLogo from "../../../../images/ruins_logo.png";
import captainMac from "../../../../images/ruins_captain_mac.png";

// Component Imports
import PlayerClueModule from "../../../../Components/Play/PlayerClueModule/PlayerClueModule";
import PlayerMediaModule from "../../../../Components/Play/PlayerMediaModule/PlayerMediaModule";
import PlayerInventoryModule from "../../../../Components/Play/PlayerInventoryModule/PlayerInventoryModule";
import PlayerMultiRowInventoryModule from "../../../../Components/Play/PlayerMultiRowInventoryModule/PlayerMultiRowInventoryModule";
import PlayerZoomDownload from "../../../../Components/Play/PlayerZoomDownload/PlayerZoomDownload";

import {getBackground, VdayHearts} from "../getBackground";

const Ruins = (props) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const inventoryType = params.get('inventory');
  useEffect(() => {
    document.body.style.backgroundColor = "#102021";
    document.getElementsByClassName("player-wrapper ")[0].style.height = document.body.scrollHeight+'px';
  }, [])
  return (
    <div className={props.theme}>
      <div className="player-wrapper" style={{backgroundImage: `url(${getBackground(props.theme)})`}}>
        <Container fluid>
          <Row className="player-logo-section">
            <div className='logo-container'>
              <img
                alt="Logo for The Escape Game"
                className="player-logos-1"
                src={lockLogo}
              />
              <img
                alt="Logo for The Escape Game's Ruins escape room"
                className="player-logos-2"
                src={ruinsLogo}
              />
            </div>
          </Row>
          <Row>
            <Col md="12">
              <VdayHearts />
              <PlayerMediaModule
                scans={props.scans}
                socket={props.socket}
                videos={props.videos}
                theme={props.theme}
                gameMedia={props.gameMedia}
              />
              <br/>
              <Row>
                {props.gameMedia.lockInventory && props.gameMedia.lockInventory.length ? <Col md='6'>
                  <PlayerInventoryModule
                    currentInventory={props.inventory}
                    inventory={props.gameMedia.lockInventory}
                    socket={props.socket}
                    theme={props.theme}
                    type={'lock'}
                  />
                </Col> : ''
                }
                <Col md={props.gameMedia.lockInventory.length ? 6 : 12}>
                  <PlayerZoomDownload conferenceApp="ZOOM" theme={props.theme} tracking={props.gameMedia.tracking} />
                  <Row className="text-center">
                    <img
                      alt="Aux image"
                      className="aux-image"
                      src={captainMac}
                    />
                  </Row>
                </Col>
              </Row>
              <Row className="no-show">
                <Col md="7">
                  <PlayerClueModule clue={props.clue} socket={props.socket} game={props.game}/>
                </Col>
                <Col md="5">
                  <Row>
                    <h1>Time</h1>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-1">
            {inventoryType === 'multi-row' ?
              <PlayerMultiRowInventoryModule
                currentInventory={props.inventory}
                inventory={props.gameMedia.baseInventory}
                socket={props.socket}
                theme={props.theme}
                type={'base'}
              />:
              <PlayerInventoryModule
                currentInventory={props.inventory}
                inventory={props.gameMedia.baseInventory}
                socket={props.socket}
                theme={props.theme}
                type={'base'}
              />
            }
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Ruins;
