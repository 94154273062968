import React, { useState } from "react"
import mixpanel from "lib/mixpanel"

// Packages
import axios from 'axios'
import styled from 'styled-components'

// Components
import { getCookie } from "lib/data"
import { config } from "enviroment.js"


const GameShowPostGame = (props) => {
	const [reviewState, setReviewState] = useState(() => {
		let state = 'pre-rating'
		const rating = localStorage.getItem('rating-' + props.gameId)
		if (rating) {
			parseInt(rating) >= 9 ? state = 'high-rating' : state = 'low-rating'
		}
		return state
  })
  
  const image = "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/post-game/game-show.png"

	const submitRating = async (evt) => {
		let rating = evt.currentTarget.getAttribute('rating')
		const customerId = getCookie('escp_player_customer_id')
		parseInt(rating) >= 9 ? setReviewState('high-rating') : setReviewState('low-rating')
		localStorage.setItem('rating-' + props.gameId, rating)
		const mixpanelTracking = {
			...props.tracking,
			"score": rating
		}
		mixpanel.track('NPS', mixpanelTracking)
		const response = await axios.post(`${config.raUrl}/game/rating/`, {
				customerId: customerId,
				rating: rating,
				rating_type: 'NPS'
			},
		)
	}

	const leaveReviewEvent = (e) => {
		e.preventDefault()
		const reviewLink = e.currentTarget.getAttribute('href')
		const mixpanelTracking = {
			...props.tracking,
			"review_copy": e.currentTarget.parentNode.innerHTML.replace(/(<([^>]+)>)/gi, ""),
			"review_link": reviewLink,
			"review_site": "Google"
		}
		mixpanel.track('Review Click', mixpanelTracking)
		window.location.href = reviewLink
	}

  return (
    <PostGameContainer>
			<div className="post-game-content">
				<a href="https://theescapegame.com/game-show/">
        	<img alt="The Escape Game Game Show" src={image} style={{width: '100%'}} />
				</a>
        <p className="sub-title">Introducing The Escape Game Game Show virtual experience!</p>
				{reviewState === 'pre-rating' &&
					<div className="review-container pre-rating">
						<p className="title">How likely are you to recommend this experience?</p>
						<div className="legend">
							<span>Not likely at all</span>
							<span>Extremely likely</span>
						</div>
						<div className="ratings">
							{[...Array(11)].map((e, i) => {
								return <div key={i} rating={i} onClick={submitRating}><span>{i}</span></div>
							})}
						</div>
					</div>
				}
				{reviewState === 'high-rating' &&
					<div className="review-container post-rating">
						<p className="thank-you">Thank you!</p>
						<p className="leave-review"><a href="https://g.page/r/CR-qNMTdgPtlEAg/review" onClick={leaveReviewEvent}>Click here</a> to leave a review on Google!</p>
					</div>
				}
				{reviewState === 'low-rating' &&
					<div className="review-container post-rating">
						<p className="thank-you">Thank you!</p>
            <p className="leave-review">
              Please take this quick <a href="https://www.surveymonkey.com/r/TEG_RA_Post_Game_Survey">survey</a> to provide us your feedback!
            </p>
					</div>
				}
			</div>
    </PostGameContainer>
  )
}

export default GameShowPostGame

const PostGameContainer = styled.div`
	background: rgba(11, 11, 11, .6);
	height: 100%;
	left: 0;
	min-height: 100vh;
	overflow: auto;
	padding: 40px;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1006;
	.post-game-content {
		display: flex;
		background-color: rgba(255, 255, 255, .90);
		border-radius: 8px;
		flex-direction: column;
		padding: 40px;
		position: relative;
		margin: 0 auto;
		max-width: 800px;
		min-height: 100%;
		width: 100%;
  }
  p.sub-title {
    border-bottom: 1px solid #212529;
    color: #000;
    font-family: DINNextLTProBoldCondensed;
		font-size: 28px;
    margin: 20px 0 0;
    padding: 0 0 30px;
    text-align: center;
    width: 100%;
  }
	.post-game-products {
		display: flex;
		border-bottom: 1px solid rgba(0, 0, 0, .25);
		& > div {
			align-items: center;
			display: flex;
			flex-direction: column;
			width: 50%;
			a {
				margin-top: auto;
				width: 100%;
			}
		}
		& > div:first-child {
			border-right: 1px solid rgba(0, 0, 0, .25);
			padding-right: 45px;
		}
		& > div:last-child {
			padding-left: 45px;
		}
	}
	.review-container {
		display: flex;
		flex-direction: column;
		font-family: DINNextLTProBoldCondensed;
		font-size: 24px;
		margin: 0;
		padding: 30px 0 0;
		p.title {
      color: #000;
      font-size: 35px;
      margin: 0 auto 20px;
      max-width: 400px;
      line-height: 1.3;
			text-align: center;
			width: 100%;
		}
		div.legend {
			display: flex;
			font-family: DINNextLTProCondensed;
			font-size: 16px;
			justify-content: space-between;
			margin: 0 auto 10px;
			max-width: 550px;
			width: 100%;
		}
		div.ratings {
			display: flex;
			flex-wrap: wrap;
			font-family: DINNextLTProCondensed;
			justify-content: space-between;
			margin: 0 auto;
			max-width: 550px;
			width: 100%;
			& > div {
				border-radius: 50%;
				border: 1px solid #000;
				cursor: pointer;
				height: 45px;
				margin-bottom: 10px;
				position: relative;
				transition: background-color .2s;
				width: 45px;
				&:hover {
					background-color: rgba(255, 255, 255, .5)
				}
				span {
					font-size: 20px;
					left: 50%;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -47%);
				}
			}
		}
		p.thank-you {
			color: #000;
			margin: 0;
			text-align: center;
			width: 100%;
		}
		p.leave-review {
			color: #000;
			margin: 0;
			text-align: center;
			width: 100%;
		}
	}
	@media (max-width: 800px) {
		padding: 20px;
		.post-game-content {
			padding: 20px;
		}
	}
	@media (max-width: 660px) {
		.review-container {
			div.legend, div.ratings {
				max-width: 235px;
			}
			div.ratings {
				justify-content: center;
				& > div {
					height: 33px;
					margin: 3px;
					width: 33px;
					span {
						font-size: 16px;
						transform: translate(-50%, -42%);
					}
				}
			}
		}
	}
	@media (max-width: 640px) {
		.post-game-products {
			flex-direction: column;
			& > div {
				border: 0 !important;
				margin: 0 auto;
				max-width: 400px;
				padding: 30px 30px 45px !important;
				width: 100%;
			}
		}
	}
`