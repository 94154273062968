import Preload from "./preload";
import GameEnd from "./gameend";
import Phaser from "phaser";
import GameScene from "./gamescene";

export default class CarnivalGame {
  scenes = [];
  config = {
    type: Phaser.AUTO,
    parent: "minigame_container",
    width: 1920,
    height: 1080,
    scale: {
      mode: Phaser.Scale.FIT,
      autoCenter: Phaser.Scale.CENTER_BOTH,
    },
    dom: {
      createContainer: true,
    },
    scene: this.scenes,
  };

  constructor() {
    this.scenes.push(Preload);
    this.scenes.push(GameScene);
    this.scenes.push(GameEnd);
    this.game = new Phaser.Game(this.config);
  }
}
