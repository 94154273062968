import React, { useEffect, useState } from "react"
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import styled from 'styled-components'
import { formatTimer, getInitialTimerState } from 'Components/Timer/utilsTimer.js'


const COLORS = {
	green: {
		color: "#02c148",
		threshold: 3600
	},
	yellow: {
		color: "#f2e616",
		threshold: 2880
	},
	orange: {
		color: "#ed831a",
		threshold: 2160
	},
	pink: {
		color: "#ee141e",
		threshold: 1440
	},
	red: {
		color: "#ff505a",
		threshold: 720
	},
	gray: {
		color: "#999999",
		threshold: 0
	}
}
const TIME_LIMIT = 3600 // in seconds
const FULL_DASH_ARRAY = 283


const PlayerTimer = (props) => {
	const { socket, timerEvents } = props
	const [isTimerPaused, setIsTimerPaused] = useState(true)
	const [timeRemaining, setTimeRemaining] = useState()
	const [timerColor, setTimerColor] = useState(COLORS.green.color)
	const [circleDashArray, setCircleDashArray] = useState("0 283")

	useEffect(() => {
		const [newTimeRemaining, timerState] = getInitialTimerState(timerEvents || [])
		setTimeRemaining(newTimeRemaining)
		setIsTimerPaused(timerState)
	}, [timerEvents])

	useEffect(() => {
		if (timeRemaining <= COLORS.gray.threshold) {
			setTimerColor(COLORS.gray.color)
		} else if (timeRemaining <= COLORS.pink.threshold) {
			setTimerColor(COLORS.red.color)
		} else if (timeRemaining <= COLORS.red.threshold) {
			setTimerColor(COLORS.pink.color)
		} else if (timeRemaining <= COLORS.orange.threshold) {
			setTimerColor(COLORS.orange.color)
		} else if (timeRemaining <= COLORS.yellow.threshold) {
			setTimerColor(COLORS.yellow.color)
		} else if (timeRemaining <= COLORS.green.threshold) {
			setTimerColor(COLORS.green.color)
		}
		updateCircleDasharray(timeRemaining)
	}, [timeRemaining])

  useEffect(() => {
    socket.on("toggle-timer-state", (data) => {
			if (['pause', 'start'].includes(data.event)) {
				setIsTimerPaused(isTimerPaused => !isTimerPaused)
			} else if (data.event === 'timerUpdated') {
				setTimeRemaining(timeRemaining => Math.max(0, timeRemaining + data.change))
			}
    })
    return () => {
      socket.off("toggle-timer-state")
    }
	}, [socket])
	
	useEffect(() => {
		const timerInterval = setInterval(() => {
			if (!isTimerPaused) setTimeRemaining(timeRemaining => Math.max(0, timeRemaining - 1))
		}, 1000)
		return () => clearInterval(timerInterval)
	}, [isTimerPaused])

	const updateCircleDasharray = (timeRemaining) => {
		const rawTimeFraction = timeRemaining / TIME_LIMIT
		const timeFraction = rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction)
		const circleDasharray = `${((1 - timeFraction) * FULL_DASH_ARRAY).toFixed(0)} 283`
		setCircleDashArray(circleDasharray)
	}

	const renderTooltip = (props) => (
    <Tooltip id="timer-tooltip" {...props}>
      {formatTimer(timeRemaining)}
			<div style={{fontSize: "8px"}}>REMAINING</div>
    </Tooltip>
  )

  return (
		<OverlayTrigger
			placement="bottom"
			delay={{ show: 100, hide: 400 }}
			overlay={renderTooltip}
		>
			<TimerContainer timerColor={timerColor} size={45}>
				<div className="base-timer">
					<svg className="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
						<g className="base-timer__circle">
							<circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
							<path id="base-timer-path-remaining" strokeDasharray={circleDashArray} className="base-timer__path-remaining"
								d="
									M 50, 50
									m -45, 0
									a 45,45 0 1,0 90,0
									a 45,45 0 1,0 -90,0
								"
							></path>
						</g>
					</svg>
					{/* <span id="base-timer-label" className="base-timer__label">{formatTime(timeRemaining)}</span> */}
				</div>
			</TimerContainer>
		</OverlayTrigger>
  )
}

export default PlayerTimer

const TimerContainer = styled.div`
	.base-timer {
		border: 5px solid ${props => props.timerColor};
		border-radius: 50%;
		cursor: pointer;
		position: relative;
		transition: 1s linear all;
		width: ${props => props.size}px;
		height: ${props => props.size}px;
	}
	.base-timer__svg {
		border: 1px solid #fff;
		border-radius: 50%;
		transform: scaleX(-1);
	}
	.base-timer__circle {
		fill: none;
		stroke: none;
	}
	.base-timer__path-elapsed {
		stroke-width: ${props => props.size * 2}px;
		stroke: #fff;
	}
	.base-timer__path-remaining {
		color: ${props => props.timerColor};
		stroke-width: ${props => props.size * 2}px;
		stroke-linecap: butt;
		transform: rotate(90deg);
		transform-origin: center;
		transition: 1s linear all;
		fill-rule: nonzero;
		stroke: currentColor;
	}
	.base-timer__label {
		font-family: 'DINNextLTProRegular';
		position: absolute;
		width: ${props => props.size}px;
		height: ${props => props.size}px;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
	}
`
