import RALogo from "images/RA-newlogo.png";

// GOLD RUSH
import playerLoginBgGoldRush from "images/gold-rush-player-login-bg.jpg"

// THE HEIST
import playerLoginBgHeist from "images/heist-player-login-bg.jpg"

// RUINS
import playerLoginBgRuins from "images/ruins-player-login-bg.jpg"

// THE DEPTHS
import playerLoginBgDepths from "images/depths-player-login-bg.jpg"

// PRISON BREAK
import playerLoginBgPrisonBreak from "images/prison-break-player-login-bg.jpg"

// RUGRATS
import iconInventoryRugrats from "images/rugrats-icon-inventory.png"
import iconLocksRugrats from "images/rugrats-icon-locks.png"
import iconViewsRugrats from "images/rugrats-icon-views.png"

// OMITB
import ActorsOnDashboard from "Components/Play/Themes/OMITB/ActorsOnDashboard"

// Post Game Components
import GameShowPostGame from 'Pages/PlayerDashboard/PostGame/Components/GameShowPostGame'

// Products
import AtHome from 'Pages/PlayerDashboard/PostGame/Products/AtHome'
import EscapeFromIrongate from 'Pages/PlayerDashboard/PostGame/Products/EscapeFromIrongate'
import Locations from 'Pages/PlayerDashboard/PostGame/Products/Locations'
import TeamBuilding from 'Pages/PlayerDashboard/PostGame/Products/TeamBuilding'
import Unlocked from 'Pages/PlayerDashboard/PostGame/Products/Unlocked'

const arrowNextWhite = "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/arrow-next-white.png"
const arrowPrevWhite = "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/arrow-prev-white.png"


const themeSettings = {
    "gold-rush": {
        backgroundColor: "#16365e",
        backgroundImage: 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/gold-rush/gold-rush-background-image.jpg',
        gameLogo: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/gold-rush/gold-rush-game-logo.png",
        viewerBackgroundColor: "#16365e",
        viewerBorder: "1px solid #988452",
        panelInventoryColor: "#f6bf41",
        panelLocksColor: "#16365e",
        panelLocksTextColor: "#fff",
        panelViewsColor: "#1b5c0b",
        nextButtonImage: arrowNextWhite,
        prevButtonImage: arrowPrevWhite,
        tabInventoryColor: "linear-gradient(#f8c351, #e2ae3a)",
        tabInventoryLabel: "Inventory",
        tabLocksColor: "linear-gradient(#264469, #143156)",
        tabLocksLabel: "Locks",
        tabViewsColor: "linear-gradient(#2f671f, #1a5409)",
        tabViewsLabel: "Explore",
        iconInventory: iconInventoryRugrats,
        iconLocks: iconLocksRugrats,
        iconViews: iconViewsRugrats,
        playerLoginBg: playerLoginBgGoldRush,
        playerLoginLogo: RALogo,
        zoomBackgrounds: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/downloads/GoldRush_Virtual_Backgrounds.zip",
        postGameProductLeft: AtHome,
        postGameProductRight: TeamBuilding,
        postGame: GameShowPostGame
    },
    "prison-break": {
        backgroundColor: "#000",
        backgroundImage: 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/prison-break/prison-break-background-image.jpg',
        gameLogo: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/prison-break/prison-break-game-logo.png",
        viewerBackgroundColor: "#000",
        viewerBorder: "1px solid #aeaeae",
        panelInventoryColor: "#d1d1d1",
        panelLocksColor: "#7e7e7e",
        panelLocksTextColor: "#fff",
        panelViewsColor: "#02c148",
        nextButtonImage: arrowNextWhite,
        prevButtonImage: arrowPrevWhite,
        tabInventoryColor: "linear-gradient(#d1d1d1, #bfbfbf)",
        tabInventoryLabel: "Inventory",
        tabLocksColor: "linear-gradient(#7e7e7e, #737373)",
        tabLocksLabel: "Locks",
        tabViewsColor: "linear-gradient(#2ec555, #05af41)",
        tabViewsLabel: "Explore",
        iconInventory: iconInventoryRugrats,
        iconLocks: iconLocksRugrats,
        iconViews: iconViewsRugrats,
        playerLoginBg: playerLoginBgPrisonBreak,
        playerLoginLogo: RALogo,
        zoomBackgrounds: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/downloads/PrisonBreak_Virtual_Backgrounds.zip",
        postGameProductLeft: EscapeFromIrongate,
        postGameProductRight: Locations,
        postGame: GameShowPostGame
    },
    "depths": {
        backgroundColor: "#082b34",
        backgroundImage: 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/depths/depths-background-image-v2.jpg',
        gameLogo: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/depths/depths-game-logo.png",
        viewerBackgroundColor: "#082b34",
        viewerBorder: "1px solid #927f3e",
        panelInventoryColor: "#f6bf41",
        panelLocksColor: "#1bb9c4",
        panelViewsColor: "#ace0de",
        nextButtonImage: arrowNextWhite,
        prevButtonImage: arrowPrevWhite,
        tabInventoryColor: "linear-gradient(#d5a746, #e5b13a)",
        tabInventoryLabel: "Inventory",
        tabLocksColor: "linear-gradient(#2fa3ac, #17abb7)",
        tabLocksLabel: "Locks",
        tabViewsColor: "linear-gradient(#99c1c0, #a1d2d0)",
        tabViewsLabel: "Explore",
        iconInventory: iconInventoryRugrats,
        iconLocks: iconLocksRugrats,
        iconViews: iconViewsRugrats,
        playerLoginBg: playerLoginBgDepths,
        playerLoginLogo: RALogo,
        zoomBackgrounds: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/downloads/Depths_Virtual_Backgrounds.zip",
        postGameProductLeft: AtHome,
        postGameProductRight: TeamBuilding,
        postGame: GameShowPostGame
    },
    "ruins": {
        playerLoginBg: playerLoginBgRuins,
        playerLoginLogo: RALogo,
        postGame: GameShowPostGame
    },
    "ruins-forbidden-treasure": {
        backgroundColor: "#121f1d",
        backgroundImage: 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/ruins/ruins-background-image.jpg',
        gameLogo: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/ruins/ruins-game-logo.png",
        viewerBackgroundColor: "#121f1d",
        viewerBorder: "1px solid #967b33",
        panelInventoryColor: "#f6bf41",
        panelLocksColor: "#7c913d",
        panelViewsColor: "#5f6d46",
        nextButtonImage: arrowNextWhite,
        prevButtonImage: arrowPrevWhite,
        tabInventoryColor: "linear-gradient(#d6a848, #e5b13a)",
        tabInventoryLabel: "Inventory",
        tabLocksColor: "linear-gradient(#738343, #738739)",
        tabLocksLabel: "Locks",
        tabViewsColor: "linear-gradient(#5c6749, #596642)",
        tabViewsLabel: "Explore",
        iconInventory: iconInventoryRugrats,
        iconLocks: iconLocksRugrats,
        iconViews: iconViewsRugrats,
        playerLoginBg: playerLoginBgRuins,
        playerLoginLogo: RALogo,
        zoomBackgrounds: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/downloads/Ruins_Virtual_Backgrounds.zip",
        postGameProductLeft: AtHome,
        postGameProductRight: TeamBuilding,
        postGame: GameShowPostGame
    },
    "the-heist": {
        backgroundColor: "#508eb9",
        backgroundImage: 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/the-heist/background-image-the-heist.jpeg',
        gameLogo: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/the-heist/game-logo-the-heist.png",
        viewerBackgroundColor: "#0f1c3e",
        viewerBorder: "1px solid #7192d3",
        panelInventoryColor: "#b9d4fb",
        panelLocksColor: "#8cb2fc",
        panelViewsColor: "#3563bf",
        nextButtonImage: arrowNextWhite,
        prevButtonImage: arrowPrevWhite,
        tabInventoryColor: "linear-gradient(#b9d4fb, #a9c2e6)",
        tabInventoryLabel: "Inventory",
        tabLocksColor: "linear-gradient(#8cb2fc, #80a3e6)",
        tabLocksLabel: "Locks",
        tabViewsColor: "linear-gradient(#3563bf, #305aaf)",
        tabViewsLabel: "Explore",
        iconInventory: iconInventoryRugrats,
        iconLocks: iconLocksRugrats,
        iconViews: iconViewsRugrats,
        playerLoginBg: playerLoginBgHeist,
        playerLoginLogo: RALogo,
        zoomBackgrounds: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/downloads/TheHeist_Virtual_Backgrounds.zip",
        postGameProductLeft: Unlocked,
        postGameProductRight: Locations,
        postGame: GameShowPostGame
    },
    "playground": {
        backgroundColor: "#508eb9",
        backgroundImage: 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/playground/background-image-playground.png',
        gameLogo: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/playground/game-logo-playground.png",
        viewerBackgroundColor: "#3663bf",
        viewerBorder: "1px solid #15ae67",
        panelAssetImgStyles: {backgroundColor: "rgba(0,0,0,.3)", borderRadius: "4px"},
        panelInventoryColor: "#fdcb0d",
        panelLocksColor: "#01c149",
        panelViewsColor: "#ff5159",
        nextButtonImage: arrowNextWhite,
        prevButtonImage: arrowPrevWhite,
        tabInventoryColor: "linear-gradient(#fdcc0e, #e6b80b)",
        tabInventoryLabel: "Inventory",
        tabLocksColor: "linear-gradient(#00c348, #02af43)",
        tabLocksLabel: "Locks",
        tabViewsColor: "linear-gradient(#ff5159, #e83d53)",
        tabViewsLabel: "Explore",
        iconInventory: iconInventoryRugrats,
        iconLocks: iconLocksRugrats,
        iconViews: iconViewsRugrats,
        playerLoginBg: 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/playground/background-image-playground.png',
        playerLoginLogo: RALogo,
        zoomBackgrounds: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/playground/any/Playground_Virtual_Backgrounds.zip",
        postGameProductLeft: AtHome,
        postGameProductRight: TeamBuilding,
        postGame: GameShowPostGame
    },
    "omitb": {
        backgroundColor: "linear-gradient(#2fa3ac, #17abb7)",
        backgroundImageGradient: "linear-gradient(to left, #1E1E1E , #B10203)",
        gameLogo: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/client/omitb/omitb-logo.png",
        viewerBackgroundColor: "#000000",
        viewerBorder: "1px solid #000000",
        panelAssetImgStyles: {backgroundColor: "rgba(0,0,0,.3)", borderRadius: "4px"},
        panelInventoryColor: "#EEE7CF",
        panelLocksColor: "#DE9D0E",
        panelViewsColor: "#466A52",
        nextButtonImage: arrowNextWhite,
        prevButtonImage: arrowPrevWhite,
        tabInventoryColor: "linear-gradient(#EEE7CF, #d2cbb8)",
        tabInventoryLabel: "Inventory",
        tabLocksColor: "linear-gradient(#DE9D0E, #ab790b)",
        tabLocksLabel: "Locks",
        tabViewsColor: "linear-gradient(#466A52, #2f4837)",
        tabViewsLabel: "Explore",
        iconInventory: iconInventoryRugrats,
        iconLocks: iconLocksRugrats,
        iconViews: iconViewsRugrats,
        playerLoginBg: 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/playground/background-image-playground.png',
        playerLoginLogo: RALogo,
        zoomBackgrounds: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/client/omitb/Only_Murders_Escape_Room_Virtual_Backgrounds.zip",
        headerLeftImage: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/client/omitb/hulu-teg-logo.png",
        headerLeftImageStyles: { maxWidth: "147px" },
        footerImage: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/client/omitb/omitb-hulu-teg-logo.png",
        footerBackgroundColor: "linear-gradient(to left, #1E1E1E , #B10203)",
        footerHeight: '100px',
        fixedPositionElement: ActorsOnDashboard,
        postGameProductLeft: AtHome,
        postGameProductRight: TeamBuilding,
        postGame: GameShowPostGame
    },
    "default": {
        playerLoginBg: playerLoginBgHeist,
        playerLoginLogo: RALogo,
    }
}

const getThemeSettings = (theme, customTheming) => {
    // ADDITIONAL CUSTOM FIELDS
    // footerBackgroundColor --> color
    // footerImage --> url
    // footerText --> text
    // headerCenterImage --> url
    // fixedPositionElement --> component
    if (!themeSettings[theme]) return themeSettings["default"]
    return {...themeSettings[theme], ...customTheming}
}

export default getThemeSettings;


