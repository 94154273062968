import React, { useEffect, useState } from "react"
import styled from 'styled-components'

const StyledActorsOnDashboard = styled.div`
  position: fixed;
  bottom: 0;
  left: ${props => props.leftPosition ? props.leftPosition : -200}px;
  width: 200px;
  img {
    width: 100%;
  }
`

export default function ActorsOnDashboard() {
  const [leftPosition, setLeftPosition] = useState(-200)
  const imgSrc = "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/client/omitb/actors-w600.png"

  useEffect(() => {

  }, [])

  useEffect(() => {
    const positionActorsOnDashboard = () => {
      const assetsRightXPosition = document.getElementById('assets').getBoundingClientRect().right
      let newLeftPosition = assetsRightXPosition - 20
      if (window.innerWidth - assetsRightXPosition + 40 < 200) newLeftPosition = -250
      setLeftPosition(newLeftPosition)
    }
    positionActorsOnDashboard()
    window.addEventListener('resize', positionActorsOnDashboard)
    return () => window.removeEventListener("resize", positionActorsOnDashboard)
  }, [])

  return (
    <StyledActorsOnDashboard leftPosition={leftPosition}>
      <img src={imgSrc} alt="Actors" />
    </StyledActorsOnDashboard>
  )
}
