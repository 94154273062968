import Phaser from "phaser";
// import { config } from "./index";

export default class GameEnd extends Phaser.Scene {
  constructor() {
    super({ key: "GameEnd", active: false });
    console.log("GameScene constructor");
  }

  create(data) {
    this.bg = this.add.image(this.game.renderer.width / 2, this.game.renderer.height, "ending_bg");
    this.scoreBoard = this.add.image(this.game.renderer.width/2, -70, "ending_scoreBoard");
    this.scoreBoard.setOrigin(0.5,0);

    this.deerText = this.add.text(this.game.renderer.width / 2 - 600, 110, "Moose", { fontFamily: 'Arial', fontSize: 60, color: '#000000' });
    this.deerText.setOrigin(0.5,0,5);
    this.deerNumber = this.add.text(this.game.renderer.width / 2 - 600, 220, 7, { fontFamily: 'Arial', fontSize: 110, color: '#000000' });
    this.deerNumber.setOrigin(0.5,0,5);

    this.ramText = this.add.text(this.game.renderer.width / 2 - 200, 110, "Ram", { fontFamily: 'Arial', fontSize: 60, color: '#000000' });
    this.ramText.setOrigin(0.5,0,5);
    this.ramNumber = this.add.text(this.game.renderer.width / 2 - 200, 220, 3, { fontFamily: 'Arial', fontSize: 110, color: '#000000' });
    this.ramNumber.setOrigin(0.5,0,5);

    this.turkeyText = this.add.text(this.game.renderer.width / 2 + 210, 110, "Turkey", { fontFamily: 'Arial', fontSize: 60, color: '#000000' });
    this.turkeyText.setOrigin(0.5,0,5);
    this.turkeyScore = this.add.text(this.game.renderer.width / 2 + 210, 220, 4, { fontFamily: 'Arial', fontSize: 110, color: '#000000' });
    this.turkeyScore.setOrigin(0.5,0,5);

    this.mooseText = this.add.text(this.game.renderer.width / 2 + 610, 110, "Deer", { fontFamily: 'Arial', fontSize: 60, color: '#000000' });
    this.mooseText.setOrigin(0.5,0,5);
    this.mooseNumber = this.add.text(this.game.renderer.width / 2 + 610, 220, 0, { fontFamily: 'Arial', fontSize: 110, color: '#000000' });
    this.mooseNumber.setOrigin(0.5,0,5);

    this.congratulationsText = this.add.text(this.game.renderer.width / 2, this.game.renderer.height / 2, "CONGRATULATIONS", { fontFamily: 'Arial', fontSize: 140, color: '#FFFFFF' });
    this.congratulationsText.setOrigin(0.5,0,5);
}
}
