import React from "react";
import styled from 'styled-components'


const ProductContainer = (props) => {
	
	return (
		<Container>
			{props.children}
		</Container>
	)
}

export default ProductContainer

const Container = styled.div`
	font-family: DINNextLTProBoldCondensed;
	padding: 30px 30px 45px 30px;
	text-align: center;
`
