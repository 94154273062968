import {
  LIST_ROOMS,
  ROOM_SUCCESS,
  ROOM_ERROR
} from "../actions/index";

export const listRooms = (payload) => ({
  type: LIST_ROOMS,
  payload: payload
});

export const roomSuccess = (payload) => ({
  type: ROOM_SUCCESS,
  payload: payload
});

export const roomError = (payload) => ({
  type: ROOM_ERROR,
  payload: payload
});
