import React from 'react'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

// Styles
import 'bootstrap/dist/css/bootstrap.min.css'

// Routing
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PlayerDashboard from './Pages/PlayerDashboard/PlayerDashboard';
import GuideDashboard from './Pages/GuideDashboard/GuideDashboard';
import PlayerJoin from './Pages/PlayerJoin/PlayerJoin';
import GuideGameOverview from './Pages/GuideGameOverview/GuideGameOverview';
import ATCDashboard from './Pages/ATCDashboard';
import Login from './Pages/Login';
import Profile from './Pages/ATCDashboard/Profile';
import UserPrivate from './auth/UserPrivate';
import XolaSettings from './Pages/ATCDashboard/Profile/XolaSettings';
import ClientTestPage from './Pages/ClientTestPage/ClientTestPage';
import { TEG_APP } from 'lib/constants'
import CarnivalTest from './Pages/CarnivalTest'


const App = () => {

  return (
    <div>
      <Router>
       <Switch>
         <Route exact path="/host/:game" component={GuideDashboard}/>
         <Route exact path="/guide" component={GuideGameOverview}/>
         <Route exact path="/" component={PlayerJoin}/>
         <Route exact path="/play/:game" component={PlayerDashboard}/>
         <Route exact path="/carnival-test/:game" component={CarnivalTest} />
         <UserPrivate exact path="/atc" component={ATCDashboard}/>
         <UserPrivate exact path="/settings" component={Profile}/>
         <UserPrivate exact path="/settings/password" component={Profile}/>
         <UserPrivate exact path="/settings/xola" component={XolaSettings}/>
         <Route exact path="/login" component={Login}/>
         <Route exact path="/test" component={ClientTestPage}/>
       </Switch>
      </Router>
    </div>
  )
  
}

export default withLDProvider({
  clientSideID: TEG_APP.LAUNCH_DARKLY_CLIENT_SIDE_ID,
  user: { key: "remote-adventures" }
})(App);
