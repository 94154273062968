import Phaser from "phaser";
import Animal from "./animal";
import { Vector2 } from "three";
import * as CarnivalSocket from "../websockets/carnival";

export default class GameScene extends Phaser.Scene {
  constructor() {
    super({ key: "GameScene", active: false });
    console.log("GameScene constructor");
  }

  create() {

    let scene = this;
    this.bg = this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, "bg");
    this.hill1 = this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2 + 75, "hill");
    this.hill1.setOrigin(0.5,0);
    this.hill2 =this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2 + 185, "hill");
    this.hill2.setOrigin(0.5,0);
    this.hill3 =this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2 + 295, "hill");
    this.hill3.setOrigin(0.5,0);
    this.hill4 =this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2 + 405, "hill");
    this.hill4.setOrigin(0.5,0);
    this.hill1.setDepth(1);
    this.hill2.setDepth(2);
    this.hill3.setDepth(3);
    this.hill4.setDepth(4);

    this.scoreBoard = this.add.image(this.game.renderer.width/2, -20, "scoreBoard");
    this.scoreBoard.setOrigin(0.5,0);

    this.muzzle=this.add.image(0,0,'muzzle').setScale(0.5,0.5);
    this.muzzle.setDepth(6);
    this.muzzle.setVisible(false);
    this.fireRate=3;  //shots per second
    this.isFireCooldown=false;

    //sfx
    this.fireSound = this.sound.add('fire');
    this.fireSound.setVolume(0.4);
    this.hitSound = this.sound.add('hit');
    
    //hiding cursor and showing the crosshair
    this.canvas = this.sys.canvas;
    this.canvas.style.cursor = 'none';
    this.crossHair = this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, "crosshair");
    this.crossHair.setDepth(5); 
    this.input.on('pointermove', function (pointer) {
        scene.crossHair.x = pointer.x;
        scene.crossHair.y = pointer.y;
        scene.muzzle.x=pointer.x+20;
        scene.muzzle.y=pointer.y-5;
    });
    this.input.on('pointerdown',()=>{
      if(!this.isFireCooldown)
      {
        this.isFireCooldown=true;
        this.muzzle.setVisible(true);
        this.fireSound.play();
        setTimeout(()=>{this.muzzle.setVisible(false)}, 100);
        setTimeout(()=>{this.isFireCooldown=false}, 1000/this.fireRate);
      }
      
    });
  

    this.animals = [];
    this.alreadyRenderedCursors = [];
    this.alreadyRenderedNames = [];
    this.intervalNum=800;   //Interval distance multiplier between animals. Dynamically adjusted per row.
    this.rowSpeedMul=1;    //Speed multipler for each row
    

    //First Row
 
    this.row1Speed=2*this.rowSpeedMul;
    this.row1Interval=(((this.intervalNum+100/this.row1Speed)*this.row1Speed)+this.game.renderer.width)/6;
    this.animals.push(new Animal(this, -100, this.game.renderer.height / 2 - 50, "deer_right_front", "deer_right_back", 1, null, this.row1Speed, 0, 1, "Deer"));
    this.animals.push(new Animal(this, -100-this.row1Interval, this.game.renderer.height / 2 - 50, "ram_right_front", "ram_right_back_numbered", 1, 3, this.row1Speed, 0, 2, "Ram"));
    this.animals.push(new Animal(this, -100-this.row1Interval*2, this.game.renderer.height / 2 - 50, "deer_right_front", "deer_right_back", 1, null, this.row1Speed, 0, 3, "Deer"));
    this.animals.push(new Animal(this, -100-this.row1Interval*3, this.game.renderer.height / 2 - 50, "turkey_right_front", "turkey_right_back", 1, null, this.row1Speed, 0, 4, "Turkey"));
    this.animals.push(new Animal(this, -100-this.row1Interval*4, this.game.renderer.height / 2 - 50, "moose_right_front", "moose_right_back", 1, null, this.row1Speed, 0, 5, "Moose"));
    this.animals.push(new Animal(this, -100-this.row1Interval*5, this.game.renderer.height / 2 - 50, "ram_right_front", "ram_right_back", 1, null, this.row1Speed, 0, 6, "Ram"));

    // //Second Row
    this.row2Speed=8*this.rowSpeedMul;
    this.row2Interval=(((this.intervalNum+100/this.row2Speed)*this.row2Speed)+this.game.renderer.width)/6;
    this.animals.push(new Animal(this, 1800, this.game.renderer.height / 2 + 60, "moose_left_front", "moose_left_back", -1, null, 8, 1, 7, "Moose"));
    this.animals.push(new Animal(this, 1800+this.row2Interval, this.game.renderer.height / 2 + 60, "deer_left_front", "deer_left_back_numbered", -1, 0, this.row2Speed, 1, 8, "Deer"));
    this.animals.push(new Animal(this, 1800+this.row2Interval*2, this.game.renderer.height / 2 + 60, "turkey_left_front", "turkey_left_back", -1, null, this.row2Speed, 1, 9, "Turkey"));
    this.animals.push(new Animal(this, 1800+this.row2Interval*3, this.game.renderer.height / 2 + 60, "ram_left_front", "ram_left_back", -1, null, this.row2Speed, 1, 10, "Ram"));
    this.animals.push(new Animal(this, 1800+this.row2Interval*4, this.game.renderer.height / 2 + 60, "moose_left_front", "moose_left_back", -1, null, this.row2Speed, 1, 11, "Moose"));
    this.animals.push(new Animal(this, 1800+this.row2Interval*5, this.game.renderer.height / 2 + 60, "deer_left_front", "deer_left_back", -1, null, this.row2Speed, 1, 12, "Deer"));

    // //Third Row
    this.row3Speed=6*this.rowSpeedMul;
    this.row3Interval=(((this.intervalNum+100/this.row3Speed)*this.row3Speed)+this.game.renderer.width)/6;
    this.animals.push(new Animal(this, 100, this.game.renderer.height / 2 + 170, "turkey_right_front", "turkey_right_back", 1, null, this.row3Speed, 2, 13, "Turkey"));
    this.animals.push(new Animal(this, 100-this.row3Interval, this.game.renderer.height / 2 + 170, "moose_right_front", "moose_right_back", 1, null, this.row3Speed, 2, 14, "Moose"));
    this.animals.push(new Animal(this, 100-this.row3Interval*2, this.game.renderer.height / 2 + 170, "ram_right_front", "ram_right_back", 1, null, this.row3Speed, 2, 15, "Ram"));
    this.animals.push(new Animal(this, 100-this.row3Interval*3, this.game.renderer.height / 2 + 170, "deer_right_front", "deer_right_back", 1, null, this.row3Speed, 2, 16, "Deer"));
    this.animals.push(new Animal(this, 100-this.row3Interval*4, this.game.renderer.height / 2 + 170, "turkey_right_front", "turkey_right_back_numbered", 1, 4, this.row3Speed, 2, 17, "Turkey"));
    this.animals.push(new Animal(this, 100-this.row3Interval*5, this.game.renderer.height / 2 + 170, "moose_right_front", "moose_right_back", 1, null, this.row3Speed, 2, 18, "Moose"));

    // //Fourth Row
    this.row4Speed=4*this.rowSpeedMul;
    this.row4Interval=(((this.intervalNum+100/this.row4Speed)*this.row4Speed)+this.game.renderer.width)/6;
    this.animals.push(new Animal(this, 1600, this.game.renderer.height / 2 + 280, "ram_left_front", "ram_left_back", -1, null, this.row4Speed, 3, 19, "Ram"));
    this.animals.push(new Animal(this, 1600+this.row4Interval, this.game.renderer.height / 2 + 280, "moose_left_front", "moose_left_back_numbered", -1, 7, this.row4Speed, 3, 20, "Moose"));
    this.animals.push(new Animal(this, 1600+this.row4Interval*2, this.game.renderer.height / 2 + 280, "deer_left_front", "deer_left_back", -1, null, this.row4Speed, 3, 21, "Deer"));
    this.animals.push(new Animal(this, 1600+this.row4Interval*3, this.game.renderer.height / 2 + 280, "turkey_left_front", "turkey_left_back", -1, null, this.row4Speed, 3, 22, "Turkey"));
    this.animals.push(new Animal(this, 1600+this.row4Interval*4, this.game.renderer.height / 2 + 280, "ram_left_front", "ram_left_back", -1, null, this.row4Speed, 3, 23, "Ram"));
    this.animals.push(new Animal(this, 1600+this.row4Interval*5, this.game.renderer.height / 2 + 280, "turkey_left_front", "turkey_left_back", -1, null, this.row4Speed, 3, 24, "Turkey"));

    this.notifications = [];
    this.notifications.push("Congratulations");
    this.notifications.push("Well Done");
    this.notifications.push("Good Job");

    this.score = 0;

    this.scoreText = []
    this.scoreCount = []

    // first position 
    this.scoreText[0] = this.add.text(this.game.renderer.width / 2 - 250, 50, "Moose", { fontFamily: 'Arial', fontSize: 30, color: '#000000' });
    this.scoreText[0].setOrigin(0.5,0,5);
    this.scoreCount[0] = this.add.text(this.game.renderer.width / 2 - 250, 100, "?", { fontFamily: 'Arial', fontSize: 50, color: '#000000' });
    this.scoreCount[0].setOrigin(0.5,0,5);

    // second position 
    this.scoreText[1] = this.add.text(this.game.renderer.width / 2 - 80, 50, "Ram", { fontFamily: 'Arial', fontSize: 30, color: '#000000' });
    this.scoreText[1].setOrigin(0.5,0,5);
    this.scoreCount[1] = this.add.text(this.game.renderer.width / 2 - 80, 100, "?", { fontFamily: 'Arial', fontSize: 50, color: '#000000' });
    this.scoreCount[1].setOrigin(0.5,0,5);

    // third position
    this.scoreText[2] = this.add.text(this.game.renderer.width / 2 + 90, 50, "Turkey", { fontFamily: 'Arial', fontSize: 30, color: '#000000' });
    this.scoreText[2].setOrigin(0.5,0,5);
    this.scoreCount[2] = this.add.text(this.game.renderer.width / 2 + 90, 100, "?", { fontFamily: 'Arial', fontSize: 50, color: '#000000' });
    this.scoreCount[2].setOrigin(0.5,0,5);

    // fourth position
    this.scoreText[3] = this.add.text(this.game.renderer.width / 2 + 260, 50, "Deer", { fontFamily: 'Arial', fontSize: 30, color: '#000000' });
    this.scoreText[3].setOrigin(0.5,0,5);
    this.scoreCount[3] = this.add.text(this.game.renderer.width / 2 + 260, 100, "?", { fontFamily: 'Arial', fontSize: 50, color: '#000000' });
    this.scoreCount[3].setOrigin(0.5,0,5);

    this.numberedCardsFound = 0;

    this.updateCursor(); // add in update
    CarnivalSocket.targetGotShot(this);

    // get user state after every 500 ms
    CarnivalSocket.getUpdatedState(this);

    this.gameOver = false;
    this.Start();    
  }

  update() {
    if(!this.gameOver){
      this.MoveAnimals();
    } 

    console.log("count: ", this.numberedCardsFound)

    if (this.numberedCardsFound >= 4) {
      console.log("game over");
      this.gameOver = true;
      CarnivalSocket.disconnect()
      this.time.delayedCall(4000, this.GameOver, [], this);
    }
  }

  GameOver(){
    let data = [];
    this.name = "hssan"
    data.push(this.score);
    data.push(this.name);
    this.scene.start('GameEnd', data);
  }

  MoveAnimals() {
    for(var i = 0; i < this.animals.length; i++) {
      this.animals[i].MoveAnimal();
      if(this.animals[i].dissapeared){
          this.animals.splice(i, 1);
      }
    }
  }

  numberedTargetFound() {
    this.numberedCardsFound +=1
  }

  IncreaseScore(score) {
    this.numberedCardsFound +=1
    this.score += score;

     // emit socket event to increase score for this user
     CarnivalSocket.increaseScore(this.score);
  }

  Start() {
    for(var i = 0; i < this.animals.length; i++) {
        this.animals[i].Start();
    }
  }

  updateCursor = () => {
    setInterval(() => {
      const cursorX = this.game.input.activePointer.x;
      const cursorY = this.game.input.activePointer.y;

      CarnivalSocket.sendCursorUpdate(new Vector2(cursorX, cursorY));
    }, 400);
  };

  targetHitListener =  (data) => {
    let  hitTarget = this.animals.find((animal) => animal.id === data.id)
    console.log(hitTarget)
    hitTarget.showKillingAnimation();
  }

  updateScoreBoard = (targetsHit) => {
    
    const deerNumber = targetsHit.find((target) => target.name === "Moose" && target.number !== null)
    if(deerNumber && this.scoreCount[0].text === "?"){
      this.scoreCount[0].text = deerNumber.number
      if(this.numberedCardsFound !== 0) this.numberedCardsFound += 1
    }

    const ramNumber = targetsHit.find((target) => target.name === "Ram" && target.number !== null)
    if(ramNumber && this.scoreCount[1].text === "?"){
      this.scoreCount[1].text = ramNumber.number
      if(this.numberedCardsFound !== 0) this.numberedCardsFound += 1
    }

    const turkeyNumber = targetsHit.find((target) => target.name === "Turkey" && target.number !== null)
    if(turkeyNumber && this.scoreCount[2].text === "?"){
      this.scoreCount[2].text = turkeyNumber.number
      if(this.numberedCardsFound !== 0) this.numberedCardsFound += 1
    }

    const mooseNumber = targetsHit.find((target) => target.name === "Deer" && target.number !== null)
    if(mooseNumber && this.scoreCount[3].text === "?"){
      this.scoreCount[3].text = mooseNumber.number
      if(this.numberedCardsFound !== 0) this.numberedCardsFound += 1
    }

    if(this.numberedCardsFound === 0) { // set value from current game state
      const numberedTargetsHit = targetsHit.filter((target) => target.number !== null)
      console.log(numberedTargetsHit)
      this.numberedCardsFound = numberedTargetsHit.length
    }

    // show top for users scores
    // let sortedUsers = users.sort((a, b) => b.score - a.score);
    // console.log(sortedUsers)

    // Score of top four users are shown
    // if(sortedUsers){
    //   const loopLimit = sortedUsers.length <= 4 ? sortedUsers.length : 4
    //   for(var i = 0; i < loopLimit; i++) {
    //     this.scoreText[i].text = sortedUsers[i].name ? sortedUsers[i].name : "USER"
    //     this.scoreCount[i].text = sortedUsers[i].score
    //   }
    // }

  }

  updateVisibleCursors = (usersData) => {
    const scene = this
    let otherUsers = usersData.filter((user) => user.id !== CarnivalSocket.getSocetId())

    // delete previously rendered cursors and user names
    while(scene.alreadyRenderedCursors.length > 0) {
      scene.alreadyRenderedCursors.pop().destroy(true);
      scene.alreadyRenderedNames.pop().destroy(true);
    } 

    otherUsers.forEach(function(user) {
      let xPos = user?.cursors?.x;
      let yPos = user?.cursors?.y;
      let name = user.name ? user.name : "user";

      if(xPos && yPos){
        let cursorImage = scene.add.sprite(xPos, yPos, 'crosshair')
        cursorImage.setDepth(5)
        let cursorText = scene.add.text(xPos + 40,yPos + 40, name, { fontFamily: "Arial", fontSize: 25, color: "#00ff00" })
        cursorText.setDepth(5)

        scene.alreadyRenderedCursors.push(cursorImage)
        scene.alreadyRenderedNames.push(cursorText)
      }
    })
  }
}
