import React from "react";
import styled from 'styled-components'


const VideoContainer = (props) => {
	const { activeVideoSrc } = props
	return (
		<StyledVideoContainer>
			<div className="iframe-content">
				<div className="iframe-scaler">
					<iframe title="Active Video" src={activeVideoSrc}></iframe>
				</div>
			</div>
		</StyledVideoContainer>
	)
}

export default VideoContainer

const StyledVideoContainer = styled.div`
	background: rgba(11, 11, 11, .8);
	height: 100%;
	left: 0;
	overflow: hidden;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1050;
	.iframe-content {
		left: 50%;
		max-width: 900px;
		top: 50%;
		transform: translate(-50%,-50%);
		width: 95%;
		position: relative;
		display: inline-block;
		vertical-align: middle;
		margin: 0 auto;
		text-align: left;
		z-index: 1045;
	}
	.iframe-scaler {
		height: 0;
		overflow: hidden;
		padding-top: 56.25%;
		width: 100%;
	}
	iframe {
		background: #000;
		border-width: 0;
		box-shadow: 0 0 8px rgba(0, 0, 0, .6);
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
`
