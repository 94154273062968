/* eslint-disable */

import React, { useState, useEffect, useRef } from "react";

// Packages
import { INITIAL_STATE } from "../../lib/constants"
import { fetchGameAndSetGameMedia, getCookie, PLAYER_COOKIE_PREFIX, validatePlayer, fetchTrackingData } from "../../lib/data"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Slider from 'react-slick'
import SimpleBar from 'simplebar-react'
import Viewer from 'react-viewer'

// Styles
import 'react-tabs/style/react-tabs.css'
import 'simplebar/dist/simplebar.min.css'
import "Pages/PlayerDashboard/PlayerDashboard-v3.scss"

// Audio Imports
import * as noise from 'audio'

import PlayerAuthModule from "../../Components/Play/PlayerAuthModule/PlayerAuthModule"

// Components
import Footer from "Components/Play/Footers/Footer"
import Header from "Components/Play/Headers/HeaderV3"
import PostGame from "Pages/PlayerDashboard/PostGame/PostGame"
import VideoContainer from 'Pages/PlayerDashboard/VideoContainer.js'
import MiniGameContainer from 'Pages/PlayerDashboard/MiniGameContainer.js'

import getThemeSettings from "Pages/PlayerDashboard/themeSettingsV2"
import mixpanel from "lib/mixpanel"
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'


const PlayerDashboard = (props) => {
  const search = window.location.search;
  const gameId = props.match.params.game;
  const playState = props.playState
  const theme = props.theme
  const socket = props.socket
  const [clue, setClue] = useState("");
  const [gameMedia, setGameMedia] = useState(INITIAL_STATE.GAME_MEDIA);
  const [scans, setScans] = useState([]);
  const [sounds, setSounds] = useState([]);
  const [videos, setVideos] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [audio, setAudio] = useState('');
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isAuthLoading, setAuthLoading] = useState(true);
  const [activeAsset, setActiveAsset] = useState(null)
  const [newAssets, setNewAssets] = useState({inventory:[], locks:[], videos:[], scans:[], toys:[]})
  const [isGameActive, setIsGameActive] = useState(null)
  const [activeVideoSrc, setActiveVideoSrc] = useState(null)
  const [activeMiniGame, setActiveMiniGame] = useState(null)
  const [themeSettings, setThemeSettings] = useState({})
  const [trackingData, setTrackingData] = useState()
  const [flagsReady, setFlagsReady] = useState(false)
  const { itemGrid } = useFlags()
  const activeTab = useRef(0)
  const ldClient = useLDClient()
  
  const changeActiveAsset = (e) => {
    const assetType = e.currentTarget.parentElement.getAttribute('type')
    const newActiveAssetId = parseInt(e.currentTarget.parentElement.getAttribute('value'))
    const newActiveAsset = {
      id: newActiveAssetId,
      type: assetType,
    }
    switch (assetType) {
      case 'inventory':
        const objInventory = gameMedia.baseInventory.find(o => o.id === newActiveAssetId);
        newActiveAsset.src = objInventory.data.src
        if (objInventory.data.sound) {
          if (noise[objInventory.data.sound]) noise[objInventory.data.sound].play()
        }
        break
      case 'lock':
        const objLock = gameMedia.lockInventory.find(o => o.id === newActiveAssetId);
        newActiveAsset.src = objLock.data.src
        break
      case 'toys':
        const objToys = gameMedia.foundedToys.find(o => o.id === newActiveAssetId);
        newActiveAsset.src = objToys.data.src
        break
      case 'scan':
        const objScan = gameMedia.scans.find(o => o.id === newActiveAssetId);
        newActiveAsset.src = objScan.data.src
        break
      case 'video':
        const objVideo = gameMedia.videos.find(o => o.id === newActiveAssetId);
        newActiveAsset.src = objVideo.data.src
        break
    }
    setActiveAsset(newActiveAsset)
    const tracking = {
      ...gameMedia.tracking,
      "item_type": assetType,
      "item_id": newActiveAssetId,
      "item_name": e.currentTarget.querySelector('.item-name').innerText
    }
    mixpanel.track('Item View', tracking)
  }

  useEffect(() => {
    socket.on("toggle-post-game", (isGameActive) => {
      setIsGameActive(isGameActive);
    });
    setActiveAsset({
      id: null,
      type: 'default',
      src: themeSettings.gameLogo
    })
    return () => {
      socket.off("toggle-post-game")
    }
  }, [themeSettings]);

  const hydrateFromServer = (stateFromServer) => {
    let state = stateFromServer;
    if (typeof stateFromServer === 'string')
      state = JSON.parse(stateFromServer);

    if (state) {
      if (state.inventory) setInventory(state.inventory);
      if (state.scans) setScans(state.scans);
      if (state.activeClue) setClue(state.activeClue);
      if (state.videos) setVideos(state.videos);
      if(state.sounds) setSounds(state.sounds);
      setIsGameActive(typeof state.isGameActive === 'undefined' ? true : state.isGameActive)
    }
  };

  useEffect(() => {
    if (gameId) {
      fetchTrackingData(gameId, setTrackingData)
    }
  }, [gameId])

  useEffect(() => {
    if (typeof playState !== 'undefined' && flagsReady) {
      hydrateFromServer(playState);
      fetchGameAndSetGameMedia(gameId, setGameMedia, itemGrid);
    }
  }, [playState, gameId, flagsReady])

  useEffect(() => {
    if (trackingData && ldClient) {
      ldClient.identify({
        key: 'remote-adventures',
        anonymous: true,
        custom: {
          event_id: trackingData?.event_id,
          x_event_id: trackingData?.x_event_id,
          experience_id: trackingData?.experience_id,
          store_id: trackingData?.store_id,
          theme: trackingData?.theme
        }
      }, null, () => {
        setFlagsReady(true)
      })
    }
  }, [trackingData, ldClient])

  useEffect(() => {
    if (gameMedia) {
      const newThemeSettings = getThemeSettings(theme, gameMedia.customTheming)
      setThemeSettings(newThemeSettings)
      if ('customTheming' in gameMedia) setIsLoading(false)
    }
  }, [gameMedia])

  useEffect(() => {
    socket.on("clue", (clue) => {
      setClue(clue);
      noise["clue"].play()
    });
  }, [clue]);

  useEffect(() => {
    socket.on("scan-add", (scan) => {
      updateBadgeCounts(gameMedia, scan)
      setScans((scans) => [...scans, scan]);
      noise["scan"].play()
    });

    socket.on("scan-remove", (mediaId) => {
      updateBadgeCounts(gameMedia, mediaId, false)
      setScans((scans) => scans.filter((scan) => scan !== mediaId));
    });

    return () => {
      socket.off("scan-add")
      socket.off("scan-remove")
    }
  }, [gameMedia]);

  useEffect(() => {
    socket.on("sound-add", (soundItem) => {
      const sound = soundItem.id;
      setAudio(soundItem.data.src);
      const soundElement = document.getElementById('sound-player');
      soundElement.setAttribute('src', soundItem.data.src);
      soundElement.play().then(() => {
      }).catch(() => {
        if (confirm("Click ok to play sounds!")) {
          soundElement.play()
        } else {
          soundElement.play()
        }
      });
      setSounds((sounds) => [...sounds, sound]);
    });

    socket.on("sound-remove", (mediaId) => {
      setSounds((sounds) => sounds.filter((sound) => sound !== mediaId));
      const soundElement = document.getElementById('sound-player');
      soundElement.pause();
    });
  }, []);

  useEffect(() => {
    socket.on("video-add", (video) => {
      const objVideo = gameMedia.videos.find(o => o.id === video);
      setActiveVideoSrc(objVideo.data.src)
    });

    socket.on("video-remove", (mediaId) => {
      const objVideo = gameMedia.videos.find(o => o.id === mediaId)
      if (activeVideoSrc === objVideo.data.src) setActiveVideoSrc(null)
    });
    return () => {
      socket.off("video-add")
      socket.off("video-remove")
    }
  }, [gameMedia, activeVideoSrc]);

  useEffect(() => {
    socket.on("minigame-add", (minigameId) => {
      const objMiniGame = gameMedia.minigames.find(o => o.id === minigameId);
      setActiveMiniGame(objMiniGame.data.src)
    });

    socket.on("minigame-remove", (minigameId) => {
      setActiveMiniGame(null)
    });
    return () => {
      socket.off("minigame-add")
      socket.off("minigame-remove")
    }
  }, [gameMedia]);

  useEffect(() => {
    socket.on("inventory-add", (item) => {
      updateBadgeCounts(gameMedia, item)
      setInventory((inventory) => [...inventory, item]);
      noise["inventory"].play()
    });

    socket.on("inventory-remove", (inventoryId) => {
      updateBadgeCounts(gameMedia, inventoryId, false)

      // change viewer to game logo if current asset in view
      // is removed from dashboard by host
      if (['inventory', 'lock'].includes(activeAsset.type) && activeAsset.id === inventoryId) {
        setActiveAsset({
          id: null,
          type: 'default',
          src: themeSettings.gameLogo
        })
      }

      setInventory((inventory) =>
        inventory.filter((item) => item !== inventoryId)
      );
    });

    return () => {
      socket.off("inventory-add")
      socket.off("inventory-remove")
    }
  }, [gameMedia, activeAsset]);

  useEffect(() => {
    checkAuthFromCookies()
  }, [])

  function updateBadgeCounts(gameMedia, assetId, add=true) {
    const objInventory = gameMedia.baseInventory.find(o => o.id === parseInt(assetId))
    if (objInventory) {
      if (activeTab.current !== 0) {
        setNewAssets(prev => {
          const index = prev.inventory.findIndex(x => x === assetId)
          if (index === -1) { if (add) { prev.inventory.push(assetId) } }
          else { if (!add) { prev.inventory.splice(index) } }
          return prev
        })
      }
    } else {
      const objLock = gameMedia.lockInventory.find(o => o.id === assetId)
      if (objLock) {
        if (activeTab.current !== 1) {
          setNewAssets(prev => {
            const index = prev.locks.findIndex(x => x === assetId)
            if (index === -1) { if (add) { prev.locks.push(assetId) } }
            else { if (!add) { prev.locks.splice(index) } }
            return prev
          })
        }
      } else {
        const objScan = gameMedia.scans.find(o => o.id === assetId)
        if (objScan) {
          if (activeTab.current !== 2) {
            setNewAssets(prev => {
              const index = prev.scans.findIndex(x => x === assetId)
              if (index === -1) { if (add) { prev.scans.push(assetId) } }
              else { if (!add) { prev.scans.splice(index) } }
              return prev
            })
          }
        } else {
          const objToy = gameMedia.foundedToys.find(o => o.id === assetId)
          if (objToy) {
            if (activeTab.current !== 3) {
              setNewAssets(prev => {
                const index = prev.toys.findIndex(x => x === assetId)
                if (index === -1) { if (add) { prev.toys.push(assetId) } }
                else { if (!add) { prev.toys.splice(index) } }
                return prev
              })
            }
          } else {
            if (activeTab.current !== 2) {
              setNewAssets(prev => {
                const index = prev.videos.findIndex(x => x === assetId)
                if (index === -1) { if (add) { prev.videos.push(assetId) } }
                else { if (!add) { prev.videos.splice(index) } }
                return prev
              })
            }
          }
        }
      }
    }
  }

  const checkAuthFromCookies = async () => {
    const [authGameId, authGamePass] = getCookie(`${PLAYER_COOKIE_PREFIX}_auth`) ? getCookie(`${PLAYER_COOKIE_PREFIX}_auth`).split('_') : [undefined, undefined];
    const host_token = getCookie(`host_token`);
    if((authGameId && (gameId === authGameId))) {
      const gameRes = await validatePlayer(authGameId, authGamePass, host_token);
      if(gameRes.success) {
        setAuthenticated(true);
      }
    } else if (host_token && host_token !== '') {
      const hostLogin = await validatePlayer(gameId, '', host_token);
      if(hostLogin.success) {
        setAuthenticated(true);
      }
    }
    setAuthLoading(false);
  }

  if (isAuthLoading || isLoading) {
    return (
      <div className="loading">
        <div className="lds-dual-ring"></div>
      </div>
    );
  }

  if(!isAuthenticated) {
    return <PlayerAuthModule gameId={gameId} setAuthenticated={setAuthenticated} theme={theme}/>
  }

  const wrapperStyles = (themeSettings) => {
    let bgImageAttributes = []
    if (themeSettings.backgroundImageGradient) bgImageAttributes.push(themeSettings.backgroundImageGradient)
    if (themeSettings.backgroundImage) bgImageAttributes.push(`url(${themeSettings.backgroundImage})`)
    const backgroundImage = bgImageAttributes.join(', ')
    return {
      backgroundColor: themeSettings.backgroundColor,
      backgroundImage: backgroundImage,
    }
  }

  function changeActiveTab(tabIndex, tabName) {
    if (tabIndex !== activeTab.current) {
      activeTab.current = tabIndex
      if (tabIndex === 0) {
        setNewAssets(prev => { return { ...prev, inventory: [] } })
      }
      if (tabIndex === 1) {
        setNewAssets(prev => { return { ...prev, locks: [] } })
      }
      if (tabIndex === 2) {
        setNewAssets(prev => { return { ...prev, scans: [], videos: [] } })
      }
      if (tabIndex === 3) {
        setNewAssets(prev => { return { ...prev, toys: [] } })
      }
      const tracking = {
        ...gameMedia.tracking,
        "tab": tabName
      }
      mixpanel.track('Tab Navigation', tracking)
    }
  }

  const onClickZoom = (e) => {
    mixpanel.track('Zoom Background Download', gameMedia.tracking)
  }

  const assetProps = {
    theme,
    themeSettings,
    gameMedia,
    activeAssetIds: inventory,
    videos,
    scans,
    changeActiveAsset,
    activeTab,
    setNewAssets,
    newAssets,
    changeActiveTab,
  }

  return (
    <div className="player-dashboard-v3">
      {activeVideoSrc &&
        <VideoContainer activeVideoSrc={activeVideoSrc} />
      }
      {activeMiniGame &&
        <MiniGameContainer activeMiniGame={activeMiniGame} gameId={gameId} />
      }
      <div>
        <audio id='sound-player' controls={false} loop={false} style={{'display':"none"}}>
          <source src={audio}/>
        </audio>
      </div>
      <div id="dash-container" className={theme} style={wrapperStyles(themeSettings)}>
        <div className="wrapper">
          <Header themeSettings={themeSettings} onClickZoom={onClickZoom} socket={socket} playState={playState} tracking={gameMedia.tracking} theme={theme} />
          <AssetViewer themeSettings={themeSettings} activeAsset={activeAsset} theme={theme} />
          <Assets {...assetProps}/>
        </div>
        {(themeSettings.footerImage || themeSettings.footerText) &&
          <Footer themeSettings={themeSettings}/>
        }
        {isGameActive === false &&
          <PostGame gameId={gameId} themeSettings={themeSettings} tracking={gameMedia.tracking} />
        }
      </div>
      {(themeSettings.fixedPositionElement) &&
        <themeSettings.fixedPositionElement />
      }
    </div>
  );
};

export default PlayerDashboard;


const AssetViewer = (props) => {
  const { themeSettings, activeAsset, theme } = props
  const { viewerColor } = themeSettings
  useEffect(() => {
    setMediaViewerWidth()
    window.addEventListener('resize', setMediaViewerWidth)
    return () => window.removeEventListener('resize', setMediaViewerWidth)
  }, [themeSettings])

  const viewerStyles = (themeSettings) => {
    return {
      backgroundColor: themeSettings.viewerBackgroundColor,
      border: themeSettings.viewerBorder,
    }
  }
  
  return (
    <div id="viewer" style={viewerStyles(themeSettings)}>
      {activeAsset.type === 'default' &&
        <div className="img-wrapper">
          <img src={activeAsset.src} />
        </div>
      }
      {activeAsset && ['inventory', 'lock'].includes(activeAsset.type) &&
        <Viewer
          visible={true}
          images={[{src:`${activeAsset.src}`, alt:''}]}
          container={document.getElementById("viewer")}
          rotatable={false}
          noClose={true}
          noImgDetails={true}
          noNavbar={true}
          changeable={false}
          showTotal={false}
          scalable={false}
          defaultScale={1.15}
          zoomSpeed={.2}
          minScale={.5}
        />
      }
      {activeAsset && activeAsset.type === 'scan' &&
        <iframe src={activeAsset.src}></iframe>
      }
    </div>
  )
}

const Assets = (props) => {
  const {
    themeSettings, gameMedia, activeAssetIds, videos, scans,
    changeActiveAsset, activeTab, newAssets, changeActiveTab
  } = props
  const [wrapperWidth, setWrapperWidth] = useState(1000)

  const getTabName = (tabIndex) => {
    const tabList = document.querySelector('.react-tabs__tab-list')
    const tabName = tabList.children[tabIndex].querySelector('h2').innerText
    changeActiveTab(tabIndex, tabName)
  }


  useEffect(() => {
    const createNewWrapperWidth = () => {
      const wrapper = document.querySelector('.wrapper')
      const newWrapperWidth = wrapper ? wrapper.offsetWidth : 1000
      setWrapperWidth(newWrapperWidth)
    }
    createNewWrapperWidth()
    window.addEventListener('resize', createNewWrapperWidth)
    return () => window.removeEventListener("resize", createNewWrapperWidth)
  }, [])

  const PrevArrow = (props) => {
    const {onClick, className} = props
    const isDisabled = className.includes('slick-disabled') ? ' isDisabled' : ''
    return (
      <div className={`prevAsset${isDisabled}`} onClick={onClick}>
        <img src={themeSettings.prevButtonImage}/>
      </div>
    )
  }
  const NextArrow = (props) => {
    const {onClick, className} = props
    const isDisabled = className.includes('slick-disabled') ? ' isDisabled' : ''
    return (
      <div className={`nextAsset${isDisabled}`} onClick={onClick}>
        <img src={themeSettings.nextButtonImage}/>
      </div>
    )
  }
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      }
    ]
  }

  return (
    <div id="assets">
      {/* tab documentation linked below */}
      {/* https://github.com/reactjs/react-tabs */}
      <Tabs defaultIndex={activeTab.current} onSelect={getTabName}>

        <TabList>
          {gameMedia.baseInventory.length > 0 &&
            <Tab className="inventory-tab tab" style={{
              background: themeSettings.tabInventoryColor
            }}>
              <div className="tab-content">
                <img src={themeSettings.iconInventory} alt=""/>
                <h2>{themeSettings.tabInventoryLabel}</h2>
                {newAssets.inventory.length > 0 &&
                  <span>{newAssets.inventory.length}</span>
                }
              </div>
            </Tab>
          }
          {gameMedia.lockInventory.length > 0 &&
            <Tab className="locks-tab tab" style={{
              background: themeSettings.tabLocksColor
            }}>
              <div className="tab-content">
                <img src={themeSettings.iconLocks} alt=""/>
                <h2>{themeSettings.tabLocksLabel}</h2>
                {newAssets.locks.length > 0 &&
                  <span>{newAssets.locks.length}</span>
                }
              </div>
            </Tab>
          }
          {(gameMedia.scans.length + gameMedia.videos.length) > 0 &&
            <Tab className="views-tab tab" style={{
              background: themeSettings.tabViewsColor
            }}>
              <div className="tab-content">
                <img src={themeSettings.iconViews} alt=""/>
                <h2>{themeSettings.tabViewsLabel}</h2>
                {newAssets.scans.length > 0 &&
                  <span>{newAssets.scans.length}</span>
                }
              </div>
            </Tab>
          }
        </TabList>

        {gameMedia.baseInventory.length > 0 &&
          <TabPanel>
            <div className="inventory-panel panel slider" style={{
              background: themeSettings.panelInventoryColor
            }}>
              <Slider {...sliderSettings}>
                {gameMedia.baseInventory.length && gameMedia.baseInventory.map((asset) =>
                  activeAssetIds.includes(asset.id) && (
                    <div key={asset.id} value={asset.id} type="inventory" className="inventory-asset asset">
                      <div className="asset-wrapper" onClick={changeActiveAsset}>
                        <div className="asset-img-wrapper">
                          <img src={asset.data.src}  style={themeSettings.panelAssetImgStyles}/>
                        </div>
                        <p className="item-name" style={{color: themeSettings.panelInventoryTextColor}}>{asset.displayName}</p>
                      </div>
                    </div>
                  )
                )}
              </Slider>
            </div>
            <SimpleBar className="inventory-panel panel scroll" style={{
              background: themeSettings.panelInventoryColor
            }}>
                {gameMedia.baseInventory.length && gameMedia.baseInventory.map((asset) =>
                  activeAssetIds.includes(asset.id) && (
                    <div key={asset.id} value={asset.id} type="inventory" className="inventory-asset asset">
                      <div className="asset-wrapper" onClick={changeActiveAsset}>
                        <div className="asset-img-wrapper">
                          <img src={asset.data.src} style={themeSettings.panelAssetImgStyles}/>
                        </div>
                        <p className="item-name" style={{color: themeSettings.panelInventoryTextColor}}>{asset.displayName}</p>
                      </div>
                    </div>
                  )
                )}
            </SimpleBar>
          </TabPanel>
        }
        {gameMedia.lockInventory.length > 0 &&
          <TabPanel>
            <div className="locks-panel panel slider" style={{
              background: themeSettings.panelLocksColor
            }}>
              <Slider {...sliderSettings}>
                {gameMedia.lockInventory.length && gameMedia.lockInventory.map((asset) =>
                  activeAssetIds.includes(asset.id) && (
                    <div key={asset.id} value={asset.id} type="lock" className="lock-asset asset">
                      <div className="asset-wrapper" onClick={changeActiveAsset}>
                        <div className="asset-img-wrapper">
                          <img src={asset.data.src} style={themeSettings.panelAssetImgStyles}/>
                        </div>
                        <p className="item-name" style={{color: themeSettings.panelLocksTextColor}}>{asset.displayName}</p>
                      </div>
                    </div>
                  )
                )}
              </Slider>
            </div>
            <SimpleBar className="locks-panel panel scroll" style={{
              background: themeSettings.panelLocksColor
            }}>
              {gameMedia.lockInventory.length && gameMedia.lockInventory.map((asset) =>
                activeAssetIds.includes(asset.id) && (
                  <div key={asset.id} value={asset.id} type="lock" className="lock-asset asset">
                      <div className="asset-wrapper" onClick={changeActiveAsset}>
                        <div className="asset-img-wrapper">
                          <img src={asset.data.src} style={themeSettings.panelAssetImgStyles}/>
                        </div>
                        <p className="item-name" style={{color: themeSettings.panelLocksTextColor}}>{asset.displayName}</p>
                      </div>
                  </div>
                )
              )}
            </SimpleBar>
          </TabPanel>
        }
        {(gameMedia.scans.length + gameMedia.videos.length) > 0 &&
          <TabPanel>
            <div className="views-panel panel slider" style={{
              background: themeSettings.panelViewsColor
            }}>
              <Slider {...sliderSettings}>
                {/* {gameMedia.videos && gameMedia.videos.map((video) =>
                  videos.includes(video.id) && (
                    <div key={video.id} value={video.id} type="video" className="views-asset asset">
                      <div className="asset-wrapper" onClick={changeActiveAsset} style={{backgroundImage:`linear-gradient(rgba(0,0,0,.25), rgba(0,0,0,.5)), url(${video.data.thumbnail})`}}>
                        <span className="item-name">{video.data.name.toUpperCase()}</span>
                      </div>
                    </div>
                  )
                )} */}
                {gameMedia.scans && gameMedia.scans.map((scan) =>
                  scans.includes(scan.id) && (
                    <div key={scan.id} value={scan.id} type="scan" className="views-asset asset">
                      <div className="asset-wrapper" onClick={changeActiveAsset} style={{backgroundImage:`linear-gradient(rgba(0,0,0,.25), rgba(0,0,0,.5)), url(${scan.data.thumbnail})`}}>
                        <span className="item-name" style={{color: themeSettings.panelExploreTextColor}}>{scan.data.name.toUpperCase()}</span>
                      </div>
                    </div>
                  )
                )}
              </Slider>
            </div>
            <SimpleBar className="views-panel panel scroll" style={{
              background: themeSettings.panelViewsColor
            }}>
              {/* {gameMedia.videos && gameMedia.videos.map((video) =>
                videos.includes(video.id) && (
                  <div key={video.id} value={video.id} type="video" className="views-asset asset">
                      <div className="asset-wrapper" onClick={changeActiveAsset} style={{backgroundImage:`linear-gradient(rgba(0,0,0,.25), rgba(0,0,0,.5)), url(${video.data.thumbnail})`}}>
                        <span className="item-name" style={{color: themeSettings.panelLocksTextColor}}>{video.data.name.toUpperCase()}</span>
                      </div>
                  </div>
                )
              )} */}
              {gameMedia.scans && gameMedia.scans.map((scan) =>
                scans.includes(scan.id) && (
                  <div key={scan.id} value={scan.id} type="scan" className="views-asset asset">
                      <div className="asset-wrapper" onClick={changeActiveAsset} style={{backgroundImage:`linear-gradient(rgba(0,0,0,.25), rgba(0,0,0,.5)), url(${scan.data.thumbnail})`}}>
                        <span className="item-name" style={{color: themeSettings.panelExploreTextColor}}>{scan.data.name.toUpperCase()}</span>
                      </div>
                  </div>
                )
              )}
            </SimpleBar>
          </TabPanel>
        }

      </Tabs>
    </div>
  )
}

function setMediaViewerWidth() {
  // sets media viewer width so that entire viewer is within view
  // goal to eliminate need to scroll to see entire viewer
  const wrapper = document.querySelector('#dash-container .wrapper')
  const header = document.getElementById('header');
  const assets = document.getElementById('assets');
  const footer = document.getElementById('footer');
  const headerHeight = header.offsetHeight
  const footerHeight = footer ? footer.offsetHeight : 0
  const assetsPaddingTop = parseInt(window.getComputedStyle(assets).paddingTop.slice(0, -2))
  const assetsPaddingBottom = parseInt(window.getComputedStyle(assets).paddingBottom.slice(0, -2))
  const assetsMinHeight = 190 + assetsPaddingTop + assetsPaddingBottom
  const wrapperXPadding = 15
  const viewerBorderWidth = parseInt(window.getComputedStyle(viewer).borderWidth.slice(0, -2))
  const viewerMaxHeight = window.innerHeight - headerHeight - footerHeight - assetsMinHeight - (viewerBorderWidth * 2)
  const viewerMaxWidth = window.innerWidth - (wrapperXPadding * 2)
  const viewerMinWidth = Math.min(viewerMaxWidth, 500)
  const viewerHeightBasedOnMaxWidth = viewerMaxWidth * .5625
  const newViewerWidth = viewerHeightBasedOnMaxWidth > viewerMaxHeight ? viewerMaxHeight / .5625 : viewerMaxWidth
  const newAssetsHeight = window.innerHeight - (headerHeight + footerHeight + (newViewerWidth * .5625) + (viewerBorderWidth * 2))
  wrapper.style.maxWidth = Math.max(newViewerWidth, viewerMinWidth) + 'px'
  assets.style.height = Math.max(assetsMinHeight, newAssetsHeight) + 'px'
}
