import React, { useEffect } from "react";
import BallSortGame from "./phaser/";
import * as BallSortSocket from "./ballSortSocket";

export default function BallSort(props) {
  const { gameId } = props

  useEffect(() => {
    BallSortSocket.connectSocket();
    BallSortSocket.joinMiniGame(gameId)
    const ballSortGame = new BallSortGame() 
    return () => {
        ballSortGame.cleanup()
        BallSortSocket.disconnect()
    }
  }, [gameId])

  return (
    <div id="minigame_container"></div>
  )
}
