/* eslint-disable */
import React, { useEffect, useState } from 'react'

// Styles
import './PlayerInventoryModal.scss'
import { Modal, Button } from 'react-bootstrap'

const PlayerInventoryModal = ({ item, onClose }) => {
  const [openStatus, setOpenStatus] = useState(false)

  useEffect(() => {
    if (item.id) {
      console.log(item)
      setOpenStatus(true)
    }
  }, [item])

  const hideModal = () => {
    onClose()
    setOpenStatus(false)
  }

  return (
    <Modal size="xl" show={openStatus} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{item.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img className="modal-image" src={item.data.src} />
      </Modal.Body>
    </Modal>
  )
}

export default PlayerInventoryModal
