import React from "react";


const HeaderMBS = (props) => {
  const { onClickZoom } = props
  const logo = "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/game-logo.png"
  const downloadLink = "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/mbs-zoom-backgrounds.zip"
  const zoom = "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/zoom-download-link-image.png"
  return (
    <div id="header">
      <img className="logo" alt="Rugrats" src={logo} />
      <a className="zoomDownloadLink desktop" href={downloadLink} onClick={onClickZoom}>
        <img className="zoom" alt="Download Zoom Background" src={zoom} />
      </a>

    </div>
  )
}

export default HeaderMBS
