import UiFx from "uifx"

import {default as BirdSound} from 'audio/birdsfx.wav'
import {default as ClueSound} from 'audio/clue_sound.wav'
import {default as FrogSound} from 'audio/frogsfx.wav'
import {default as InventorySound} from 'audio/inventory_sound.mp3'
import {default as OwlSound} from 'audio/owlsfx.wav'
import {default as ScanSound} from 'audio/scan_sound.wav'

export const bird = new UiFx(BirdSound, {volume: 0.25})
export const clue = new UiFx(ClueSound, {volume: 0.25})
export const frog = new UiFx(FrogSound, {volume: 0.25})
export const inventory = new UiFx(InventorySound, {volume: 0.25})
export const owl = new UiFx(OwlSound, {volume: 0.25})
export const scan = new UiFx(ScanSound, {volume: 0.25})

