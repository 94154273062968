import {LIST_ROOMS, ROOM_SUCCESS, ROOM_ERROR} from "../actions";

const initialState = {
  rooms: [],
  isLoading: false,
  error: null
};

export function roomReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_ROOMS:
      return {
        rooms: [],
        isLoading: true,
        error: null
      };
    case ROOM_SUCCESS:
      return {
        rooms: action.payload.experiences || state.rooms,
        isLoading: false,
        error: null
      };
    case ROOM_ERROR:
      return {
        rooms: [],
        isLoading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}
