import Phaser from "phaser";

export default class End extends Phaser.Scene {
    constructor() {
        super({ key: 'End', active: false});
        this.winner = ""
        console.log("End constructor");
    }

    init(data) {
        this.winner = data?.winner
    }

    create() {
        let scene=this;
        this.isRunning=true;
        this.videoPlaying=false;
       /*  this.canvas = this.sys.canvas;
        this.canvas.style.cursor = 'none';
        this.crossHair = this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, "crosshair");
        this.crossHair.setDepth(5); 
        this.input.on('pointermove', function (pointer) {
            scene.crossHair.x = pointer.x;
            scene.crossHair.y = pointer.y;
        }); */

        this.form = this.add.dom().createFromCache("dom2");
        this.form.originX = 0;
        this.form.originY = 0;

        if(this.winner) {
            this.form.getChildByID("win").innerHTML = `${this.winner} made it in!` 
        }


        this.bg = this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, "bg");
        this.logo=this.add.image(this.game.renderer.width * 0.218, this.game.renderer.height * 0.148, "logo");
        this.course=this.add.image(this.game.renderer.width*0.719, this.game.renderer.height*0.5, "end");

        this.box1=this.add.image(this.game.renderer.width*0.218, this.game.renderer.height*0.413, "boxE");

        setTimeout(() => {
            // this.scene.start("Putt");
            this.videoPlaying=true;
      	    this.form.setVisible(false);
            this.vid = this.add.video(this.game.renderer.width/2, this.game.renderer.height/2, 'vid').setScale(2,2);
            this.vid.setLoop(false);
            this.vid.play(true);
            this.vid.setPaused(false);
        }, 5000);   
    }

    update()
    {
        if(this.videoPlaying)
        {
            var duration=0;
            duration=this.vid.getCurrentTime();
            if(duration>=this.vid.getDuration()-0.05)
            {
                this.videoPlaying=false;
                this.vid.stop();
            }
        }
        
    }
}

