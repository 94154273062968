import Phaser from "phaser";

export default class Preload extends Phaser.Scene {
  constructor() {
    super({ key: "Preload", active: true });
  }

  init() {}

  preload() {
    //BallSort
    this.load.image("bg", "/assets/images/carnival/BlockGrid/background.png");
    this.load.image("logo", "/assets/images/carnival/BlockGrid/PG_Logo.png");
    this.load.image("ballsort", "/assets/images/carnival/BallSort/Ball_Sort.png");
    this.load.image("release", "/assets/images/carnival/BallSort/Release.png");
    this.load.image("tube", "/assets/images/carnival/BallSort/Tube.png");
    this.load.image("tubeH", "/assets/images/carnival/BallSort/Tube_Highlight.png");
    this.load.image("boxB", "/assets/images/carnival/BallSort/WhiteBox.png");
    this.load.image("boxB2", "/assets/images/carnival/BallSort/Whitebox2.png");

    this.load.image("blueball", "/assets/images/carnival/BallSort/Blue_Ball.png");
    this.load.image("greenball", "/assets/images/carnival/BallSort/Green_Ball.png");
    this.load.image("redball", "/assets/images/carnival/BallSort/Red_Ball.png");
    this.load.image("yellowball", "/assets/images/carnival/BallSort/Yellow_Ball.png");
    this.load.image("orangeball", "/assets/images/carnival/BallSort/Orange_Ball.png");
    this.load.image("pinkball", "/assets/images/carnival/BallSort/Pink_Ball.png");

    this.load.image("crosshair", "/assets/images/carnival/Environment/icons8-hand-cursor-50.png");
  }

  create() {
    this.StartGame();
  }

  StartGame (){
    this.scene.start('BallSortGameScene'); 
  }

  cleanup() {
    // if (this.music.isPlaying) {
    //   this.music.stop();
    // }
  }
  
}
