import io from "socket.io-client";
import { TEG_APP } from "lib/constants";

let BlockGridSocket;
let currentGameId;

export const connectSocket = () => {
  BlockGridSocket = io(TEG_APP.ENDPOINT);
};

export const getSocetId = () => {
  return BlockGridSocket.id;
};

export const joinMiniGame = (gameId) => {
  currentGameId = gameId
  let user = JSON.parse(localStorage.getItem("userInfo"))
  BlockGridSocket.emit("join_minigame", gameId, "BlockGrid", user && user.firstName ? user.firstName : "user", () =>
    console.log(`Sent join emission to server.`)
  );
};

export const getUpdatedState = (GameScene) => {
  BlockGridSocket.on("minigame_state_update", (data) => {
    // console.log(data)
    GameScene.updateVisibleCursors(data)
    // GameScene.updateScoreBoard(data.targetsHit)
    return data;
  });
};

export const sendCursorUpdate = (cursorData) => {
  BlockGridSocket.emit("minigame_send_cursor_update", cursorData);
};

export const toggleBlockState = (quadId, blockId, state) => {
  BlockGridSocket.emit("toggle_block_state", currentGameId, quadId, blockId, state);
}; 

export const ListenForBlockStateChange = (GameScene) => {
  BlockGridSocket.on("on_block_state_change", ( quadId, blockId, state ) => {
    GameScene.onBlockClickedByAnotherUser(quadId, blockId, state)
  });
}


export const disconnect = () => {
  if (!BlockGridSocket) return true;

  BlockGridSocket.emit("disconnect_minigame", currentGameId, "BlockGrid");

  BlockGridSocket.removeAllListeners();
  BlockGridSocket.disconnect();
};

