

const tabStylesDefault = (gameMedia, windowWidth) => {
  return {
    inventory: {
      left: 'unset',
      width: 'auto',
    },
    "locks": {
      left: 'unset',
      width: 'auto',
    },
    "views": {
      left: 'unset',
      width: 'auto',
    },
    "founded": {
      left: 'unset',
      width: 'auto',
    },
  }
}
  
export default tabStylesDefault
