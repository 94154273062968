import Phaser from "phaser";

export default class Preload extends Phaser.Scene {
  constructor() {
    super({ key: "Preload", active: true });
  }

  init() {}

  preload() {
     //Putt
     this.load.image("bg", "/assets/images/carnival/BlockGrid/background.png");
     this.load.image("logo", "/assets/images/carnival/BlockGrid/PG_Logo.png");
     this.load.image("boxP", "/assets/images/carnival/Putt/WhiteBoxPutt.png");
     this.load.image("boxE", "/assets/images/carnival/Putt/WhiteBox.png");
     this.load.image("course", "/assets/images/carnival/Putt/Course.png");
     this.load.image("ball", "/assets/images/carnival/Putt/Ball.png");
     this.load.image("power", "/assets/images/carnival/Putt/Power.png");
     this.load.image("newpower", "/assets/images/carnival/Putt/NewPower.png");
     this.load.image("hole", "/assets/images/carnival/Putt/Hole.png");
     this.load.image("blueBox", "/assets/images/carnival/Putt/Blue_Box.png");
     this.load.image("swing", "/assets/images/carnival/Putt/Swing_Left.png");
     this.load.image("swingSpent", "/assets/images/carnival/Putt/Swing_Spent.png");
     
     this.load.image("topWall", "/assets/images/carnival/Putt/Walls/TopWall.png");
     this.load.image("bottomWall", "/assets/images/carnival/Putt/Walls/BottomWall.png");
     this.load.image("middleWall", "/assets/images/carnival/Putt/Walls/MiddleWall.png");
     this.load.image("leftBottomWall", "/assets/images/carnival/Putt/Walls/LeftBottomWall.png");
     this.load.image("leftTopWall", "/assets/images/carnival/Putt/Walls/LeftTopWall.png");
     this.load.image("rightWall", "/assets/images/carnival/Putt/Walls/RightWall.png");

     this.load.image("riverTop", "/assets/images/carnival/Putt/Walls/RiverTop.png");
     this.load.image("riverBottom", "/assets/images/carnival/Putt/Walls/RiverBottom.png");
     this.load.image("riverBottomLeft", "/assets/images/carnival/Putt/Walls/RiverBottomLeft.png");
     this.load.image("riverBottomRight", "/assets/images/carnival/Putt/Walls/RiverBottomRight.png");
     this.load.image("riverTopLeft", "/assets/images/carnival/Putt/Walls/RiverTopLeft.png");
     this.load.image("riverTopRight", "/assets/images/carnival/Putt/Walls/RiverTopRight.png");

     this.load.image("bridgeTop", "/assets/images/carnival/Putt/Walls/BridgeTop.png");
     this.load.image("bridgeBottom", "/assets/images/carnival/Putt/Walls/BridgeBottom.png");

     this.load.image("triangle", "/assets/images/carnival/Putt/Walls/triangleCollider.png");
     this.load.image("triangleUp", "/assets/images/carnival/Putt/Walls/triangleColliderUp.png");

     this.load.image("end", "/assets/images/carnival/Putt/Nice_Putt.png");
     this.load.image("crosshair", "/assets/images/carnival/Environment/Reticle.png");

     this.load.html("dom","/assets/TextForm.html");
     this.load.html("dom2","/assets/TextForm2.html");

     this.load.video('vid', '/assets/video/putt-putt-video.mp4','loadeddata', false, true);
  }

  create() {
    this.StartGame();
  }

  StartGame (){
    this.scene.start('PuttGameScene'); 
  }

  cleanup() {
    // if (this.music.isPlaying) {
    //   this.music.stop();
    // }
  }
  
}
