/* eslint-disable */
import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    InputGroup,
    FormControl,
} from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead'

import FormSelect from "./FormSelect";
import useWindowSize from "../../../utils/useWindowSize";
import { config } from "enviroment.js";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import './GuideSideBar.scss';

const parseJSON = (json) => (json && typeof json === 'string'? JSON.parse(json) : json || {});

const BeforeGame = (props) => {
	const { ESGFormData, setESGFormData, gameId, selectOptions, companies, setCompanies, starttime, users, actualStartTime, setActualStartTime } = props
	const [introStartTime, setIntroStartTime] = useState()
	const [whyLateText, setWhyLateText] = useState()
	const [lastWhyLateText, setLastWhyLateText] = useState()
	const [companySelection, setCompanySelection] = useState([])
	const [TLCheck, setTLCheck] = useState()
	const format = 'YYYY-MM-DD HH:mm:ss';
	const size = useWindowSize();

	useEffect(() => {
		if (ESGFormData?.before?.intro_start) setIntroStartTime(ESGFormData?.before?.intro_start)
		if (ESGFormData?.before?.actual_start) setActualStartTime(ESGFormData?.before?.actual_start)
		if (ESGFormData?.before?.why_late) {
			setWhyLateText(ESGFormData?.before?.why_late)
			setLastWhyLateText(ESGFormData?.before?.why_late)
		}
		if (ESGFormData?.before?.quality_check) {
			const TLCheckValue = parseJSON(ESGFormData?.before?.quality_check).tl_check
			const newTLCheck = (selectOptions.ESG_QUALITY_CHECK || []).filter(qualityCheck => {
				return qualityCheck.value === TLCheckValue
			})
			setTLCheck(newTLCheck)
		}
		setCompanySelection(getSelectedCompany(ESGFormData?.before?.company))
	}, [ESGFormData])

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (lastWhyLateText !== whyLateText) {
				updateWhyLate(whyLateText)
				setLastWhyLateText(whyLateText)
			}
		}, 1000);
    return () => clearTimeout(timeOutId);
  }, [whyLateText]);

	const updateBeforeForm = (updatedESGFormData) => {
		axios.post(config.raUrl + `/esg/${gameId}/updateBeforeForm`, updatedESGFormData.before)
			.then((res) => {
				setESGFormData(updatedESGFormData)
			}).catch((err) => {
					console.log("error caught: ", err)
			})
	}

	const updateVideoSoftware = (e) => {
		const newVideoSoftware = e.currentTarget.innerText
		if (newVideoSoftware !== ESGFormData?.esg?.before?.video_software) {
			let updatedESGFormData = {...ESGFormData}
			if (!updatedESGFormData.before) updatedESGFormData.before = {}
			updatedESGFormData.before.video_software = e.currentTarget.innerText
			updateBeforeForm(updatedESGFormData)
		}
	}

	const updateRenamedHost = (e) => {
		let updatedESGFormData = {...ESGFormData}
		if (!updatedESGFormData.before) updatedESGFormData.before = {}
		updatedESGFormData.before.quality_check = updateQualityCheck('renamed_host', e.currentTarget.checked)
		updateBeforeForm(updatedESGFormData)
	}

	const updateCopiedDashInfoToChat = (e) => {
		let updatedESGFormData = {...ESGFormData}
		if (!updatedESGFormData.before) updatedESGFormData.before = {}
		updatedESGFormData.before.quality_check = updateQualityCheck('copied_dash_info_to_chat', e.currentTarget.checked)
		updateBeforeForm(updatedESGFormData)
	}

	const updateQualityCheck = (key, value) => {
		let qualityCheckParsed = parseJSON(ESGFormData?.before?.quality_check || '{}')
		qualityCheckParsed[key] = value
		return qualityCheckParsed
	}

	const updateTeamType = (e) => {
		let types = []
		if (e) types = e.map(a => a.value)
		let updatedESGFormData = {...ESGFormData}
		if (!updatedESGFormData.before) updatedESGFormData.before = {}
		updatedESGFormData.before.team_type = JSON.stringify(types)
		updateBeforeForm(updatedESGFormData)
	}

	const handleCompanyChange = async (e) => {
		setCompanySelection(e) // necessary to allow input
		if (e.length > 0) {
			let companyId = e[0].id
			if (e[0].customOption) {
				// check if trimmed, case insensitive company exists
				// if yes, set company id to existing company id
				// if not, create new company and update companies state with new company
				const matchedCompany = (companies || []).filter(company => {
					return company.label.toLowerCase().trim() === e[0].label.toLowerCase().trim()
				})
				if (matchedCompany.length > 0) {
					companyId = matchedCompany[0].id
				} else {
					const res = await axios.post(config.raUrl + `/companies`, {companyName: e[0].label})
					companyId = res.data.id
					setCompanies(prev => [...prev, {id: res.data.id, label: e[0].label} ])
				}
			}
			// save selected company id to database
			let updatedESGFormData = {...ESGFormData}
			if (!updatedESGFormData.before) updatedESGFormData.before = {}
			updatedESGFormData.before.company = companyId
			updateBeforeForm(updatedESGFormData)
		}
	}

	const updateHostingFrom = (e) => {
		let updatedESGFormData = {...ESGFormData}
		if (!updatedESGFormData.before) updatedESGFormData.before = {}
		updatedESGFormData.before.hosting_from = e.value
		updateBeforeForm(updatedESGFormData)
	}

	const updateIntroStartState = (value) => {
		if (value) {
			setIntroStartTime(value.format(format))
		} else {
			setIntroStartTime(null)
		}
	}

	const updateIntroStart = () => {
		if (introStartTime && ESGFormData?.before?.intro_start !== introStartTime) {
			let updatedESGFormData = {...ESGFormData}
			if (!updatedESGFormData.before) updatedESGFormData.before = {}
			updatedESGFormData.before.intro_start = introStartTime
			updateBeforeForm(updatedESGFormData)
		}
	}

	const updateActualStartState = (value) => {
		if (value) {
			setActualStartTime(value.format(format))
		} else {
			setActualStartTime(null)
		}
	}

	const updateActualStart = () => {
		if (actualStartTime && ESGFormData?.before?.actual_start !== actualStartTime) {
			let updatedESGFormData = {...ESGFormData}
			if (!updatedESGFormData.before) updatedESGFormData.before = {}
			updatedESGFormData.before.actual_start = actualStartTime
			updateBeforeForm(updatedESGFormData)
		}
	}

	const updateWhoWasLate = (e) => {
		let updatedESGFormData = {...ESGFormData}
		if (!updatedESGFormData.before) updatedESGFormData.before = {}
		updatedESGFormData.before.who_was_late = e.value
		updateBeforeForm(updatedESGFormData)
	}

	const updateWhyLate = (whyLateText) => {
		let updatedESGFormData = {...ESGFormData}
		if (!updatedESGFormData.before) updatedESGFormData.before = {}
		updatedESGFormData.before.why_late = whyLateText
		updateBeforeForm(updatedESGFormData)
	}

	const updateEmpRoomSetupIds = (e) => {
		let empRoomSetupIds = []
		if (e) empRoomSetupIds = e.map(a => a.value)
		let updatedESGFormData = {...ESGFormData}
		if (!updatedESGFormData.before) updatedESGFormData.before = {}
		updatedESGFormData.before.emp_room_setup_id = JSON.stringify(empRoomSetupIds)
		updateBeforeForm(updatedESGFormData)
	}

	const updateTLCheck = (e) => {
		let checks = []
		if (e) checks = e.map(a => a.value)
		let updatedESGFormData = {...ESGFormData}
		if (!updatedESGFormData.before) updatedESGFormData.before = {}
		updatedESGFormData.before.quality_check = updateQualityCheck('tl_check', checks)
		updateBeforeForm(updatedESGFormData)
	}
	
	const getQualityCheckValue = (key) => {
		let qualityCheck = parseJSON(ESGFormData?.before?.quality_check || '{}')
		return qualityCheck[key] || null
	}

	const getSelectedTeamtypes = (teamTypeState) => {
		let parsedTeamTypeState = parseJSON(teamTypeState)
		return (selectOptions.ESG_VIRTUAL_TEAM_TYPE || []).filter(teamType => parsedTeamTypeState.includes(teamType.value))
	}

	const getSelectedCompany = (companyFromState) => {
		const selectedCompany = (companies || []).filter(company => {
			return company.id === companyFromState
		})
		return selectedCompany
	}

	const getSelectedHostingFrom = (hostingFromState) => {
		return (selectOptions.ESG_HOSTING_FROM || []).filter(hostingFrom => {
			return hostingFrom.value === hostingFromState
		})
	}

	const getSelectedWhoWasLate = (whoWasLateState) => {
		return (selectOptions.ESG_WHO_WAS_LATE || []).filter(whoWasLate => {
			return whoWasLate.value === whoWasLateState
		})
	}

	const getSelectedEmpRoomSetupIds = (empRoomSetupIdState) => {
		let parsedEmpRoomSetupIdState = parseJSON(empRoomSetupIdState)
		return (users || []).filter(user => parsedEmpRoomSetupIdState.includes(user.value))
	}


	const getSelectedTLChecks = (TLChecksState) => {
		let parsedTLChecksState = parseJSON(TLChecksState)
		return (selectOptions.ESG_QUALITY_CHECK || []).filter(TLCheck => parsedTLChecksState.includes(TLCheck.value))
	}

	const didStartLate = () => {
		const actualStart = ESGFormData?.before?.actual_start
		const scheduledStart = starttime
		if (actualStart && scheduledStart) {
			const actualStartMinutes = moment.duration(moment.utc(actualStart).format('HH:mm')).asMinutes()
			const scheduledStartMinutes = moment.duration(moment.utc(starttime).format('HH:mm')).asMinutes()
			if (actualStartMinutes > scheduledStartMinutes) return true
		}
		return false
	}
	
  return (
    <form>
        <Container fluid>
            <Row>
                <Col className="meeting-label">
                    What video software is being used?*
                </Col>
            </Row> 
            <Row className={size.width > 576 ? "row-mar" : ""}>
                <Col className="meeting-col">
                    <Button className={ESGFormData?.before?.video_software === "Zoom" ? "meeting-btn-selected" : "meeting-btn"} onClick={updateVideoSoftware} variant="light">
                        Zoom
                    </Button> 
                </Col>
                <Col className="meeting-col">
                    <Button className={ESGFormData?.before?.video_software === "Meet" ? "meeting-btn-selected" : "meeting-btn"} onClick={updateVideoSoftware} variant="light">
                        Meet
                    </Button> 
                </Col>
                <Col className="meeting-col">
                    <Button className={ESGFormData?.before?.video_software === "Teams" ? "meeting-btn-selected" : "meeting-btn"} onClick={updateVideoSoftware} variant="light">
                        Teams
                    </Button> 
                </Col>
                {size.width > 576 && (
                    <>
                        <Col className="meeting-col">
                            <Button className={ESGFormData?.before?.video_software === "Chime" ? "meeting-btn-selected" : "meeting-btn"} onClick={updateVideoSoftware} variant="light">
                                Chime
                            </Button> 
                        </Col>
                        <Col className="meeting-col">
                            <Button className={ESGFormData?.before?.video_software === "Other" ? "meeting-btn-selected" : "meeting-btn"} onClick={updateVideoSoftware} variant="light">
                                Other
                            </Button> 
                        </Col>
                    </>
                )}
            </Row> 

            {size.width <= 576 && (
                <Row className="row-mar">
                    <Col xs={4} sm={4} md={0} lg={0} xl={0} className="meeting-col">
                        <Button className={ESGFormData?.before?.video_software === "Chime" ? "meeting-btn-selected" : "meeting-btn"} onClick={updateVideoSoftware} variant="light">
                            Chime
                        </Button> 
                    </Col>
                    <Col xs={4} sm={4} md={0} lg={0} xl={0} className="meeting-col">
                        <Button className={ESGFormData?.before?.video_software === "Other" ? "meeting-btn-selected" : "meeting-btn"} onClick={updateVideoSoftware} variant="light">
                            Other
                        </Button> 
                    </Col>
                </Row> 
            )} 

            <Row className="checkbox-row">
                <Col className="meeting-col">
                    <InputGroup >
                        <Form.Group>
                            <Form.Check
                                type="checkbox"
                                label='I have renamed myself "Host - First Name" the video software '
                                name="renameCheck"
                                checked={getQualityCheckValue('renamed_host') ? true : false}
                                onChange={updateRenamedHost}
                                variant="light"
                            />
                        </Form.Group>
                    </InputGroup>
                </Col>  
            </Row>

            <Row className="checkbox-row">
                <Col className="meeting-col">
                    <InputGroup >
                        <Form.Group>
                            <Form.Check
                                type="checkbox"
                                label="I've copied and pasted the player dashboard link & passwrord in the chat"
                                name="renameCheck"
                                checked={getQualityCheckValue('copied_dash_info_to_chat') ? true : false}
                                onChange={updateCopiedDashInfoToChat}
                            />
                        </Form.Group>
                    </InputGroup>
                </Col>  
            </Row>

            <Row className="select-row">
                <Col className="meeting-col">
                    <FormSelect 
                        options={selectOptions.ESG_VIRTUAL_TEAM_TYPE || []}
                        updateStateFunction={updateTeamType}
                        value={getSelectedTeamtypes(ESGFormData?.before?.team_type || '[]')}
                        label="Team Type*"
                        isMulti={true}
                    />
                </Col>  
            </Row>


						{/* if team type includes Corporate ('3') display Company input field */}
						{ESGFormData?.before?.team_type && ESGFormData?.before?.team_type.includes('3') &&
							<Row className="select-row">
								<Col className="meeting-col">
								<div className="select-label">Company?*</div>
								<InputGroup>
									<Typeahead
										allowNew
										id="basic-typeahead-single"
										onChange={handleCompanyChange}
										options={companies}
										placeholder="Select a company..."
										newSelectionPrefix="Add New Company: "
										selected={companySelection}
									/>
								</InputGroup>
								</Col>  
							</Row>
						}

            <Row className="select-row">
                <Col className="meeting-col">
                    <FormSelect 
                        options={selectOptions.ESG_HOSTING_FROM || []}
                        updateStateFunction={updateHostingFrom}
                        value={getSelectedHostingFrom(ESGFormData?.before?.hosting_from || null)}
                        label="Hosting From?*"
                        isMulti={false}
                    />
                </Col>
            </Row>

            <Row className="select-row">
                <Col xs={12} sm={12} md={12} lg={6} xl={6} className={size.width > 576 ? "col-left" : "meeting-col"}>
                    <div className="select-label">Time Intro Started*</div>
                    <TimePicker
                        showSecond={false}
                        onChange={updateIntroStartState}
                        onClose={updateIntroStart}
                        use12Hours
												inputReadOnly
												value={introStartTime ? moment(introStartTime) : null}
                    />
                </Col>  

                <Col xs={12} sm={12} md={12} lg={6} xl={6} className={size.width > 576 ? "col-right" : "meeting-col"}>
                    <div className="select-label">Time Game Started*</div>
                    <TimePicker
                        showSecond={false}
                        onChange={updateActualStartState}
                        onClose={updateActualStart}
                        use12Hours
												inputReadOnly
												value={actualStartTime ? moment(actualStartTime) : null}
                    />        
                </Col>
            </Row>
						
						{didStartLate() &&
							<Row className="select-row">
									<Col className="meeting-col">
											<FormSelect 
													options={selectOptions.ESG_WHO_WAS_LATE || []}
													updateStateFunction={updateWhoWasLate}
													value={getSelectedWhoWasLate(ESGFormData?.before?.who_was_late || null)}
													label="Who was late?*"
													isMulti={false}
											/>
									</Col>  
							</Row>
						}

            {didStartLate() && ESGFormData?.before?.who_was_late === "1" && (
							<Row className="select-row">
								<Col className="meeting-col">
								<div className="select-label">Why was TEG late?*</div>
								<InputGroup>
									<FormControl required as="textarea" aria-label="With textarea"
										onChange={e => setWhyLateText(e.target.value)}
										value={whyLateText}
									/>
								</InputGroup>
								</Col>  
							</Row>
            )}

						<Row className="select-row">
							<Col className="meeting-col">
								<FormSelect 
									options={users || []}
									updateStateFunction={updateEmpRoomSetupIds}
									value={getSelectedEmpRoomSetupIds(ESGFormData?.before?.emp_room_setup_id || '[]')}
									label="Who setup the room?*"
									isMulti={true}
								/>
							</Col>  
						</Row>

						<Row className="select-row">
							<Col className="meeting-col">
								<FormSelect 
									options={selectOptions.ESG_QUALITY_CHECK || []}
									updateStateFunction={updateTLCheck}
									value={getSelectedTLChecks(ESGFormData?.before?.quality_check?.tl_check || '[]')}
									label="TL Check?*"
									isMulti={true}
								/>
							</Col>  
						</Row>

        </Container>
    </form>
  );
}

export default BeforeGame
