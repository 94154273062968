import React, { useState, useEffect } from "react";
import { Row, Col } from 'react-bootstrap';

// Styles
import "./PlayerMediaModule.scss";
import Button from "react-bootstrap/Button";

const getLastMediaSrc = (gameMedia, mediaList, mediaType) => {
  const orderedMedia = gameMedia[mediaType].filter((media) =>
    mediaList.includes(media.id)
  );
  console.log(orderedMedia);
  const lastMediaId = orderedMedia.length ? orderedMedia[mediaList.length - 1].id : null;
  const lastMedia = gameMedia[mediaType].filter(
    (media) => media.id === lastMediaId
  )[0];

  if (lastMedia) return lastMedia.data.src;
};

const PlayerMediaModule = ({ gameMedia, scans, theme, videos }) => {
  const [iFrameSrc, setiFrameSrc] = useState("");

  console.log('gameMedia PlayerMediaModule', gameMedia);
  console.log('iFrameSrc', iFrameSrc);
  useEffect(() => {
    if (!gameMedia.videos.length && !gameMedia.scans.length) return

    if (scans.length) {
      // Show last scan first if we have any
      const lastScanSrc = getLastMediaSrc(gameMedia, scans, "scans");

      if (lastScanSrc) setiFrameSrc(lastScanSrc);
    } else if (videos.length) {
        // Fallback to video
        const lastVideoSrc = getLastMediaSrc(gameMedia, videos, "videos");

        if (lastVideoSrc) setiFrameSrc(lastVideoSrc);
    }
  }, [scans, videos, gameMedia, theme]);

  return (
    <div className={theme}>
      <div className="player-iframe-container">
        <iframe
          className="player-iframe"
          src={iFrameSrc}
          scrolling="no"
          frameBorder="0"
          allow="fullscreen"
          title="Video"
        ></iframe>
      </div>
      <div className="player-media-button-container">
        <Row>
        <Col md={6}>
        {gameMedia.videos &&
          gameMedia.videos.map(
            (video) =>
              videos.includes(video.id) && (
                <Button
                  className="player-video-button"
                  key={video.id}
                  onClick={() => setiFrameSrc(typeof video === 'object' ? video.data.src:  video)}
                >
                  {video.data.name.toUpperCase()}
                </Button>
              )
          )}
          </Col>
          <Col md={6}>
        {gameMedia.scans &&
          gameMedia.scans.map(
            (scan) =>
              scans.includes(scan.id) && (
                <Button
                  key={scan.id}
                  className="player-scan-button"
                  onClick={() => setiFrameSrc(scan.data.src)}
                >
                  {scan.data.name.toUpperCase()}
                </Button>
              )
          )}
          </Col>
          </Row>
      </div>
    </div>
  );
};

export default PlayerMediaModule;
