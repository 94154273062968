import Phaser from "phaser";

export default class Preload extends Phaser.Scene {
    constructor() {
        super({ key: 'Preload', active: true});
        console.log("preload constructor");
    }

    init() {

    }

    preload() {
        console.log("preload preload");

         //Loading Environment Assets
        //this.load.image("bg", "assets/Environment/Background.png");
        //this.load.image("hill", "assets/Environment/Hill.png");
        //this.load.image("scoreBoard", "assets/Environment/Scoreboard.png");
        this.load.image("crosshair", "/assets/images/carnival/Environment/Reticle.png");
/*
        //Start Scene Assets
        this.load.video('timer', 'assets/timer.mp4', 'loadeddata', false, true);
        this.load.image("hit_targets", "assets/Hit_The_Targets.png");

        //End Scene Assets
        this.load.image("ending_scoreBoard", "assets/Environment/EndScene/Scoreboard.png");
        this.load.image("ending_bg", "assets/Environment/EndScene/Background.png");
        
        //Loading Animal Assets

        //Deer
        this.load.image("deer_right_front", "assets/Animals/Deer_Front_Right.png");
        this.load.image("deer_right_back", "assets/Animals/Deer_Back_Left.png");
        this.load.image("deer_left_front", "assets/Animals/Deer_Front_Left.png");
        this.load.image("deer_left_back", "assets/Animals/Deer_Back_Right.png");
        this.load.image("deer_left_back_numbered", "assets/Animals/Deer_Back_R_0.png");
        this.load.image("deer_right_back_numbered", "assets/Animals/Deer_Back_L_0.png");
        

        //Ram
        this.load.image("ram_right_front", "assets/Animals/Ram_Front_Right.png");
        this.load.image("ram_right_back", "assets/Animals/Ram_Back_Left.png");
        this.load.image("ram_left_front", "assets/Animals/Ram_Front_Left.png");
        this.load.image("ram_left_back", "assets/Animals/Ram_Back_RIght.png");
        this.load.image("ram_left_back_numbered", "assets/Animals/Ram_Back_R_3.png");
        this.load.image("ram_right_back_numbered", "assets/Animals/Ram_Back_L_3.png");

        //Turkey
        this.load.image("turkey_right_front", "assets/Animals/Turkey_Front_Right.png");
        this.load.image("turkey_right_back", "assets/Animals/Turkey_Back_Left.png");
        this.load.image("turkey_left_front", "assets/Animals/Turkey_Front_Left.png");
        this.load.image("turkey_left_back", "assets/Animals/Turkey_Back_Right.png");
        this.load.image("turkey_right_back_numbered", "assets/Animals/Turkey_Back_L_4.png");
        this.load.image("turkey_left_back_numbered", "assets/Animals/Turkey_Back_R_4.png");

        //Moose
        this.load.image("moose_right_front", "assets/Animals/Moose_Front_Right.png");
        this.load.image("moose_right_back", "assets/Animals/Moose_Back_Left.png");
        this.load.image("moose_left_front", "assets/Animals/Moose_Front_Left.png");
        this.load.image("moose_left_back", "assets/Animals/Moose_Back_Right.png");
        this.load.image("moose_left_back_numbered", "assets/Animals/Moose_Back_L_7.png");
        this.load.image("moose_left_back_numbered", "assets/Animals/Moose_Back_R_7.png");

        this.load.image("cat", "assets/cat.png");
        this.load.image("dog", "assets/dog.png");
        this.load.image("fish", "assets/fish.png");
        this.load.image("lion", "assets/lion.png");
        this.load.image("flip", "assets/white.png"); */


        //BlockGrid
        this.load.image("bg", "assets/BlockGrid/background.png");
        this.load.image("grid", "assets/BlockGrid/Grid.png");
        this.load.image("logo", "assets/BlockGrid/PG_Logo.png");
        this.load.image("box", "assets/BlockGrid/White_Box.png");
        this.load.image("block", "assets/BlockGrid/Block.png");

        //BallSort
        this.load.image("ballsort", "assets/BallSort/Ball_Sort.png");
        this.load.image("release", "assets/BallSort/Release.png");
        this.load.image("tube", "assets/BallSort/Tube.png");
        this.load.image("tubeH", "assets/BallSort/Tube_Highlight.png");
        this.load.image("boxB", "assets/BallSort/WhiteBox.png");
        this.load.image("boxB2", "assets/BallSort/WhiteBox2.png");

        this.load.image("blueball", "assets/BallSort/Blue_Ball.png");
        this.load.image("greenball", "assets/BallSort/Green_Ball.png");
        this.load.image("redball", "assets/BallSort/Red_Ball.png");
        this.load.image("yellowball", "assets/BallSort/Yellow_Ball.png");
        this.load.image("orangeball", "assets/BallSort/Orange_Ball.png");
        this.load.image("pinkball", "assets/BallSort/Pink_Ball.png");

        //Putt
        this.load.image("boxP", "assets/Putt/WhiteBoxPutt.png");
        this.load.image("boxE", "assets/Putt/WhiteBox.png");
        this.load.image("course", "assets/Putt/Course.png");
        this.load.image("ball", "assets/Putt/Ball.png");
        this.load.image("power", "assets/Putt/Power.png");
        this.load.image("newpower", "assets/Putt/NewPower.png");
        this.load.image("hole", "assets/Putt/Hole.png");
        this.load.image("blueBox", "assets/Putt/Blue_Box.png");
        this.load.image("swing", "assets/Putt/Swing_Left.png");
        this.load.image("swingSpent", "assets/Putt/Swing_Spent.png");
        
        this.load.image("topWall", "assets/Putt/Walls/TopWall.png");
        this.load.image("bottomWall", "assets/Putt/Walls/BottomWall.png");
        this.load.image("middleWall", "assets/Putt/Walls/MiddleWall.png");
        this.load.image("leftBottomWall", "assets/Putt/Walls/LeftBottomWall.png");
        this.load.image("leftTopWall", "assets/Putt/Walls/LeftTopWall.png");
        this.load.image("rightWall", "assets/Putt/Walls/RightWall.png");

        this.load.image("riverTop", "assets/Putt/Walls/RiverTop.png");
        this.load.image("riverBottom", "assets/Putt/Walls/RiverBottom.png");
        this.load.image("riverBottomLeft", "assets/Putt/Walls/RiverBottomLeft.png");
        this.load.image("riverBottomRight", "assets/Putt/Walls/RiverBottomRight.png");
        this.load.image("riverTopLeft", "assets/Putt/Walls/RiverTopLeft.png");
        this.load.image("riverTopRight", "assets/Putt/Walls/RiverTopRight.png");

        this.load.image("bridgeTop", "assets/Putt/Walls/BridgeTop.png");
        this.load.image("bridgeBottom", "assets/Putt/Walls/BridgeBottom.png");

        this.load.image("triangle", "assets/Putt/Walls/triangleCollider.png");
        this.load.image("triangleUp", "assets/Putt/Walls/triangleColliderUp.png");

        this.load.image("end", "assets/Putt/Nice_Putt.png");

       }

    create() {

        this.scene.start('Putt');
    }



    StartGame ()
    {
        this.scene.start('GameScene'); 
    }
    
}