import {ajax} from "rxjs/ajax";
import {config} from "../../enviroment";
import {getCookie} from '../../common/utils/auth';
const header = { 'Content-Type': 'application/json' };
const getAuth = () => {
  return {
    ...header,
    'Authorization': `Bearer ${getCookie('token')}`
  }
}
const env = process.env.NODE_ENV || 'development'
export const updateProfile = (payload) => {
  return ajax.put(`${config.raUrl}/users/profile`, payload, getAuth());
}
export const linkXolaAccount = (payload) => {
  payload.env = env
  return ajax.put(`${config.raUrl}/users/xola/link`, payload, getAuth());
}
export const unlinkXolaAccount = (payload) => {
  return ajax.put(`${config.raUrl}/users/xola/unlink`, payload, getAuth());
}
export const getXolaAccount = () => {
  return ajax.getJSON(`${config.raUrl}/users/xola?env=${env}`, getAuth());
}