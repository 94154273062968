import Phaser from "phaser";
import * as PuttPuttSocket from "../puttPutSocket";

export default class GolfBall extends Phaser.GameObjects.Sprite {
    constructor(scene, x, y, sprite,power) {
      super(scene, x, y, sprite);
      this.scene = scene; 
      this.sprite = sprite;
      this.forceX=0;
      this.forceY=0;
      this.isEnabled=false;

      //this.power=scene.add.image(this.x,this.y,power).setOrigin(0.5,0);
      //this.power.setTint(0x00FF00);
      this.powerContainer=scene.add.container(0,0);
      this.power=scene.add.image(this.x,this.y,power).setOrigin(0.5,0);
      //this.powerArr=[this.power];
     // this.powerContainer.add(this.powerArr);

      this.power.alpha=0.001;

      scene.physics.world.enable([ this ]);
      this.body.useDamping=true;
      this.body.setDrag(0.5);
      //this.body.setVelocity(-100, 200).setBounce(1, 1).setCollideWorldBounds(true);

      scene.add.existing(this);
  
/*       scene.input.on("pointermove", function(pointer,this) {
        gameObject.forceX=gameObject.x-pointer.x;
        gameObject.forceY=gameObject.y-pointer.y;

      });

      scene.input.on('pointerdown',()=>{
        console.log(this.forceX,this.forceY);
      }); */

    }

    updateForces(forceX,forceY)
    {
        if(this.isEnabled)
        {
            this.power.alpha=1;
            let dist=this.distanceFromMouse(forceX,forceY);
            let rotation=0;
            if(dist<=300)
            {
                this.power.setTint(0x00FF00);
            }
            else if(dist>300&&dist<500)
            {
                this.power.setTint(0xFFFF00);
            }
            else if(dist>500)
            {
                this.power.setTint(0xFF0000);
            }
            
            if(forceX>0)
            {
                rotation=Math.asin(forceY/dist);
            }
            else{
                rotation=1.57+(1.57-Math.asin(forceY/dist));
            }
          
    
    
            //console.log(forceY);
            this.forceX=forceX;
            this.forceY=forceY;

            //Limiting max power
            if(Math.abs(this.forceX)>1000&&Math.abs(this.forceY)>1000)
            {
                let maxVal=(Math.abs(this.forceX)>Math.abs(this.forceY)) ? this.forceX:this.forceY;
                this.forceX=(this.forceX/maxVal)*1000;
                this.forceY=(this.forceY/maxVal)*1000;
            }
            else if(Math.abs(this.forceX)>=1000)
            {
                this.forceX=(this.forceX>0)?1000:-1000;
            }
            else if(Math.abs(this.forceY)>1000)
            {
                this.forceY=(this.forceY>0)?1000:-1000;
            }  
            this.power.setScale(1,dist/500);
            this.power.rotation=rotation-Math.PI/2;
 
    
           //console.log(this.forceX,this.forceY);
        }
       
    }
    distanceFromMouse(x,y)
    {
        return Math.sqrt(Math.pow(x,2)+Math.pow(y,2));
    }
    shootBall()
    {
        if(this.isEnabled)
        {
            console.log(this.forceX,this.forceY);
            this.power.alpha = 0.001;
            this.body.setVelocity(this.forceX, this.forceY).setBounce(0.9, 0.9).setCollideWorldBounds(true);
            PuttPuttSocket.registerAShot(this.forceX, this.forceY)
        }
    }

    shootBallBySomeoneElse(forceX,forceY)
    {
        console.log("simulating the shot:", forceX,forceY)
        this.power.alpha = 0.001;
        this.body.setVelocity(forceX,forceY).setBounce(0.9, 0.9).setCollideWorldBounds(true);
    }

    stopBall()
    {
        console.log(this.forceX,this.forceY);
        this.body.setVelocity(0,0).setBounce(0.9, 0.9).setCollideWorldBounds(true);
    }

    win()
    {
        this.scene.isRunning=false;
        this.scene.scene.start('End', {
            winner: "You"
        });
        this.destroy();
    }


  }
  