/* eslint-disable */
import React, { useEffect, useState } from "react";
// import io from "socket.io-client";
import { useLocation } from "react-router-dom";
import { INITIAL_STATE, TEG_APP } from "../../lib/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  fetchGameAndSetGameMedia,
  verifyHost,
  getGameData,
  setCookie,
  getCookie,
  eraseCookie,
} from "../../lib/data";

import GameScene from "../../phaser/gamescene";
import CarnivalGame from "../../phaser/index";
import * as CarnivalSocket from "../../websockets/carnival";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CarnivalTest = (props) => {
  const gameId = props.match.params.game;
  const query = useQuery();
  const [isLoading, setLoading] = useState(true);
  const [isUserAuthorized, setUserAuthorized] = useState(true);
  const [isGameActive, setIsGameActive] = useState(null);

  const validateHostData = async (eventId, pass) => {
    const { isValid, token } = await verifyHost(eventId, pass);
    if (isValid) {
      setCookie("host_token", token);
      console.log("HERE");

      CarnivalSocket.connectSocket();
      CarnivalSocket.joinCarnivalGame(gameId);
      
      new CarnivalGame();
      setLoading(false);
    } else {
      console.log("HERE ELSE");
      setLoading(false);
      setUserAuthorized(false);
    }
    return isValid;
  };

  useEffect(() => {
    setLoading(false);
    if (query.get("auth") && query.get("auth") !== "") {
      validateHostData(gameId, query.get("auth"));

    } else {
      setLoading(false);
      setUserAuthorized(false);
    }

    return () => {
      socket.emit("disconnect");
      socket.off();
    };
  }, [TEG_APP.ENDPOINT, gameId]);

  if (isLoading) {
    return (
      <div className="loading">
        <div className="lds-dual-ring"></div>
      </div>
    );
  }

  //   if (!isUserAuthorized) {
  //     return (
  //       <Container fluid>
  //         <Row>
  //           <Col>
  //             <p>Logo</p>
  //           </Col>
  //         </Row>
  //         <Row>You are not authorized to view this page.</Row>
  //       </Container>
  //     );
  //   }

  const togglePostGame = (evt) => {
    socket.emit("toggle-post-game", isGameActive, () => {
      setIsGameActive(!isGameActive);
    });
  };

  if (isLoading) {
    return (
      <div className="loading">
        <div className="lds-dual-ring"></div>
      </div>
    );
  }

  return (
    <div id="carnival_game"></div>
  );
};

export default CarnivalTest;
