
export const config = {
  baseUrl:
    process.env.REACT_APP_ENV === "production"
      ? "https://ra-auth-api.theescapegame.com/api/v1"
      : process.env.REACT_APP_ENV === "test"
      ? "https://ra-auth-api.theescapegame.com/api/v1"
      : process.env.REACT_APP_ENV === "local"
      ? "http://localhost:8000/api/v1"
      : "http://ec2-54-243-189-62.compute-1.amazonaws.com:8000/api/v1",
  raUrl:
    process.env.REACT_APP_ENV === "production"
      ? "https://ra-atc-api.theescapegame.com/api"
      : process.env.REACT_APP_ENV === "test"
      ? "https://d671unct8szzv.cloudfront.net/api"
      : process.env.REACT_APP_ENV === "local"
      ? "http://localhost:5000/api"
      : "http://52.15.252.54:5000/api",
  clientUrl:
    process.env.REACT_APP_ENV === "production"
      ? "https://remoteadventures.theescapegame.com"
      : process.env.REACT_APP_ENV === "test"
      ? "https://remoteadventures-stag.theescapegame.com"
      : process.env.REACT_APP_ENV === "local"
      ? "http://localhost:3000"
      : "https://remoteadventures.theescapegame.com",
};
