const INITIAL_STATE = {
  GAME_MEDIA: {
    images: [],
    baseInventory: [],
    lockInventory: [],
    scans: [],
    videos: [],
    sounds: [],
    playerPassword: '',
    foundedToys: [],
  }
};

const TEG_APP = {
  ENDPOINT: "http://localhost:5000",
  LAUNCH_DARKLY_CLIENT_SIDE_ID: "61421d8da71e7626fa8d5095"
}
if (process.env.REACT_APP_ENV === 'production') {
  TEG_APP.ENDPOINT = "https://ra-atc-api.theescapegame.com"
  TEG_APP.LAUNCH_DARKLY_CLIENT_SIDE_ID = "61421d8da71e7626fa8d5096"
}
else if (process.env.REACT_APP_ENV === 'test') {
  TEG_APP.ENDPOINT = "https://d671unct8szzv.cloudfront.net"
}

export {
  INITIAL_STATE,
  TEG_APP,
}
