import React, {useState} from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import { loginUser } from '../../redux/actionCreators';
import './Login.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCalendar, faSpinner} from '@fortawesome/free-solid-svg-icons'



const Login = (props) => {
  const userState = useSelector(state => state.userReducer);
  const dispatch = useDispatch();
  const [loginForm, setLoginForm] = useState({
    email: '',
    password: ''
  });

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser({...loginForm, onComplete: ()=> {
        props.history.push('/atc');
      }}));
  }

  const onChange = (e) => {
    const values = {...loginForm};
    values[e.target.name] = e.target.value;
    setLoginForm(values);
  }

  return (<div className='signin-section'>
    <Card className='signin-card'>
      <div className='heading'>Remote Adventures</div>
      <Form onSubmit={onSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control required name='email'  onChange={(e)=> onChange(e)} type="email" placeholder="Enter email" />
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control required name='password'  onChange={(e)=> onChange(e)} type="password" placeholder="Password" />
        </Form.Group>
        <Button variant="secondary" type="submit">
          Login
          {userState.isLoading &&  <FontAwesomeIcon size='1x' spin={true} icon={faSpinner} />}
        </Button>
        <p className='text-danger text-center message'>{userState.error}</p>
      </Form>
    </Card>
  </div>)
}
export default Login;
