import {ajax} from "rxjs/ajax";
import {config} from "../../enviroment";
import {getCookie} from '../../common/utils/auth';
const header = { 'Content-Type': 'application/json' };
const getAuth = () => {
  return {
    ...header,
    'Authorization': `Bearer ${getCookie('token')}`
  }
}
export const updateCustomer = (id, payload) => {
  return ajax.put(`${config.raUrl}/customers/${id}`, payload, getAuth());
}

export const getCustomer = (id) => {
  return ajax.getJSON(`${config.raUrl}/customers/event/${id}`, getAuth());
}
