

const tabStylesRugrats = (gameMedia, windowWidth) => {
	let inventoryTabLeft, locksTabLeft, viewsTabLeft, foundedTabLeft,
			inventoryTabWidthStr, locksTabWidthStr, viewsTabWidthStr, foundedTabWidthStr
	const inventoryTabWidth = 160;
	const locksTabWidth = 135;
	const viewsTabWidth = 135;
	const foundedTabWidth = 175;
	const tabOverlapPx = 10;
	const tabOverlapPct = 2;
	const hasInventoryTab = gameMedia.baseInventory.length > 0 ? true : false
	const hasLocksTab = gameMedia.lockInventory.length > 0 ? true : false
	const hasViewsTab = (gameMedia.scans.length + gameMedia.videos.length) > 0 ? true : false
	const hasFoundedTab = true
	const tabCount = [hasInventoryTab, hasLocksTab, hasViewsTab, hasFoundedTab].filter(Boolean).length
	if (windowWidth > 610) {
		inventoryTabLeft = 0
		locksTabLeft = (
			(hasInventoryTab ? (inventoryTabWidth - tabOverlapPx) : 0) +
			"px"
		)
		viewsTabLeft = (
			(hasInventoryTab ? (inventoryTabWidth - tabOverlapPx) : 0) +
			(hasLocksTab ? (locksTabWidth - tabOverlapPx) : 0) +
			"px"
		)
		foundedTabLeft = (
			(hasInventoryTab ? (inventoryTabWidth - tabOverlapPx) : 0) +
			(hasLocksTab ? (locksTabWidth - tabOverlapPx) : 0) +
			(hasViewsTab ? (viewsTabWidth - tabOverlapPx) : 0) +
			"px"
		)
		inventoryTabWidthStr = inventoryTabWidth + "px"
		locksTabWidthStr = locksTabWidth + "px"
		viewsTabWidthStr = viewsTabWidth + "px"
		foundedTabWidthStr = foundedTabWidth + "px"
	} else {
		const tabWidthPct = 100/tabCount
		inventoryTabLeft = 0
		locksTabLeft = (
			(hasInventoryTab ? (tabWidthPct - tabOverlapPct) : 0) +
			"%"
		)
		viewsTabLeft = (
			(hasInventoryTab ? (tabWidthPct - tabOverlapPct) : 0) +
			(hasLocksTab ? (tabWidthPct - tabOverlapPct) : 0) +
			"%"
		)
		foundedTabLeft = (
			(hasInventoryTab ? (tabWidthPct - tabOverlapPct) : 0) +
			(hasLocksTab ? (tabWidthPct - tabOverlapPct) : 0) +
			(hasViewsTab ? (tabWidthPct - tabOverlapPct) : 0) +
			"%"
		)
		inventoryTabWidthStr = tabWidthPct + "%"
		locksTabWidthStr = tabWidthPct + "%"
		viewsTabWidthStr = tabWidthPct + "%"
		foundedTabWidthStr = tabWidthPct + "%"
	}
	return {
		inventory: {
			left: inventoryTabLeft,
			width: inventoryTabWidthStr,
		},
		"locks": {
			left: locksTabLeft,
			width: locksTabWidthStr,
		},
		"views": {
			left: viewsTabLeft,
			width: viewsTabWidthStr,
		},
		"founded": {
			left: foundedTabLeft,
			width: foundedTabWidthStr,
		},
	}
}

export default tabStylesRugrats