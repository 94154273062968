import React from "react";

import backgroundImageGoldRush from "images/gr_background.png";
import nextAssetGoldRush from "images/ruins_next.png";
import prevAssetGoldRush from "images/ruins_next.png";
import RALogo from "images/RA-newlogo.png";
import tabDefault from "Components/Play/Tabs/TabDefault.js"
import tabStylesDefault from "Components/Play/Tabs/TabStylesDefault.js"

// GOLD RUSH
import playerLoginBgGoldRush from "images/gold-rush-player-login-bg.jpg"

// THE HEIST
import playerLoginBgHeist from "images/heist-player-login-bg.jpg"

// RUINS
import playerLoginBgRuins from "images/ruins-player-login-bg.jpg"

// THE DEPTHS
import playerLoginBgDepths from "images/depths-player-login-bg.jpg"

// PRISON BREAK
import playerLoginBgPrisonBreak from "images/prison-break-player-login-bg.jpg"

// RUGRATS
import nextAssetRugrats from "images/rugrats-slider-right.png";
import prevAssetRugrats from "images/rugrats-slider-left.png";
import backgroundImageRugrats from "images/rugrats-bg.png";
import gameLogoRugrats from "images/rugrats-logo.png"
import panelBgBlueRugrats from "images/rugrats-panel-blue.png";
import panelBgGreenRugrats from "images/rugrats-panel-green.png";
import panelBgPurpleRugrats from "images/rugrats-panel-purple.png";
import tabBlueRugrats from "images/rugrats-tab-blue.png";
import tabGreenRugrats from "images/rugrats-tab-green.png";
import tabPurpleRugrats from "images/rugrats-tab-purple.png";
import tabRugrats from "Components/Play/Tabs/TabRugrats.js";
import iconFoundedRugrats from "images/rugrats-icon-founded.png"
import iconInventoryRugrats from "images/rugrats-icon-inventory.png"
import iconLocksRugrats from "images/rugrats-icon-locks.png"
import iconViewsRugrats from "images/rugrats-icon-views.png"
import HeaderRugrats from "Components/Play/Headers/HeaderRugrats.js"
import viewerBorder from "images/rugrats-viewer-border.png"
import panelBorderImageRugrats from "images/rugrats-panel-border.png"
import playerLoginBgRugrats from "images/rugrats-player-login-bg.png"
import playerLoginLogoRugrats from "images/rugrats-player-login-logo.png"
import playerLoginDinoRugrats from "images/rugrats-player-login-dino.png"

// MBS
import HeaderMBS from "Components/Play/Headers/HeaderMBS.js"
import FooterMBS from "Components/Play/Footers/FooterMBS.js"
import tabStylesRugrats from "Components/Play/Tabs/TabStylesRugrats.js"


const themeSettings = {
    "rugrats": {
        backgroundColor: "#062b1d",
        backgroundImage: backgroundImageRugrats,
        gameLogo: gameLogoRugrats,
        header: HeaderRugrats,
        panelBgBlue: panelBgBlueRugrats,
        panelBgGreen: panelBgGreenRugrats,
        panelBgPurple: panelBgPurpleRugrats,
        panelBorderImage: panelBorderImageRugrats,
        viewerColor: "rgb(253, 203, 0)",
        nextButtonImage: nextAssetRugrats,
        prevButtonImage: prevAssetRugrats,
        tab: tabRugrats,
        tabInventoryLabel: "Inventory",
        tabLocksLabel: "Locks",
        tabViewsLabel: "Views",
        tabToysLabel: "Founded Toys",
        tabBlue: tabBlueRugrats,
        tabGreen: tabGreenRugrats,
        tabPurple: tabPurpleRugrats,
        tabStyles: tabStylesRugrats,
        iconFounded: iconFoundedRugrats,
        iconInventory: iconInventoryRugrats,
        iconLocks: iconLocksRugrats,
        iconViews: iconViewsRugrats,
        viewerBorder: viewerBorder,
        playerLoginBg: playerLoginBgRugrats,
        playerLoginDino: playerLoginDinoRugrats,
        playerLoginLogo: playerLoginLogoRugrats,
        zoomBackgrounds: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/rugrats/zoom-backgrounds/search-for-the-losted-toys-backgrounds.zip",
        zoomBackgroundText: "Download Rugrats Virtual Backgrounds",
    },
    "mbs": {
        backgroundColor: "#062b1d",
        backgroundImage: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/bg.jpg",
        backgroundImageGradient: "linear-gradient(transparent, 85%, rgba(0,0,0,.5))",
        gameLogo: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/game-logo.png",
        footer: FooterMBS,
        header: HeaderMBS,
        panelBgBlue: panelBgBlueRugrats,
        panelBgGreen: panelBgGreenRugrats,
        panelBgPurple: panelBgPurpleRugrats,
        panelBorderImage: panelBorderImageRugrats,
        viewerColor: "rgb(253, 203, 0)",
        nextButtonImage: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/right-arrow.png",
        prevButtonImage: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/left-arrow.png",
        tab: tabDefault,
        tabInventoryLabel: "Inventory",
        tabLocksLabel: "Locks",
        tabViewsLabel: "Explore",
        tabBlue: tabBlueRugrats,
        tabGreen: tabGreenRugrats,
        tabPurple: tabPurpleRugrats,
        tabStyles: tabStylesDefault,
        iconInventory: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/icon-inventory.png",
        iconLocks: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/icon-locks.png",
        iconViews: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/icon-views.png",
        viewerBorder: viewerBorder,
        playerLoginBg: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/bg.jpg",
        playerLoginLogo: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/game-logo.png",
        zoomBackgrounds: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/mbs-zoom-backgrounds.zip",
        zoomBackgroundText: "Download Zoom Backgrounds",
    },
    "omitb": {
        backgroundColor: "#062b1d",
        backgroundImage: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/bg.jpg",
        backgroundImageGradient: "linear-gradient(transparent, 85%, rgba(0,0,0,.5))",
        gameLogo: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/game-logo.png",
        footer: FooterMBS,
        header: HeaderMBS,
        panelBgBlue: panelBgBlueRugrats,
        panelBgGreen: panelBgGreenRugrats,
        panelBgPurple: panelBgPurpleRugrats,
        panelBorderImage: panelBorderImageRugrats,
        viewerColor: "rgb(253, 203, 0)",
        nextButtonImage: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/right-arrow.png",
        prevButtonImage: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/left-arrow.png",
        tab: tabDefault,
        tabInventoryLabel: "Inventory",
        tabLocksLabel: "Locks",
        tabViewsLabel: "Explore",
        tabBlue: tabBlueRugrats,
        tabGreen: tabGreenRugrats,
        tabPurple: tabPurpleRugrats,
        tabStyles: tabStylesDefault,
        iconInventory: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/icon-inventory.png",
        iconLocks: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/icon-locks.png",
        iconViews: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/icon-views.png",
        viewerBorder: viewerBorder,
        playerLoginBg: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/bg.jpg",
        playerLoginLogo: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/game-logo.png",
        zoomBackgrounds: "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/mbs-zoom-backgrounds.zip",
        zoomBackgroundText: "Download Zoom Backgrounds",
    },
    "gold-rush": {
        backgroundColor: "#062b1d",
        backgroundImage: backgroundImageGoldRush,
        viewerColor: "rgb(253, 203, 0)",
        nextButtonImage: nextAssetGoldRush,
        prevButtonImage: prevAssetGoldRush,
        playerLoginBg: playerLoginBgGoldRush,
        playerLoginLogo: RALogo,
    },
    "prison-break": {
        playerLoginBg: playerLoginBgPrisonBreak,
        playerLoginLogo: RALogo,
    },
    "depths": {
        playerLoginBg: playerLoginBgDepths,
        playerLoginLogo: RALogo,
    },
    "ruins": {
        playerLoginBg: playerLoginBgRuins,
        playerLoginLogo: RALogo,
    },
    "ruins-forbidden-treasure": {
        playerLoginBg: playerLoginBgRuins,
        playerLoginLogo: RALogo,
    },
    "heist": {
        playerLoginBg: playerLoginBgHeist,
        playerLoginLogo: RALogo,
    },
    "the-heist": {
        playerLoginBg: playerLoginBgHeist,
        playerLoginLogo: RALogo,
    },
    "playground": {
        playerLoginBg: 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/playground/background-image-playground.png',
        playerLoginLogo: RALogo,
    },
    "default": {
        playerLoginBg: playerLoginBgHeist,
        playerLoginLogo: RALogo,
    }
}

const getThemeSettings = (theme) => {
    if (!themeSettings[theme]) return themeSettings["default"]
    return themeSettings[theme]
}

export default getThemeSettings;


