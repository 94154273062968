/* eslint-disable */

import React, {useState, useEffect} from "react";

// Styles
import "./TheHeist.scss";
import {Col, Container, Row} from "react-bootstrap";

// Image Imports
import lockLogo from "../../../../images/teg_lock_logo.png";
import heistLogo from "../../../../images/heist_logo.png";
import surveillanceVan from "../../../../images/heist_surveillance_van.png";

// Component Imports
import PlayerClueModule from "../../../../Components/Play/PlayerClueModule/PlayerClueModule";
import PlayerMediaModule from "../../../../Components/Play/PlayerMediaModule/PlayerMediaModule";
import PlayerInventoryModule from "../../../../Components/Play/PlayerInventoryModule/PlayerInventoryModule";
import PlayerMultiRowInventoryModule from "../../../../Components/Play/PlayerMultiRowInventoryModule/PlayerMultiRowInventoryModule";
import PlayerZoomDownload from "../../../../Components/Play/PlayerZoomDownload/PlayerZoomDownload";

import {getBackground, VdayHearts, CustomGraphic} from "../getBackground";

const TheHeist = (props) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const inventoryType = params.get('inventory');
  useEffect(() => {
    document.body.style.backgroundColor = "#102021";
  }, [])
  return (
    <div className={props.theme}>
      <div className="player-wrapper" style={{backgroundImage: `url(${getBackground(props.theme)})`}}>
        <Container fluid>
          <Row className="player-logo-section">
            <div className='logo-container'>
              <img
                alt="Logo for The Escape Game"
                className="player-logos-1"
                src={lockLogo}
              />
              <img
                alt="Logo for The Escape Game's Ruins escape room"
                className="player-logos-2"
                src={heistLogo}
              />
            </div>
          </Row>
          <Row>
            <Col md="12">
              <CustomGraphic eventId={props.gameId} placement="viewer-top-right" />
              <PlayerMediaModule
                scans={props.scans}
                socket={props.socket}
                videos={props.videos}
                theme={props.theme}
                gameMedia={props.gameMedia}
              />
              <br/>
              <Row>
                {props.gameMedia.lockInventory && props.gameMedia.lockInventory.length ? <Col md='6'>
                  <CustomGraphic eventId={props.gameId} placement="locks-right" />
                  <PlayerInventoryModule
                    currentInventory={props.inventory}
                    inventory={props.gameMedia.lockInventory}
                    socket={props.socket}
                    theme={props.theme}
                    type={'lock'}
                  />
                </Col> : ''
                }
                <Col md={props.gameMedia.lockInventory.length ? 6 : 12}>
                  <div className='zoom-and-van'>
                    <PlayerZoomDownload conferenceApp="ZOOM" theme={props.theme} tracking={props.gameMedia.tracking} />
                    <Row className="text-center">
                      <img
                        alt="Aux image"
                        className="aux-image"
                        src={surveillanceVan}
                      />
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row className="no-show">
                <Col md="7">
                  <PlayerClueModule clue={props.clue} socket={props.socket} game={props.game}/>
                </Col>
                <Col md="5">
                  <Row>
                    <h1>Time</h1>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-5">
            {inventoryType === 'multi-row' ?
              <PlayerMultiRowInventoryModule
                currentInventory={props.inventory}
                inventory={props.gameMedia.baseInventory}
                socket={props.socket}
                theme={props.theme}
                type={'base'}
              />:
              <PlayerInventoryModule
                currentInventory={props.inventory}
                inventory={props.gameMedia.baseInventory}
                socket={props.socket}
                theme={props.theme}
                type={'base'}
              />
            }
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default TheHeist;
