/* eslint-disable */

import React, { useState, useEffect } from "react";

// Packages
import io from "socket.io-client";

// Constants
import { TEG_APP } from "../../lib/constants";

// Components
import PlayerDashboardV1 from 'Pages/PlayerDashboard/PlayerDashboard-v1';
import PlayerDashboardV2 from 'Pages/PlayerDashboard/PlayerDashboard-v2';
import PlayerDashboardV3 from 'Pages/PlayerDashboard/PlayerDashboard-v3';

let socket;

const PlayerDashboard = (props) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const customTheme = params.get('theme');
  const gameId = props.match.params.game;
  const [playState, setPlayState] = useState();
  const [theme, setTheme] = useState("");

  useEffect(() => {
    socket = io(TEG_APP.ENDPOINT);

    socket.emit("join", gameId, () =>
      console.log(
        `Join transmission sent from TEG to server over socket ${socket.id}.`
      )
    );

    socket.on("play-state", ({ playState, theme }) => {
      if(customTheme) {
        setTheme(customTheme);
      } else {
        setTheme(theme);
      }
      setPlayState(playState)
    });

    return () => {
      socket.emit("disconnect");
      socket.off();
    };
  }, [TEG_APP.ENDPOINT, gameId]);

  const ibmEventIds = [
    "60be76e082cc3763692d1805",
    "60be746c82cc37636925e3d4",
    "60be73f082cc376369249a35",
    "60be75bd82cc37636929bc48",
    "60be973d82cc376369887086",
    "60be980982cc3763698adb1e",
    "60be764f82cc3763692b5496",
    "60be74bb82cc37636926ddb9",
    "60be760882cc3763692a9124",
  ]
  if (theme && ibmEventIds.includes(gameId)) {
    return <PlayerDashboardV1 playState={playState} theme={theme} socket={socket} {...props} />
  }

  switch (theme) {
    case '':
      return (
        <div className="loading">
          <div className="lds-dual-ring"></div>
        </div>
      );
    case 'rugrats':
    case 'mbs':
      return <PlayerDashboardV2 playState={playState} theme={theme} socket={socket} {...props}/>;
    case 'heist':
    case 'the-heist':
    case 'prison-break':
    case 'gold-rush':
    case 'depths':
    case 'ruins-forbidden-treasure':
    case 'playground':
    case 'omitb':
      return <PlayerDashboardV3 playState={playState} theme={theme} socket={socket} {...props}/>;
    default:
      return <PlayerDashboardV1 playState={playState} theme={theme} socket={socket} {...props}/>;
  }
};

export default PlayerDashboard;
