import React from 'react'
import { Button, Container, Card, Row } from 'react-bootstrap'

export default function ItemGrid(props) {
  const {
    rooms,
    filter,
    changeFilter,
    filteredItems,
    itemCardStyles,
    toggleAsset
  } = props

  const itemWrapperStyles = {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    margin: "40px auto 40px",
    maxWidth: "1000px",
  }

  const itemCardImageStyles = {
    padding: "10px",
    flex: 1,
  }

  const itemCardFooterStyles = {
    padding: "6px 8px",
    backgroundColor: "#eee",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }

  const cardFooterTextStyles = {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    paddingRight: "8px",
  }

  const filterButtonStyles = (roomId) => {
    return {
      minWidth: "100px",
      borderRadius: "20px",
      cursor: roomId === filter ? "default" : "pointer",
    }
  }
  
  return (
    <>
    <Container fluid className="border-bottom border-dark">
      <Row className="px-4 pt-2 justify-content-center">
        {rooms.map((room, idx) =>
          <Button key={idx}
            disabled={room.roomId === filter ? true : false}
            value={room.roomId}
            onClick={changeFilter}
            variant={room.roomId === filter ? "dark" : "outline-dark"}
            className="mx-2 mb-2"
            style={filterButtonStyles(room.roomId)}
          >{room.roomId === 0 ? "All" : room.roomName}</Button>
        )}
      </Row>
    </Container>
    <Container fluid>
      <div style={itemWrapperStyles}>
        {filteredItems && filteredItems.map((item, index) =>
          <Card key={index} type={item.item_type} id={item.id} style={itemCardStyles(item)} onClick={() => toggleAsset(item)}>
            {(item.item_type === "inventory" || item.item_type === "lock") &&
              <Card.Img variant="top" src={item.src_url} style={itemCardImageStyles}/>
            }
            {item.item_type === "minigame" &&
              <Card.Img variant="top" src={item.icon_url} style={itemCardImageStyles}/>
            }
            {item.item_type === "video" &&
              <svg style={{padding:"10px",flex:1,margin:"0 auto",maxWidth:"75px"}} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                <path d="M0 12V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm6.79-6.907A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
              </svg>
            }
            {item.item_type === "scan" &&
              <svg style={{padding:"10px",flex:1,margin:"0 auto",maxWidth:"75px"}} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z"/>
              </svg>
            }
            <Card.Footer style={itemCardFooterStyles}>
              <small style={cardFooterTextStyles} className="text-muted">{item.label_internal || "Placeholder text"}</small>
              {item.item_type === "video" &&
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M0 12V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm6.79-6.907A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
                </svg>
              }
              {item.item_type === "lock" &&
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
                </svg>
              }
              {item.item_type === "inventory" &&
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M.102 2.223A3.004 3.004 0 0 0 3.78 5.897l6.341 6.252A3.003 3.003 0 0 0 13 16a3 3 0 1 0-.851-5.878L5.897 3.781A3.004 3.004 0 0 0 2.223.1l2.141 2.142L4 4l-1.757.364L.102 2.223zm13.37 9.019l.528.026.287.445.445.287.026.529L15 13l-.242.471-.026.529-.445.287-.287.445-.529.026L13 15l-.471-.242-.529-.026-.287-.445-.445-.287-.026-.529L11 13l.242-.471.026-.529.445-.287.287-.445.529-.026L13 11l.471.242z"/>
                </svg>
              }
              {item.item_type === "scan" &&
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z"/>
                </svg>
              }
              {item.item_type === "minigame" &&
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 576 512">
                  <path fill="currentColor" d="M519.442 288.651c-41.519 0-59.5 31.593-82.058 31.593C377.409 320.244 432 144 432 144s-196.288 80-196.288-3.297c0-35.827 36.288-46.25 36.288-85.985C272 19.216 243.885 0 210.539 0c-34.654 0-66.366 18.891-66.366 56.346 0 41.364 31.711 59.277 31.711 81.75C175.885 207.719 0 166.758 0 166.758v333.237s178.635 41.047 178.635-28.662c0-22.473-40-40.107-40-81.471 0-37.456 29.25-56.346 63.577-56.346 33.673 0 61.788 19.216 61.788 54.717 0 39.735-36.288 50.158-36.288 85.985 0 60.803 129.675 25.73 181.23 25.73 0 0-34.725-120.101 25.827-120.101 35.962 0 46.423 36.152 86.308 36.152C556.712 416 576 387.99 576 354.443c0-34.199-18.962-65.792-56.558-65.792z"></path>
                </svg>
              }
            </Card.Footer>
          </Card>
        )}
      </div>
    </Container>
    </>
  )
}