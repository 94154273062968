import Phaser from "phaser";
import * as BlockGridSocket from "../blockGridSocket";

export default class Block extends Phaser.GameObjects.Sprite {
  constructor(scene, x, y, sprite,quadId,blockId) {
    super(scene, x, y, sprite);
    this.scene = scene;
    this.x = x;
    this.y = y;
    this.sprite = sprite;
    this.isActive = true;
    this.quadId = quadId;
    this.blockId = blockId;
    this.setInteractive();
    scene.add.existing(this);

    this.on("pointerdown", () => {
       this.blockClick();
    });
  }
  blockClick()
  {
    if(this.isActive)
    {
      this.alpha=0.0001;
      this.isActive=false;
    }
    else
    {
      this.alpha=1;
      this.isActive=true;
    }

    BlockGridSocket.toggleBlockState(this.quadId, this.blockId, !this.isActive);
  }
}
