import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios'
import {Button, Nav, InputGroup, FormControl, Form} from 'react-bootstrap';
import './Drawer.scss';
import {useDispatch, useSelector} from "react-redux";
import {resendBookingInvite} from "../../../redux/actionCreators";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faEdit, faCalendar, faSave} from "@fortawesome/free-solid-svg-icons";
import {updateCustomer, getCustomer} from  '../../../common/utils/customer';
import {getCookie} from 'common/utils/auth'
import {config} from 'enviroment.js'


const Drawer = (props) => {
  const { refreshEventsList, event } = props
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [error, setError] = useState('');
  const [customerLoading, setCustomerLoading] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [userForm, setUserForm] = useState({
    customerName: {
      value: '',
      isEdit: false
    },
    customerEmail: {
      value: '',
      isEdit: false
    },
    phone: {
      value: '',
      isEdit: false
    },
    id: {
      value: ''
    }
  });

  useEffect(() => {
    if(event?.eventId) {
      setCustomerLoading(true);
      axios.get(`${config.raUrl}/customers/event/${event.eventId}/`, {
        headers: {
          'Authorization': `Bearer ${getCookie('token')}`,
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        setUserForm(form => {
          form.customerName.value = res.data.customer?.customerName;
          form.customerEmail.value = res.data.customer?.customerEmail;
          form.phone.value = res.data.customer?.phone;
          form.id.value = res.data.customer?.customerId;
          return form
        });
        setCustomerLoading(false);
      })

    }
  }, [event]);

  const toggleEdit = (fieldName) => {
    setMsg('');
    setError('');
    const form = {
      ...userForm
    };
    form[fieldName].isEdit = !form[fieldName].isEdit;
    setUserForm(form);
    if(form[fieldName].isEdit === false) {
      const payload = {};
      payload[fieldName] =  userForm[fieldName].value;
      updateCustomer(userForm.id.value, payload).subscribe((res) => {
        setMsg('Updated successfully');
        // props.refreshEventsList();
      }, (err) => {
        setError('Email already exists');
        console.log(err);
      });
      setTimeout(() => {
        setMsg('');
        setError('');
      }, 10000);
    }
  }

  const changeInput = (e) => {
    const form = {
      ...userForm
    }
    form[e.target.name] = {
      value: e.target.value,
      isEdit: true
    };
    setUserForm(form);
  }
  const zoomRef = useRef(null);
  const guideRef = useRef(null);
  const playRef = useRef(null);
  const copyLink = (ref) => {
    ref.current.select();
    document.execCommand('copy');
  }

  const onResendClick = () => {
    setLoading(true);
    dispatch(resendBookingInvite({
      eventId: props.event.eventId,
      storeId: props.event.storeId,
      roomId:  props.event.roomId,
      // storeId: props.event.ATCAssignedBooking.storeId,
      // roomId:  props.event.ATCAssignedBooking.roomId,
      onComplete: () => {
        setLoading(false);
      }
    }));
  }

  return (<Nav className="sidebar" activeKey="/home">

    {props.event && userForm.id.value ? <div>
      <h4>{props?.event?.title}</h4>
      <br/>
      <h5>Guest info &nbsp;{customerLoading ?  <FontAwesomeIcon size='1x' spin={true} icon={faSpinner} /> : ''}</h5>
      <p className='paragraph-text'>
        {!userForm.customerName.isEdit && <span> Customer Name: {userForm.customerName.value}</span>}
        {userForm.customerName.isEdit && <span>Customer Name: <input name='customerName' value={userForm.customerName.value} onChange={(e) => changeInput(e)} placeholder='First name' type="text"/></span>}
        <FontAwesomeIcon onClick={() => toggleEdit('customerName')} className='edit-icon' size='1x' icon={faEdit} />
      </p>
      <p className='paragraph-text'>
        {!userForm.customerEmail.isEdit && <span> Email: {userForm.customerEmail.value}</span>}
        {userForm.customerEmail.isEdit && <span>Email: <input name='customerEmail' value={userForm.customerEmail.value} onChange={(e) => changeInput(e)} placeholder='Email' type="text"/></span>}
        <FontAwesomeIcon onClick={() => toggleEdit('customerEmail')} className='edit-icon' size='1x' icon={faEdit} />
      </p>
      <p className='paragraph-text'>
        {!userForm.phone.isEdit && <span> Phone: {userForm.phone.value}</span>}
        {userForm.phone.isEdit && <span>Phone: <input name='phone' value={userForm.phone.value} onChange={(e) => changeInput(e)} placeholder='Phone name' type="text"/></span>}
        <FontAwesomeIcon onClick={() => toggleEdit('phone')} className='edit-icon' size='1x' icon={faEdit} />
      </p>
      <p className='text-success paragraph-text'> {msg}</p>
      <p className='text-danger paragraph-text'> {error}</p>
    </div>: ''}
    {/* {props.event.ATCAssignedBooking ? <div> */}
    {props.event.atcEventId ? <div>
      <br/>
      <h5>Booking Details</h5>
      <label style={{marginTop: 10}}><b> Zoom</b></label>
      <InputGroup className="mb-3" size='sm'>
        <FormControl
          ref={zoomRef}
          onChange={() => {}}
          placeholder={props.event ? props.event.raZoomLink: ''}
          aria-label={props.event ? props.event.raZoomLink: ''}
          value={props.event ? props.event.raZoomLink: ''}
          aria-describedby="basic-addon2"
        />
        <InputGroup.Append>
          <InputGroup.Text style={{cursor: 'pointer'}} className='cursor-pointer' id="basic-addon2" onClick={() => copyLink(zoomRef)}>Copy</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
      <label><b> Play</b></label>
      <InputGroup className="mb-3" size='sm'>
        <FormControl
          ref={playRef}
          onChange={() => {}}
          placeholder={props.event.x_event_id ? `https://remoteadventures.theescapegame.com/play/${props.event.x_event_id}`: ''}
          aria-label={props.event.x_event_id ? `https://remoteadventures.theescapegame.com/play/${props.event.x_event_id}`: ''}
          aria-describedby="basic-addon2"
          value={props.event.x_event_id ? `https://remoteadventures.theescapegame.com/play/${props.event.x_event_id}`: ''}
        />
        <InputGroup.Append>
          <InputGroup.Text className='cursor-pointer' onClick={() => copyLink(playRef)} id="basic-addon2">Copy</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
      <label><b> Guide</b></label>
      <InputGroup className="mb-3" size='sm'>
        <FormControl
          ref={guideRef}
          onChange={() => {}}
          placeholder={props.event.x_event_id ? `https://remoteadventures.theescapegame.com/host/${props.event.x_event_id}`: ''}
          aria-label={props.event.x_event_id ? `https://remoteadventures.theescapegame.com/host/${props.event.x_event_id}`: ''}
          aria-describedby="basic-addon2"
          value={props.event.x_event_id ? `https://remoteadventures.theescapegame.com/host/${props.event.x_event_id}`: ''}
        />
        <InputGroup.Append>
          <InputGroup.Text className='cursor-pointer' onClick={() => copyLink(guideRef)} id="basic-addon2">Copy</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>

      <div className='text-center'>
        <Button variant="outline-secondary"  onClick={() => onResendClick()}>Resend Invite   {loading &&  <FontAwesomeIcon size='1x' spin={true} icon={faSpinner} />}</Button>
      </div>
    </div>: <div className='mt-2'> {!props?.event?.ATCAssignedBooking ? 'No booking available': 'No item selected'}</div> }
  </Nav>)
}
export default Drawer;
