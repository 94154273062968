import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import GuideInventoryModule from "Components/Guide/GuideInventoryModule/GuideInventoryModule"
import GuideMediaModule from "Components/Guide/GuideMediaModule/GuideMediaModule"


export default function LegacyGrid(props) {
  const {
    gameMedia,
    socket,
    inventory,
    setInventory,
    theme,
    scans,
    videos,
    sounds,
    minigames,
    setScans,
    setVideos,
    setSounds,
    setMinigames
  } = props

  return (
    <Container fluid>
      <Row className="mt-3">
        <Col>
          <Container fluid>
            {gameMedia.lockInventory && (
              <Row className="inventory lock-inventory">
                <GuideInventoryModule
                  socket={socket}
                  currentInventory={inventory}
                  setInventory={setInventory}
                  theme={theme}
                  title="Locks"
                  inventory={gameMedia.lockInventory}
                  tracking={gameMedia.tracking}
                />
              </Row>
            )}
            {gameMedia.foundedToys.length > 0 && (
              <Row className="inventory lock-inventory">
                <GuideInventoryModule
                  socket={socket}
                  currentInventory={inventory}
                  setInventory={setInventory}
                  theme={theme}
                  title="Founded Toys"
                  inventory={gameMedia.foundedToys}
                  tracking={gameMedia.tracking}
                />
              </Row>
            )}
            <Row className="inventory base-inventory">
              <GuideInventoryModule
                socket={socket}
                currentInventory={inventory}
                setInventory={setInventory}
                theme={theme}
                inventory={gameMedia.baseInventory}
                tracking={gameMedia.tracking}
              />
            </Row>
          </Container>
        </Col>
        <Col lg={4}>
          <GuideMediaModule
            socket={socket}
            currentScans={scans}
            currentVideos={videos}
            currentSounds={sounds}
            currentMiniGames={minigames}
            setScans={setScans}
            setVideos={setVideos}
            setSounds={setSounds}
            setMinigames={setMinigames}
            theme={theme}
            data={gameMedia}
          />
        </Col>
      </Row>
    </Container>
  )
}