import React from "react"
import {
  Form,
  Row,
  Col,
  FormControl,
  Button,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap"

import "./LoginForm.scss"

export default function OMITBLoginForm({
  isPasswordCorrect,
  playerLoginPassword,
  formData,
  setFormData,
  onFormUpdate,
  isSubmitted,
  isValid,
  onSubmit,
  isLoading,
  countries,
}) {

  function onCountryUpdate(e) {
    let country = e.target.options[e.target.selectedIndex].text
    let country_id = e.target.value
    if (e.target.value === "Country") {
      country = undefined
      country_id = undefined
    }
    setFormData({ ...formData, country, country_id });
  }

  return (
    <div className="form-container" style={{position:'relative'}}>
      {(!isPasswordCorrect && !playerLoginPassword) &&
        <div style={{color:'red', position:'absolute', left:'50%', transform:'translateX(-50%)', top:'-30px'}}>Incorrect Player Password</div>
      }
      <Row>
        <Col>
          <InputGroup>
            <OverlayTrigger
              key={"lastName-validation"}
              placement={"top"}
              overlay={
                isSubmitted && !isValid("firstName") ? (
                  <Tooltip
                    className="validation-tooltip"
                    id={`tooltip-top`}
                  >
                    First name is required.
                  </Tooltip>
                ) : (
                  <Tooltip className="d-none"></Tooltip>
                )
              }
            >
              <FormControl
                placeholder={`First Name*`}
                aria-label="firstName"
                aria-describedby="basic-addon1"
                name="firstName"
                value={formData.firstName}
                onChange={(e) => onFormUpdate(e)}
                isInvalid={isSubmitted && !isValid("firstName")}
              />
            </OverlayTrigger>
          </InputGroup>
        </Col>
        <Col>
          <InputGroup>
            <OverlayTrigger
              key={"lastName-validation"}
              placement={"top"}
              overlay={
                isSubmitted && !isValid("lastName") ? (
                  <Tooltip
                    className="validation-tooltip"
                    id={`tooltip-top`}
                  >
                    Last name is required.
                  </Tooltip>
                ) : (
                  <Tooltip className="d-none"></Tooltip>
                )
              }
            >
              <FormControl
                placeholder={`Last Name*`}
                aria-label="lastName"
                aria-describedby="basic-addon1"
                name="lastName"
                value={formData.lastName}
                onChange={(e) => onFormUpdate(e)}
                isInvalid={isSubmitted && !isValid("lastName")}
              />
            </OverlayTrigger>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroup>
            <OverlayTrigger
              key={"email-validation"}
              placement={"right"}
              overlay={
                isSubmitted && !isValid("email") ? (
                  <Tooltip
                    className="validation-tooltip"
                    id={`tooltip-right`}
                  >
                    Email must be a valid <strong>email address</strong>
                  </Tooltip>
                ) : (
                  <Tooltip className="d-none"></Tooltip>
                )
              }
            >
              <FormControl
                placeholder={`Email*`}
                aria-label="email"
                aria-describedby="basic-addon1"
                name="email"
                type="email"
                value={formData.email}
                onChange={(e) => onFormUpdate(e)}
                isInvalid={isSubmitted && !isValid("email")}
              />
            </OverlayTrigger>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroup>
            <FormControl
              as="select"
              placeholder={`Country`}
              aria-label="country"
              aria-describedby="basic-addon1"
              name="country"
              value={formData.country_id}
              onChange={onCountryUpdate}
            >
              <option value={null}>Country</option>
              {(countries || []).map((country) => (
                <option key={country.name} value={country.country_id}>{country.name}</option>
              ))}
            </FormControl>
          </InputGroup>
        </Col>
        <Col>
          <InputGroup>
            <OverlayTrigger
              key={"postalCode-validation"}
              placement={"right"}
              overlay={
                isSubmitted && !isValid("postalCode") ? (
                  <Tooltip
                    className="validation-tooltip"
                    id={`tooltip-right`}
                  >
                    Postal code is required.
                  </Tooltip>
                ) : (
                  <Tooltip className="d-none"></Tooltip>
                )
              }
            >
              <FormControl
                placeholder={`Postal Code*`}
                aria-label="postalCode"
                aria-describedby="basic-addon1"
                name="postalCode"
                value={formData.postalCode}
                onChange={(e) => onFormUpdate(e)}
                isInvalid={isSubmitted && !isValid("postalCode")}
              />
            </OverlayTrigger>
          </InputGroup>
        </Col>
      </Row>
      {playerLoginPassword &&
        <Row>
          <Col>
            <InputGroup>
              <OverlayTrigger
                key={"fname-validation"}
                placement={"right"}
                overlay={
                  isSubmitted && (!isValid("pass") || !isPasswordCorrect) ? (
                    <Tooltip
                      className="validation-tooltip"
                      id={`tooltip-right`}
                    >
                      {!isValid("pass") ? (
                        <span>
                          <strong>Password</strong> is a required field.
                        </span>
                      ) : !isPasswordCorrect ? (
                        "That password doesn�t seem to be correct. Try again!"
                      ) : (
                        ""
                      )}
                    </Tooltip>
                  ) : (
                    <Tooltip className="d-none"></Tooltip>
                  )
                }
              >
                <FormControl
                  placeholder={`4-Digit Game Password *`}
                  aria-label="pass"
                  aria-describedby="basic-addon1"
                  name="pass"
                  value={formData.pass}
                  onChange={(e) => onFormUpdate(e)}
                  isInvalid={
                    isSubmitted && (!isValid("pass") || !isPasswordCorrect)
                  }
                />
              </OverlayTrigger>
            </InputGroup>
          </Col>
        </Row>
      }
      <div className="checkboxes">
        <Row className="justify-content-md-center mt-3">
          <Col>
            <InputGroup>
              <Form.Group controlId="formBasicCheckbox" className="m-0">
                <Form.Check
                  type="checkbox"
                  label="Sign me up to receive email updates from The Escape Game including new games, locations and products."
                  name="receiveEmails"
                  checked={formData.receiveEmails}
                  onChange={(e) => onFormUpdate(e)}
                />
              </Form.Group>
            </InputGroup>
          </Col>
        </Row>
        <Row className="justify-content-md-center mt-0">
          <Col>
            <InputGroup>
              <Form.Group controlId="termsCheckbox" className="m-0 d-flex align-items-center">
                <Form.Check>
                  <Form.Check.Input
                    type="checkbox"
                    name="terms"
                    checked={formData.terms}
                    onChange={(e) => onFormUpdate(e)}
                    isInvalid={isSubmitted && !isValid("terms")}
                  />
                  <Form.Check.Label>I agree to The Escape Game’s <a href="https://theescapegame.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://theescapegame.com/terms/" target="_blank" rel="noopener noreferrer">Terms of Service</a>.</Form.Check.Label>
                  <Form.Control.Feedback type="invalid">You must agree before submitting.</Form.Control.Feedback>
                </Form.Check>
              </Form.Group>
            </InputGroup>
          </Col>
        </Row>
      </div>
      <Row className="justify-content-center mt-3">
        <Button onClick={onSubmit} disabled={isLoading}>
          {isLoading ? "SUBMITTING" : "SUBMIT"}
        </Button>
      </Row>
    </div>
  )
}