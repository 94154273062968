/* eslint-disable */
import React, { useState } from "react";
import mixpanel from "lib/mixpanel"

// Styles
import "./GuideMediaModule.scss";
import { Button, Container, Row, Col } from "react-bootstrap";

const GuideMediaModule = ({
  currentScans,
  currentSounds,
  currentVideos,
  currentMiniGames,
  setScans,
  setSounds,
  setVideos,
  setMinigames,
  socket,
  theme,
  data,
}) => {
  const sendScan = (e, value) => {
    if (value) {
      const currentTarget = e.currentTarget
      if (currentScans.includes(value)) {
        socket.emit("scan-remove", value, () => {
          setScans(currentScans.filter((mediaId) => mediaId !== value))
        })
      } else {
        socket.emit("scan-add", value, () => {
          setScans([...currentScans, value])
          const mixpanelTracking = {
            ...data.tracking,
            "item_id": value,
            "item_name": currentTarget.innerText,
            "item_type": "scan"
          }
          mixpanel.track('Item Sent', mixpanelTracking)
        })
      }
    }
  };

  const sendVideo = (e, value) => {
    if (value) {
      const currentTarget = e.currentTarget
      if (currentVideos.includes(value)) {
        socket.emit("video-remove", value, () => {
          setVideos(currentVideos.filter((mediaId) => mediaId !== value))
        })
      } else {
        socket.emit("video-add", value, () => {
          setVideos([...currentVideos, value])
          const mixpanelTracking = {
            ...data.tracking,
            "item_id": value,
            "item_name": currentTarget.innerText,
            "item_type": "video"
          }
          mixpanel.track('Item Sent', mixpanelTracking)
        });
      }
    }
  }

  const sendSound = (value) => {
    if (value) {
      if (currentSounds.includes(value)) {
        socket.emit("sound-remove", value, () => {
          console.log(`Sent sound removal to player: ${value}.`);

          setSounds(currentSounds.filter((mediaId) => mediaId !== value));
        });
      } else {
        socket.emit("sound-add", value, () => {
          console.log(`Sound sent to players: ${value}.`);

          setSounds([...currentSounds, value]);
        });
      }
    }
  };

  const sendMiniGame = (value) => {
    if (value) {
      if (currentMiniGames.includes(value)) {
        socket.emit("minigame-remove-legacy", value, () => {
          console.log(`Sent minigame removal to player: ${value}.`);

          setMinigames(currentMiniGames.filter((mediaId) => mediaId !== value));
        });
      } else {
        socket.emit("minigame-add-legacy", value, () => {
          console.log(`Minigame sent to players: ${value}.`);

          setMinigames([...currentMiniGames, value]);
        });
      }
    }
  };

  return (
    <Container className={theme}>
      <Row className="media-module">
        <Col>
          <Row>
            <Col className="center">
              <h5>Videos</h5>
              <div>
                {data &&
                  data.videos.map((video) => {
                    const classes = `guide-media-buttons${
                      currentVideos.includes(video.id) ? " item-sent" : ""
                    }`;

                    return (
                      <Button
                        key={video.id}
                        value={video.id}
                        className={classes}
                        onClick={(e) => sendVideo(e, video.id)}
                      >
                        {video.data.name.toUpperCase()}
                      </Button>
                    );
                  })}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="center">
              <h5>Scans</h5>
              <div>
                {data &&
                  data.scans.map((scan) => {
                    const classes = `guide-media-buttons${
                      currentScans.includes(scan.id) ? " item-sent" : ""
                    }`;

                    return (
                      <Button
                        key={scan.id}
                        value={scan.id}
                        className={classes}
                        onClick={(e) => sendScan(e, scan.id)}
                      >
                        {scan.data.name.toUpperCase()}
                      </Button>
                    );
                  })}
              </div>
            </Col>
          </Row>
          {data.minigames?.length > 0 &&
            <Row>
              <Col className="center">
                <h5>Mini Games</h5>
                <div>
                  {data &&
                  data.minigames.map((minigame) => {
                    const classes = `guide-media-buttons${
                      currentMiniGames.includes(minigame.id) ? " item-sent" : ""
                    }`;

                    return (
                      <Button
                        key={minigame.id}
                        value={minigame.id}
                        className={classes}
                        onClick={() => sendMiniGame(minigame.id)}
                      >
                        {minigame.data.name.toUpperCase()}
                      </Button>
                    );
                  })}
                </div>
              </Col>
            </Row>
          }
          <Row>
            <Col className="center">
              <h5>Sounds</h5>
              <div>
                {data &&
                data.sounds.map((sound) => {
                  const classes = `guide-media-buttons${
                    currentSounds.includes(sound.id) ? " item-sent" : ""
                  }`;

                  return (
                    <Button
                      key={sound.id}
                      value={sound.id}
                      className={classes}
                      onClick={() => sendSound(sound.id)}
                    >
                      {sound.data.name.toUpperCase()}
                    </Button>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default GuideMediaModule;
