import React from "react";
import { Tab } from 'react-tabs'


const TabDefault = (props) => {
	const {classes, icon, newAssetCount, label} = props
	return (
		<Tab className={classes}>
			<div className="tab-content">
				<img src={icon} alt=""/>
				<h2>{label}</h2>
				{newAssetCount > 0 &&
					<span>{newAssetCount}</span>
				}
			</div>
		</Tab>
	)
}

export default TabDefault