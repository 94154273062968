import {
  LIST_BOOKINGS,
  BOOKING_SUCCESS,
  BOOKING_ERROR,
  ASSIGN_BOOKING,
  RESEND_INVITE, UPDATE_BOOKING
} from "../actions/index";

export const listBookings = (payload) => ({
  type: LIST_BOOKINGS,
  payload: payload
});

export const updateBooking = (payload) => ({
  type: UPDATE_BOOKING,
  payload: payload
});

export const resendBookingInvite = (payload) => ({
  type: RESEND_INVITE,
  payload: payload
});

export const assignBooking = (payload) => ({
  type: ASSIGN_BOOKING,
  payload: payload
});

export const bookingSuccess = (payload) => ({
  type: BOOKING_SUCCESS,
  payload: payload
});

export const bookingError = (payload) => ({
  type: BOOKING_ERROR,
  payload: payload
});
