import io from "socket.io-client";
import { TEG_APP } from "lib/constants";

let PuttPuttSocket;
let currentGameId;

export const connectSocket = () => {
  PuttPuttSocket = io(TEG_APP.ENDPOINT);
};

export const getSocetId = () => {
  return PuttPuttSocket.id;
};

export const joinPuttPuttGame = (gameId) => {
  currentGameId = gameId
  let user = JSON.parse(localStorage.getItem("userInfo"))
  PuttPuttSocket.emit("join_puttPutt_game", gameId, user && user.firstName ? user.firstName : "user", () =>
    console.log(`Sent join emission to server.`)
  );
};

export const getUpdatedState = (GameScene) => {
  PuttPuttSocket.on("puttPutt_state_update", (data) => {
    // console.log("update from server")
    GameScene.updateGameState(data.gameData)
    // GameScene.updateScoreBoard(data.targetsHit)
    return data;
  });
};

export const sendBallLocation = (cursorData) => {
  PuttPuttSocket.emit("puttPutt_send_ball_location", currentGameId, cursorData);
};

export const gameCompleted = () => {
    PuttPuttSocket.emit("puttPutt_game_complete", currentGameId);
};

export const registerAShot = (forceX, forceY) => {
  PuttPuttSocket.emit("puttPutt_register_a_shot", forceX, forceY);
};

export const anotherUserHadAShot = (GameScene) => {
  PuttPuttSocket.on("someone_had_a_shot", (forceX, forceY) => {
    GameScene.SomeoneHadAShot(forceX, forceY)
  });
};

export const gameWon = (GameScene) => { // game won by someone else
    PuttPuttSocket.on("puttPutt_game_won", (winner) => {
      GameScene.onGameWon(winner)
    });
};

export const onTurnChange = (GameScene) => {
  PuttPuttSocket.on("reset_ball_location", () => {
    GameScene.resetBallLocation()
  });
};

export const registerUserSwing = () => {
    PuttPuttSocket.emit("puttPutt_register_user_try", currentGameId);
};


export const disconnect = () => {
  if (!PuttPuttSocket) return true;

  PuttPuttSocket.emit("disconnect_minigame", currentGameId, "PuttPutt");

  PuttPuttSocket.removeAllListeners();
  PuttPuttSocket.disconnect();
};

