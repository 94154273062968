import {mergeMap, map, catchError} from 'rxjs/operators';
import { from } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';

import {
  GET_USER,
  LOGIN_USER,
  GET_COUNTRIES
} from '../actions/userActions';
import {userSuccess, userError, setCountries, countriesError} from '../actionCreators';
import {config} from '../../enviroment';
import {getCookie, removeCookie, setCookie} from '../../common/utils/auth';
const header = { 'Content-Type': 'application/json' };
const getAuth = () => {
  return {
    ...header,
    'Authorization': `Bearer ${getCookie('token')}`
  }
}

export const loginUserEpic = action$ => action$.pipe(
  ofType(LOGIN_USER),
  mergeMap((action) => from(new Promise((resolve, reject) => {
    ajax.post(`${config.raUrl}/users/login`, action.payload, header)
      .subscribe((userResponse) => {
        const data = userResponse.response;
        setCookie('token', data.token, 15);
        resolve(data);
        action.payload.onComplete();
      }, (err) => {
        reject(err.response);
      })
  })).pipe(
    map(response =>  userSuccess({user: response.user})),
    catchError(error => Promise.resolve(userError(error)))
  ))
)

export const getUserEpic = action$ => action$.pipe(
  ofType(GET_USER),
  mergeMap((action) => from(new Promise((resolve, reject) => {
    
    ajax.getJSON(`${config.raUrl}/users/me`, getAuth())
      .subscribe((userResponse) => {
        resolve(userResponse);
        action.payload.onComplete();
      }, (err) => {
        action.payload.onError();
        removeCookie();
        reject(err.response);
      })
  })).pipe(
    map(response =>  userSuccess({user: response.user})),
    catchError(error => Promise.resolve(userError(error)))
  ))
)

// export const getCountriesEpic = action$ => action$.pipe(
//   ofType(SET_COUNTRIES),
//   mergeMap((action) => from(new Promise((resolve, reject) => {
//     ajax.getJSON(`${config.raUrl}/countries`)
//       .subscribe((countriesResponse) => {
//         resolve(countriesResponse);
//         action.payload.onComplete();
//       }, (err) => {
//         action.payload.onError();
//         reject(err.response);
//       })
//   })).pipe(
//     map(response =>  setCountries({countries: response.countries})),
//     catchError(error => console.log(error))
//   ))
// )

export const getCountriesEpic = action$ => action$.pipe(
  ofType(GET_COUNTRIES),
  mergeMap(action =>
    ajax.getJSON(`${config.raUrl}/countries`).pipe(
      map(response => setCountries(response)),
      catchError(error => Promise.resolve(countriesError({error: error.response})))
    )
  ),
)
