import React, { useEffect } from "react";
import CarnivalGame from "Components/MiniGames/ShootingRange/phaser";
import * as CarnivalSocket from "Components/MiniGames/ShootingRange/carnival";


export default function ShootingRange(props) {
  const { gameId } = props

  useEffect(() => {
    CarnivalSocket.connectSocket();
    CarnivalSocket.joinCarnivalGame(gameId)
    const carnivalGame = new CarnivalGame()
    return () => {
      carnivalGame.cleanup()
      CarnivalSocket.disconnect()
    }
  }, [gameId])

  return (
    <div id="minigame_container"></div>
  )
}
