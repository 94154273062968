import {mergeMap, map, catchError} from 'rxjs/operators';
import { from } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';

import {
 LIST_STORES
} from '../actions/storeActions';
import {storeSuccess, storeError} from '../actionCreators';
import {config} from '../../enviroment';
import {getCookie} from '../../common/utils/auth';
const header = { 'Content-Type': 'application/json' };
const getAuth = () => {
  return {
    ...header,
    'Authorization': `Bearer ${getCookie('token')}`
  }
}

export const listStoreEpic = action$ => action$.pipe(
  ofType(LIST_STORES),
  mergeMap(action =>
    ajax.getJSON(`${config.raUrl}/stores`, getAuth()).pipe(
      map(response => storeSuccess(response)),
      catchError(error => Promise.resolve(storeError({error: error.response})))
    )
  ),
)
