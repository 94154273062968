import React, { useEffect, useState } from 'react';

import './ClientTestPage.scss';
import {  TEG_APP } from '../../lib/constants'

// Images
import tegLogo from "../../images/teg_lock_logo_flat.png";
import raBanner from "../../images/remote_adventures_banner.png";
import raLogo from "../../images/ra_logo.png";
import lockyFail from "../../images/test-page-locky-fail.png";
import lockySuccess from "../../images/test-page-locky-success.png";
import errorImg from "../../images/test-page-error.png";
import successImg from "../../images/test-page-success.png";

let testingItems = [
  {
    "type": "database",
    "src": `${TEG_APP.ENDPOINT}/api/games/`,
    "path": "https://ra-atc-api.theescapegame.com",
  },
  {
    "table": "escp_ra_media",
    "id": 3,
    "type": "image",
    "src": "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/ruins/Inventory_Bamboo.png",
    "path": "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com",
  },
  {
    "table": "escp_ra_media",
    "id": 2006,
    "type": "video",
    "src": "https://player.vimeo.com/video/425496556",
    "path": "https://player.vimeo.com",
  },
  {
    "table": "escp_ra_media",
    "id": 2062,
    "type": "scan1",
    "src": "https://s.insta360.com/p/bd63ea899429a469c7d58cde9611a079?e=true&locale=en-us",
    "path": "https://s.insta360.com",
  },
  {
    "table": "escp_ra_media",
    "id": 2067,
    "type": "scan2",
    "src": "https://d20whurec95g80.cloudfront.net",
    "path": "*.cloudfront.net",
  },
]

const TestPage = () => {
  const [checkedStatus, setCheckedStatus] = useState({
    "database": false,
    "image": false,
    "video": false,
    "scan1": false,
    "scan2": false
  })
  const [tempResults, setTempResults] = useState([])
  const [results, setResults] = useState(false)
  const [display, setDisplay] = useState("loading")

  useEffect(() => {

    function loadScriptError(asset) {
      let script = document.createElement('script')
      script.src = asset.src
      script.onload = function() {
        setCheckedStatus(checkedStatus => ({...checkedStatus, [asset.type]: true}))
      }
      script.onerror = function() {
        setTempResults(tempResults => [...tempResults, asset.path])
        setCheckedStatus(checkedStatus => ({...checkedStatus, [asset.type]: true}))
      }
      document.body.append(script)
    }

    async function getResults() {
      for (const asset of testingItems) {

        if (["database", "image"].includes(asset.type)) {
          try {
            let response = await fetch(asset.src)
            if (response.status !== 200) {
              setTempResults(tempResults => [...tempResults, asset.path])
            }
          } catch (e) {
            setTempResults(tempResults => [...tempResults, asset.path])
          }
          setCheckedStatus(checkedStatus => ({...checkedStatus, [asset.type]: true}))
        } else {
          loadScriptError(asset)
        }

      }
    }
    getResults()

    // append canvas-confetti to body
    const scriptConfetti = document.createElement("script");
    scriptConfetti.src = "https://cdn.jsdelivr.net/npm/canvas-confetti@1.3.2/dist/confetti.browser.min.js";
    document.body.appendChild(scriptConfetti);

  }, [])

  useEffect(() => {
    if (Object.values(checkedStatus).every(e => e === true)) {
      setResults(tempResults)
    }
  }, [checkedStatus])

  useEffect(() => {
    if (results) {
      setTimeout(function() {
        if (results.length > 0) {
          setDisplay("fail")
        } else {
          setDisplay("success")
        }
      }, 2000)
    }
  }, [results])

  return (
    <div id="testPageWrapper">
      <header>
        <div className="top"></div>
        <div className="bottom">
          <a href="https://theescapegame.com/">
            <img src={tegLogo} alt="The Escape Game" />
          </a>
        </div>
      </header>
      <section className="banner">
        <div className="banner__content">
          <img src={raLogo} alt="Remote Adventures" />
          <h1>Virtual Escape Rooms</h1>
        </div>
        <img className="banner__bg" src={raBanner} alt="Remote Adventures Background" />
      </section>
      {}
      <Output display={display} results={results}/>
    </div>
  )
}
export default TestPage;



const Output = ({display, results}) => {
  return (
    <>
    {display === "loading" && <Loading /> }
    {display === "success" && <Success /> }
    {display === "fail" && <Fail results={results}/> }
    </>
  )
}

const Loading = () => {

  return (
    <div>
      <h2 className="loading_header">Testing Network</h2>
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
  )
}

const Success = () => {

  useEffect(() => {
    const confettiInterval = setInterval(function() {
      if (window.confetti) {
        window.confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 }
        });
        clearInterval(confettiInterval)
      }
    }, 500)
  }, [])

  return (
    <>
    <img className="locky" src={lockySuccess} alt="Test Successful"/>
    <h2 className="success_header">Success!</h2>
    <p className="success_text">
      Thanks for visiting! If you're seeing this, that means you'll be able to access the
      digital dashboard on the day of your team building experience! On the day of, our host
      will provide a specific link for your game! You won't need anything else from this page!
      Feel free to close out this page and we'll see you soon!
    </p>
    </>
  )
}

const Fail = ({results}) => {

  return (
    <>
    <img className="locky" src={lockyFail} alt="Test Failed"/>
    <p className="success_text">
      Oh no! It looks like you currently cannot play The Escape Game's Remote Adventures
      on your network. Please see the instructions below to ensure your team is setup for success
      on the day of your event.
    </p>
    <div className="results">
      <ul className="results__left">
        <li>
          {results.includes(testingItems[0].path) ? (
            <img src={errorImg} alt="error"/>
          ) : (
            <img src={successImg} alt="success"/>
          )}
          <p>Remote Adventures API Request</p>
        </li>
        <li>
          {results.includes(testingItems[1].path) ? (
            <img src={errorImg} alt="error"/>
          ) : (
            <img src={successImg} alt="success"/>
          )}
          <p>Remote Adventures Image Media</p>
        </li>
        <li>
          {results.includes(testingItems[2].path) ? (
            <img src={errorImg} alt="error"/>
          ) : (
            <img src={successImg} alt="success"/>
          )}
          <p>Remote Adventures Video Media</p>
        </li>
        <li>
          {(results.includes(testingItems[3].path) || results.includes(testingItems[3].path)) ? (
            <img src={errorImg} alt="error"/>
          ) : (
            <img src={successImg} alt="success"/>
          )}
          <p>Remote Adventures Scan Media</p>
        </li>
      </ul>
      <div className="results__right">
        <h4>Instructions For Your IT Team</h4>
        <p>Please ensure that the following domains have been whitelisted on your firewall:</p>
        {results.map((result, idx) => (
          <span className="domain" key={idx}>{result}</span>
        ))}
      </div>
    </div>
    <CopyInstructionsButton />
    </>
  )
}

const CopyInstructionsButton = (props) => {
  const [copySuccess, setCopySuccess] = useState(false)

  const getInstructionsText = () => {
    const element = document.querySelector('.results__right')
    return element.innerText || element.textContent
  }

  const copyToClipboard = (e) => {
    const el = document.createElement('textarea');
    el.value = getInstructionsText();
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
    setCopySuccess(true)
    setTimeout(() => {
      setCopySuccess(false)
    }, 1000)
  }

  const copiedStyle = {
    position: 'absolute',
    top: '60px',
    transform: 'translateX(-50%)',
    left: '50%',
    color: '#000',
    transition: 'opacity .2s',
    opacity: copySuccess ? '1' : '0',
  }

  return (
      <div className="copy-btn-wrapper">
        <div onClick={copyToClipboard} className="copy-btn">COPY INSTRUCTIONS</div>
        <span style={copiedStyle}>Copied!</span>
      </div>
  )
}