import React, {useState} from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import './Profile.scss';
import Header from '../Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCalendar, faSpinner} from '@fortawesome/free-solid-svg-icons'
import {updateProfile} from '../../../common/utils/user';
import SettingsHeader from './SettingsHeader';

const Profile = (props) => {
  const [loginForm, setLoginForm] = useState({
    password: '',
    confirmPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');

  const home = () => {
    props.history.push('/atc');
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if(loginForm.password !== loginForm.confirmPassword){
      setMsg('Password and confirm password do not match');
      return;
    }
    setLoading(true);
    setMsg('');
    updateProfile(loginForm).subscribe(() => {
      setLoading(false);
      setMsg('Password Changed');
    });
  }

  const onChange = (e) => {
    const values = {...loginForm};
    values[e.target.name] = e.target.value;
    setLoginForm(values);
  }

  return (<React.Fragment>
    <Header></Header>

    <SettingsHeader history={props.history} selected={1} />
    <div className='signin-section'>
      <Card className='signin-card'>
        <div className='heading'>Change Password</div>
        <Form onSubmit={onSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>New Password</Form.Label>
            <Form.Control required name='password'  onChange={(e)=> onChange(e)} type="password" placeholder="Enter new Password" />
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control required name='confirmPassword'  onChange={(e)=> onChange(e)} type="password" placeholder="Confirm Password" />
          </Form.Group>
          <Button variant="secondary" type="submit">
            Save {' '}
            {loading &&  <FontAwesomeIcon size='1x' spin={true} icon={faSpinner} />}
          </Button>
          <p className='text-danger text-center message'>{msg}</p>
        </Form>
      </Card>
    </div>
  </React.Fragment>)
}
export default Profile;
