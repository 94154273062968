import React, { useEffect } from "react";
import PuttGame from "./phaser/";
import * as PuttPuttSocket from "./puttPutSocket";


export default function PuttPutt(props) {
  const { gameId } = props

  useEffect(() => {
    PuttPuttSocket.connectSocket();
    PuttPuttSocket.joinPuttPuttGame(gameId)
    const puttGame = new PuttGame() 
    return () => {
        puttGame.cleanup()
        PuttPuttSocket.disconnect()
    }
  }, [gameId])

  return (
    <div id="minigame_container"></div>
  )
}
