import React from "react";


const Footer = (props) => {
  const { themeSettings } = props

  return (
    <div id="footer" style={{
        background: themeSettings.footerBackgroundColor,
        height: themeSettings.footerHeight || '65px'
    }}>
        <img src={themeSettings.footerImage} alt="Custom Logo"/>
        {(themeSettings.footerImage && themeSettings.footerText) &&
            <div class="spacer" style={{
                borderColor: themeSettings.footerBorderColor
            }}></div>
        }
        <p style={{
            color: themeSettings.footerTextColor
        }}>{themeSettings.footerText}</p>
    </div>
  )
}

export default Footer
