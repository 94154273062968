import Preload from "./preload";
import BlockGridGameScene from "./BlockGridGameScene";
import Phaser from "phaser";
// import GameScene from "./gamescene";

export default class BlockGridGame {
  scenes = [];
  config = {
    type: Phaser.AUTO,
    parent: "minigame_container",
    width: 1920,
    height: 1080,
    scale: {
      mode: Phaser.Scale.FIT,
      autoCenter: Phaser.Scale.CENTER_BOTH,
    },
    dom: {
      createContainer: true,
    },
    scene: this.scenes,
  };

  constructor() {
    this.scenes.push(Preload);
    this.scenes.push(BlockGridGameScene);
    // this.scenes.push(GameEnd);
    this.game = new Phaser.Game(this.config);
  }

  cleanup() {
    const preloadScene = this.game.scene.getScene('Preload')
    const gameScene = this.game.scene.getScene('BlockGridGameScene')
    preloadScene.cleanup()
    gameScene.cleanup()
  }

}
