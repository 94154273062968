import Phaser from "phaser";
import * as BallSortSocket from "../ballSortSocket";

export default class Tube extends Phaser.GameObjects.Sprite {
    constructor(scene, x, y, sprite,highlightSprite,release,id) {
      super(scene, x, y, sprite);
      this.scene = scene;
      this.id = id;
      this.xVal = x;
      this.yVal = y;
      this.stackDev=this.height/13.2;
      this.isAnimating=false;
      this.sprite = sprite;
      this.highlightSprite=highlightSprite;
      this.tubeStack=new TubeStack(this.xVal,this.yVal,this.stackDev);
      this.setInteractive();
      this.release=scene.add.image(this.x,this.y+this.height*0.54,release).setDepth(2);
      this.release.setInteractive();
      this.release.on("pointerdown",()=>{
        this.tubeStack.release(this.scene,this.release);
        BallSortSocket.releaseBallsFromTube(this.id)
      });
      this.setDepth(2);
      scene.add.existing(this);
  
      this.on("pointerover", () => {
        scene.checkBallOver(this);
      });
      this.on("pointerout", () => {
        scene.ballOut();
        this.setTexture(this.sprite);
      });


    }
    highLight()
    {
      this.setTexture(this.highlightSprite);
    }
    dropBall(ball)
    {
      if(this.tubeStack.index!=this.tubeStack.items.length)
      {
        var thisIndex=0;
        this.isAnimating=true;
        this.release.removeInteractive();
        let newBall=this.scene.add.image(this.x,this.y-(this.height/2),ball);
        let duration=(newBall.y-this.tubeStack.items[this.tubeStack.index].y)/(newBall.y-this.tubeStack.getBottom().y);
        let timeline = this.scene.tweens.timeline({
          onComplete: () => {
              timeline.destroy();
          }
        });
        timeline.add({
          targets: newBall,
          duration: 0,
          completeDelay: 1,
          onComplete: () =>{
            this.tubeStack.index++;
            thisIndex=this.tubeStack.index;
          }
      });
        timeline.add({
            targets: newBall,
            x: this.tubeStack.items[this.tubeStack.index].x,
            y: this.tubeStack.items[this.tubeStack.index].y,
            duration: duration*700,
            onComplete: () =>{
              for(var i=0;i<this.tubeStack.items.length;i++)
              {
                if(this.tubeStack.items[i].ball==null)
                {
                  this.tubeStack.items[i].ball=newBall;
                  break;
                }

              }
             if(thisIndex==this.tubeStack.index)
             {
                this.release.setInteractive();
                this.isAnimating=false;
             }
             
            }
        });
        timeline.play();
      }
      else
      {
        console.log("Tube Overflow");
      }
    }

  }

  class TubeStack{
  
    // Array is used to implement stack
    constructor(xPos,yPos,stackDev)
    {
        this.index=0;
        this.items = [
          {x:xPos,y:yPos+stackDev*6,ball:null},
          {x:xPos,y:yPos+stackDev*5,ball:null},
          {x:xPos,y:yPos+stackDev*4,ball:null},
          {x:xPos,y:yPos+stackDev*3,ball:null},
          {x:xPos,y:yPos+stackDev*2,ball:null},
          {x:xPos,y:yPos+stackDev*1,ball:null},
          {x:xPos,y:yPos,ball:null},
          {x:xPos,y:yPos+stackDev*-1,ball:null},
          {x:xPos,y:yPos+stackDev*-2,ball:null},
          {x:xPos,y:yPos+stackDev*-3,ball:null},
          {x:xPos,y:yPos+stackDev*-4,ball:null},
          {x:xPos,y:yPos+stackDev*-5,ball:null},
          {x:xPos,y:yPos+stackDev*-6,ball:null},
   
      
        
        ];
    }
    getBottom()
    {
      return this.items[0];
    }
    release(scene,releaseButton)
    {
      releaseButton.removeInteractive();
      for(var i=0;i<this.items.length;i++)
      {
        
        if(this.items[i].ball!=null)
        {
          let x=i;
          let duration=(this.items[x].y-scene.game.renderer.height)/(this.items[this.items.length-1].y-scene.game.renderer.height);
          // console.log("duration", duration)
          let timeline = scene.tweens.timeline({
            onComplete: () => {
                timeline.destroy();
            }
          });
          timeline.add({
              targets: this.items[x].ball,
              y: scene.game.renderer.height,
              duration: 1000*duration,
              onComplete: () =>{
                // console.log(x);
                // console.log(this.items[x]);
                this.items[x].ball.destroy();
                this.items[x].ball=null;
              }
          });
          timeline.play();

        }
      }
      this.index=0;
    }


    
}
  