import React, { useState, useEffect } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import "./Profile.scss";
import Header from "../Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCheck,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import {
  linkXolaAccount,
  unlinkXolaAccount,
  getXolaAccount,
} from "../../../common/utils/user";
import SettingsHeader from "./SettingsHeader";
import XolaLogo from "../../../images/xola-logo-black-small.png";

const XolaSettings = (props) => {
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [xolaLoading, setXolaLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [isXolaLinked, setXolaLink] = useState(false);
  const [xolaInfo, setXolaInfo] = useState({});
  const [modalShow, setModalShow] = React.useState(false);

  const home = () => {
    props.history.push("/atc");
  };

  useEffect(() => {
    refreshXolaStatus();
  }, []);

  const refreshXolaStatus = () => {
    setXolaLoading(true);
    getXolaAccount().subscribe((xola) => {
      setXolaLoading(false);
      if (xola.isLinked) {
        setXolaLink(true);
        setXolaInfo(xola.xolaAuth);
      } else {
        setXolaLink(false);
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setMsg("");
    linkXolaAccount(loginForm).subscribe(
      (resp) => {
        console.log(resp)
        setLoading(false);
        setMsg("Your account has been linked!");
        refreshXolaStatus();
      },
      (err) => {
        console.log(err.response)
        setLoading(false);
        setMsg(err.response.message);
      }
    );
  };

  const onChange = (e) => {
    const values = { ...loginForm };
    values[e.target.name] = e.target.value;
    setLoginForm(values);
  };

  const onXolaUnlinkPrompt = (e) => {
    setModalShow(true);
  };

  const onXolaUnlinkConfirm = () => {
    setLoading(true);
    setMsg("");
    unlinkXolaAccount().subscribe(
      () => {
        setLoading(false);
        setModalShow(false);
        setMsg("Your account has been unlinked!");
        refreshXolaStatus();
      },
      (err) => {
        setLoading(false);
        setMsg(err.response.message);
      }
    );
  };

  return (
    <React.Fragment>
      <Header></Header>

      <SettingsHeader history={props.history} selected={2} />
      <div className="signin-section">
        <Card className="signin-card">
          <img className="xola-logo" src={XolaLogo} />
          {!isXolaLinked && !xolaLoading ? (
            <>
              <div className="heading">Link Your Account</div>
              <div className="sub-heading">
                Enter your Xola login details to link your account.
              </div>
              <Form onSubmit={onSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    name="email"
                    onChange={(e) => onChange(e)}
                    type="email"
                    placeholder="Enter your email"
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    required
                    name="password"
                    onChange={(e) => onChange(e)}
                    type="password"
                    placeholder="Enter your password"
                  />
                </Form.Group>
                <Button variant="secondary" type="submit">
                  Link Account{" "}
                  {loading && (
                    <FontAwesomeIcon size="1x" spin={true} icon={faSpinner} />
                  )}
                </Button>
                <p className="text-danger text-center message">{msg}</p>
              </Form>
            </>
          ) : !xolaLoading ? (
            <>
              <div className="sub-heading">Linked email: {xolaInfo.email}</div>
              <div className="xola-linked">
                <FontAwesomeIcon size="1x" icon={faCheck} /> &nbsp;ACCOUNT
                LINKED
              </div>

              <div className="m-auto">
                <Button
                  onClick={onXolaUnlinkPrompt}
                  className="xola-unlink-btn btn-danger"
                >
                  Unlink
                </Button>
              </div>
            </>
          ) : (
            <div className="m-auto">
              <FontAwesomeIcon size="1x" spin={true} icon={faSpinner} />
            </div>
          )}
        </Card>
      </div>
      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Body>
          Are you sure you want to unlink your Xola account?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => onXolaUnlinkConfirm()}>
            Yes
          </Button>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default XolaSettings;
