/* eslint-disable */

import React, { useState } from 'react';

// Styles
import './PlayerJoin.scss';
import { Row, Form, Col, Button } from 'react-bootstrap';

// Routing
import { Link } from 'react-router-dom';

// Image Import
import Logo from '../../images/RemoteAdventures_Logo.png';



const PlayerJoin = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [game, setGame] = useState('');

    return (
        <Row>
            <Col md="6" className="left-side">
                <img className="remote-logo" src={Logo}/>
            </Col>
            <Col md="6" className="right-side">
        <div className="join-box">
            <h1>JOIN YOUR ADVENTURE</h1>
            <Form className="redemption-form">
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Control placeholder="Redemption Code" onChange={(e) => setGame(e.target.value)}/>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Control placeholder="First Name" onChange={(e) => setFirstName(e.target.value)}/>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Control placeholder="Last Name" onChange={(e) => setLastName(e.target.value)}/>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Control type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>
                    </Form.Group>
                </Form.Row>
                <Link onClick={e => (!email || !game || !firstName || !lastName) ? e.preventDefault() : null} to={`/play/${game}`}>
                <Button className="join-button">JOIN</Button>
                </Link>
            </Form>
        </div>
        </Col>
        </Row>

    )
};

export default PlayerJoin;