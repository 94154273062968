/* eslint-disable */
import React, { useState } from 'react'
import Carousel from 'nuka-carousel'
import UiFx from 'uifx'
import Slider from 'react-slick'

// Styles
import './PlayerMultiRowInventoryModule.scss'
import Button from 'react-bootstrap/Button'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// Component
import PlayerInventoryModal from './PlayerInventoryModal/PlayerInventoryModal'
// import InventoryNextButton from './InventoryNextButton/InventoryNextButton';
// import InventoryPrevButton from './InventoryPrevButton/InventoryPrevButton';

// Image Imports
import RuinsPrev from '../../../images/ruins_prev.png'
import RuinsNext from '../../../images/ruins_next.png'
import GRPrev from '../../../images/ruins_prev.png'
import GRNext from '../../../images/ruins_next.png'
import HeistPrev from '../../../images/heist_prev.png'
import HeistNext from '../../../images/heist_next.png'
import PBNext from '../../../images/pb_next.png';
import PBPrev from '../../../images/pb_prev.png';
// Audio Imports
import frogAudio from '../../../audio/frogsfx.wav'
import owlAudio from '../../../audio/owlsfx.wav'
import birdAudio from '../../../audio/birdsfx.wav'

const frogClickAudio = new UiFx(frogAudio, {
  volume: 0.25,
})
const owlClickAudio = new UiFx(owlAudio, {
  volume: 0.25,
})
const birdClickAudio = new UiFx(birdAudio, {
  volume: 0.25,
})

// const data = require('../../../data');
// const inventory = data[0].inventory;

const PlayerMultiRowInventoryModule = ({ currentInventory, inventory = [], theme, title = 'Inventory', type ='base' }) => {
  const [inventoryModalOpen, setInventoryModalOpen] = useState(false)
  const [activeItem, setActiveItem] = useState(null)

  const onClose = () => setActiveItem({})

  const loadNextButton = (theme) => {
    switch (theme) {
      case 'ruins':
        return RuinsNext
        break
      case 'gold-rush':
        return GRNext
        break
      case 'the-heist':
        return HeistNext
        break
      case 'ruins-forbidden-treasure':
        return RuinsNext
        break
      case 'prison-break':
        return PBNext;
        break;
    }
  }

  const loadPrevButton = (theme) => {
    switch (theme) {
      case 'ruins':
        return RuinsPrev
        break
      case 'gold-rush':
        return GRPrev
        break
      case 'the-heist':
        return HeistPrev
        break
      case 'ruins-forbidden-treasure':
        return RuinsPrev
        break
      case 'prison-break':
        return PBPrev;
        break;
    }
  }

  const NextArrow = (props) => {
    const { onClick } = props
    return (
      <Button className={'nextButton'} onClick={onClick}>
        <img className={'nextImage'} src={loadNextButton(theme)} />
      </Button>
    )
  }

  const PrevArrow = (props) => {
    const { onClick } = props
    return (
      <Button className={'prevButton'} onClick={onClick}>
        <img className={'prevImage'} src={loadPrevButton(theme)} />
      </Button>
    )
  }

  const settings = {
    dots: false,
    infinite: false,
    centerPadding: '100px',
    speed: 500,
    slidesToShow: type === 'base'? 6 : 3,
    slidesToScroll: type === 'base'? 6 : 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  return (
    <div className={theme}>
      <div className={type === 'base' ? 'player-multi-row-inventory-container': 'player-inventory-lock-container'}>
        <div className={type==='base' ? 'player-multi-row-inventory-title' : 'player-inventory-lock-title'}>{title}</div>
        <div className='slider-body'>
          {inventory.length &&
            inventory.map(
              (item) =>
                currentInventory.includes(item.id) && (
                  <Button
                    key={item.id}
                    value={item.id}
                    className="player-multi-row-inventory-item-button"
                    onClick={() => {
                      setActiveItem(item);
                      switch (item.displayName) {
                        case "Bird":
                          birdClickAudio.play();
                          break;
                        case "Owl":
                          owlClickAudio.play();
                          break;
                        case "Frog":
                          frogClickAudio.play();
                          break;
                      }
                    }}
                  >
                    <img
                      className="player-inventory-item-image"
                      src={item.data.src}
                    />
                    <p>{item.displayName}</p>
                  </Button>
                )
            )}
        </div>
        {activeItem && (
          <PlayerInventoryModal
            item={activeItem}
            onClose={() => setActiveItem(null)}
          />
        )}
      </div>
    </div>
  );
}

export default PlayerMultiRowInventoryModule
