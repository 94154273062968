import Phaser from "phaser";

export default class Preload extends Phaser.Scene {
  constructor() {
    super({ key: "Preload", active: true });
  }

  init() {}

  preload() {
    //BlockGrid
    this.load.image("bg", "/assets/images/carnival/BlockGrid/background.png");
    this.load.image("grid", "/assets/images/carnival/BlockGrid/Grid.png");
    this.load.image("logo", "/assets/images/carnival/BlockGrid/PG_Logo.png");
    this.load.image("box", "/assets/images/carnival/BlockGrid/White_Box.png");
    this.load.image("block", "/assets/images/carnival/BlockGrid/Block.png");
    this.load.image("crosshair", "/assets/images/carnival/Environment/icons8-hand-cursor-50.png");
  }

  create() {
    this.StartGame();
  }

  StartGame (){
    this.scene.start('BlockGridGameScene'); 
  }

  cleanup() {
    // if (this.music.isPlaying) {
    //   this.music.stop();
    // }
  }
  
}
