/* eslint-disable */

import React, { useState, useEffect, useRef } from "react";

// Packages
import UiFx from "uifx";
import { INITIAL_STATE, TEG_APP } from "../../lib/constants";
import { fetchGameAndSetGameMedia, getCookie, PLAYER_COOKIE_PREFIX, validatePlayer } from "../../lib/data";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Slider from 'react-slick'
import SimpleBar from 'simplebar-react';
import axios from 'axios'
import Viewer from 'react-viewer'

// Styles
import 'react-tabs/style/react-tabs.css'
import 'simplebar/dist/simplebar.min.css';
import "./PlayerDashboard-v2.scss";

// Audio Imports
import inventoryAudio from "../../audio/inventory_sound.mp3";
import clueAudio from "../../audio/clue_sound.wav";
import scanAudio from "../../audio/scan_sound.wav";
import PlayerAuthModule from "../../Components/Play/PlayerAuthModule/PlayerAuthModule";

// Images
import ZoomImage from 'images/remote_adventures_zoom_download.png'

// Rugrats Images
import RugratsPostGameBg from 'images/rugrats-post-game-bg.png'
import RugratsPostGameBuyButton from 'images/rugrats-post-game-buy-button.png'
import RugratsPostGameProductsBg from 'images/rugrats-post-game-products-bg.png'
import RugratsPostGameReviewBox from 'images/rugrats-post-game-review-box.png'
import RugratsPostGameShopAmazon from 'images/rugrats-post-game-shop-amazon.png'
import RugratsPostGameStarEmpty from 'images/rugrats-post-game-star-empty.png'
import RugratsPostGameStarFill from 'images/rugrats-post-game-star-fill.png'
import RugratsPostGameSusie from 'images/rugrats-post-game-susie.png'
import RugratsPostGameHoodie from 'images/rugrats-post-game-hoodie.png'
import RugratsPostGameGroup from 'images/rugrats-post-game-group.png'
import RugratsPostGamePants from 'images/rugrats-post-game-pants.png'
import RugratsPostGameSocks from 'images/rugrats-post-game-socks.png'
import RugratsPostGameSquad from 'images/rugrats-post-game-squad.png'

import getThemeSettings from "Pages/PlayerDashboard/themeSettings";
import mixpanel from "lib/mixpanel"
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { config } from "enviroment.js";
import VideoContainer from 'Pages/PlayerDashboard/VideoContainer.js'


const inventoryUploadAudio = new UiFx(inventoryAudio, {
  volume: 0.25,
});
const clueSendAudio = new UiFx(clueAudio, {
  volume: 0.25,
});
const scanSendAudio = new UiFx(scanAudio, {
  volume: 0.25,
});

const PlayerDashboard = (props) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const gameId = props.match.params.game;
  const playState = props.playState
  const theme = props.theme
  const socket = props.socket
  const [clue, setClue] = useState("");
  const [gameMedia, setGameMedia] = useState(INITIAL_STATE.GAME_MEDIA);
  const [scans, setScans] = useState([]);
  const [sounds, setSounds] = useState([]);
  const [videos, setVideos] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [audio, setAudio] = useState('');
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isAuthLoading, setAuthLoading] = useState(true);
  const [activeAsset, setActiveAsset] = useState(null)
  const [newAssets, setNewAssets] = useState({inventory:[], locks:[], videos:[], scans:[], toys:[]})
  const [isGameActive, setIsGameActive] = useState(null)
  const [activeVideoSrc, setActiveVideoSrc] = useState(null)
  const activeTab = useRef(0)
  const ldClient = useLDClient()
  const themeSettings = getThemeSettings(theme)
  
  const changeActiveAsset = (e) => {
    const assetType = e.currentTarget.parentElement.getAttribute('type')
    const newActiveAssetId = parseInt(e.currentTarget.parentElement.getAttribute('value'))
    const newActiveAsset = {
      id: newActiveAssetId,
      type: assetType,
    }
    switch (assetType) {
      case 'inventory':
        const objInventory = gameMedia.baseInventory.find(o => o.id === newActiveAssetId);
        newActiveAsset.src = objInventory.data.src
        break
      case 'lock':
        const objLock = gameMedia.lockInventory.find(o => o.id === newActiveAssetId);
        newActiveAsset.src = objLock.data.src
        break
      case 'toys':
        const objToys = gameMedia.foundedToys.find(o => o.id === newActiveAssetId);
        newActiveAsset.src = objToys.data.src
        break
      case 'scan':
        const objScan = gameMedia.scans.find(o => o.id === newActiveAssetId);
        newActiveAsset.src = objScan.data.src
        break
      case 'video':
        const objVideo = gameMedia.videos.find(o => o.id === newActiveAssetId);
        newActiveAsset.src = objVideo.data.src
        break
    }
    setActiveAsset(newActiveAsset)
  }

  useEffect(() => {
    if (gameMedia.tracking && ldClient) {
      ldClient.identify({
        key: 'remote-adventures',
        anonymous: true,
        custom: {
          event_id: gameMedia?.tracking?.event_id,
          x_event_id: gameId,
          experience_id: gameMedia?.tracking?.experience_id,
          store_id: gameMedia?.tracking?.store_id,
          theme: gameMedia?.tracking?.theme
        }
      }, null, () => {
        if ('customTheming' in gameMedia) setIsLoading(false)
      })
    }
  }, [gameMedia])

  useEffect(() => {
    socket.on("toggle-post-game", (isGameActive) => {
      setIsGameActive(isGameActive);
    });
    setActiveAsset({
      id: null,
      type: 'default',
      src: themeSettings.gameLogo
    })
  }, []);

  const hydrateFromServer = (stateFromServer) => {
    let state = stateFromServer;
    if (typeof stateFromServer === 'string')
      state = JSON.parse(stateFromServer);

    if (state) {
      if (state.inventory) setInventory(state.inventory);
      if (state.scans) setScans(state.scans);
      if (state.activeClue) setClue(state.activeClue);
      if (state.videos) setVideos(state.videos);
      if(state.sounds) setSounds(state.sounds);
      setIsGameActive(typeof state.isGameActive === 'undefined' ? true : state.isGameActive)
    }
  };

  useEffect(() => {
    hydrateFromServer(playState);
    fetchGameAndSetGameMedia(gameId, setGameMedia);
  }, [playState, gameId]);

  useEffect(() => {
    socket.on("clue", (clue) => {
      setClue(clue);
      clueSendAudio.play();
      console.log(`Clue received from TEG: ${clue}.`);
    });
  }, [clue]);

  useEffect(() => {
    socket.on("scan-add", (scan) => {
      updateBadgeCounts(gameMedia, scan)
      setScans((scans) => [...scans, scan]);
      scanSendAudio.play();
      console.log(`Received scan from TEG: ${scan}.`);
      console.log(scans);
    });

    socket.on("scan-remove", (mediaId) => {
      updateBadgeCounts(gameMedia, mediaId, false)
      setScans((scans) => scans.filter((scan) => scan !== mediaId));
      console.log(`Received scan removal from TEG: ${mediaId}.`);
      console.log(scans);
    });

    return () => {
      socket.off("scan-add")
      socket.off("scan-remove")
    }
  }, [gameMedia]);

  useEffect(() => {
    socket.on("sound-add", (soundItem) => {
      const sound = soundItem.id;
      setAudio(soundItem.data.src);
      const soundElement = document.getElementById('sound-player');
      soundElement.setAttribute('src', soundItem.data.src);
      soundElement.play().then(() => {
      }).catch(() => {
        if (confirm("Click ok to play sounds!")) {
          soundElement.play()
        } else {
          soundElement.play()
        }
      });
      setSounds((sounds) => [...sounds, sound]);
      console.log(`Received sound from TEG: ${sound}.`);
      console.log(sounds);
    });

    socket.on("sound-remove", (mediaId) => {
      setSounds((sounds) => sounds.filter((sound) => sound !== mediaId));
      console.log(`Received sound removal from TEG: ${mediaId}.`);
      console.log(sounds);
      const soundElement = document.getElementById('sound-player');
      soundElement.pause();
    });
  }, []);

  useEffect(() => {
    socket.on("video-add", (video) => {
      const objVideo = gameMedia.videos.find(o => o.id === video);
      setActiveVideoSrc(objVideo.data.src)
    })
    socket.on("video-remove", (mediaId) => {
      const objVideo = gameMedia.videos.find(o => o.id === mediaId)
      if (activeVideoSrc === objVideo.data.src) setActiveVideoSrc(null)
    })
    return () => {
      socket.off("video-add")
      socket.off("video-remove")
    }
  }, [gameMedia, activeVideoSrc])

  useEffect(() => {
    socket.on("inventory-add", (item) => {
      updateBadgeCounts(gameMedia, item)
      setInventory((inventory) => [...inventory, item]);
      inventoryUploadAudio.play();
      console.log(`Received inventory from TEG: ${item}.`);
    });

    socket.on("inventory-remove", (inventoryId) => {
      updateBadgeCounts(gameMedia, inventoryId, false)

      // change viewer to game logo if current asset in view
      // is removed from dashboard by host
      if (['inventory', 'lock'].includes(activeAsset.type) && activeAsset.id === inventoryId) {
        setActiveAsset({
          id: null,
          type: 'default',
          src: themeSettings.gameLogo
        })
      }

      setInventory((inventory) =>
        inventory.filter((item) => item !== inventoryId)
      );
      console.log(`Received scan removal from TEG: ${inventoryId}.`);
    });

    return () => {
      socket.off("inventory-add")
      socket.off("inventory-remove")
    }
  }, [gameMedia, activeAsset]);

  useEffect(() => {
    checkAuthFromCookies()
  }, [])

  function updateBadgeCounts(gameMedia, assetId, add=true) {
    const objInventory = gameMedia.baseInventory.find(o => o.id === parseInt(assetId))
    if (objInventory) {
      if (activeTab.current !== 0) {
        setNewAssets(prev => {
          const index = prev.inventory.findIndex(x => x === assetId)
          if (index === -1) { if (add) { prev.inventory.push(assetId) } }
          else { if (!add) { prev.inventory.splice(index) } }
          return prev
        })
      }
    } else {
      const objLock = gameMedia.lockInventory.find(o => o.id === assetId)
      if (objLock) {
        if (activeTab.current !== 1) {
          setNewAssets(prev => {
            const index = prev.locks.findIndex(x => x === assetId)
            if (index === -1) { if (add) { prev.locks.push(assetId) } }
            else { if (!add) { prev.locks.splice(index) } }
            return prev
          })
        }
      } else {
        const objScan = gameMedia.scans.find(o => o.id === assetId)
        if (objScan) {
          if (activeTab.current !== 2) {
            setNewAssets(prev => {
              const index = prev.scans.findIndex(x => x === assetId)
              if (index === -1) { if (add) { prev.scans.push(assetId) } }
              else { if (!add) { prev.scans.splice(index) } }
              return prev
            })
          }
        } else {
          const objToy = gameMedia.foundedToys.find(o => o.id === assetId)
          if (objToy) {
            if (activeTab.current !== 3) {
              setNewAssets(prev => {
                const index = prev.toys.findIndex(x => x === assetId)
                if (index === -1) { if (add) { prev.toys.push(assetId) } }
                else { if (!add) { prev.toys.splice(index) } }
                return prev
              })
            }
          } else {
            if (activeTab.current !== 2) {
              setNewAssets(prev => {
                const index = prev.videos.findIndex(x => x === assetId)
                if (index === -1) { if (add) { prev.videos.push(assetId) } }
                else { if (!add) { prev.videos.splice(index) } }
                return prev
              })
            }
          }
        }
      }
    }
  }

  const checkAuthFromCookies = async () => {
    const [authGameId, authGamePass] = getCookie(`${PLAYER_COOKIE_PREFIX}_auth`) ? getCookie(`${PLAYER_COOKIE_PREFIX}_auth`).split('_') : [undefined, undefined];
    const host_token = getCookie(`host_token`);
    if((authGameId && (gameId === authGameId))) {
      const gameRes = await validatePlayer(authGameId, authGamePass, host_token);
      if(gameRes.success) {
        setAuthenticated(true);
      }
    } else if (host_token && host_token !== '') {
      const hostLogin = await validatePlayer(gameId, '', host_token);
      if(hostLogin.success) {
        setAuthenticated(true);
      }
    }
    setAuthLoading(false);
  }

  if (isAuthLoading || isLoading) {
    return (
      <div className="loading">
        <div className="lds-dual-ring"></div>
      </div>
    );
  }

  if(!isAuthenticated) {
    return <PlayerAuthModule gameId={gameId} setAuthenticated={setAuthenticated} theme={theme}/>
  }

  const wrapperStyles = (themeSettings) => {
    let bgImageAttributes = []
    if (themeSettings.backgroundImageGradient) bgImageAttributes.push(themeSettings.backgroundImageGradient)
    if (themeSettings.backgroundImage) bgImageAttributes.push(`url(${themeSettings.backgroundImage})`)
    const backgroundImage = bgImageAttributes.join(', ')
    return {
      backgroundImage: backgroundImage,
    }
  }

  function changeActiveTab(tabIndex) {
    if (tabIndex !== activeTab.current) {
      activeTab.current = tabIndex
      if (tabIndex === 0) setNewAssets(prev => { return { ...prev, inventory: [] } })
      if (tabIndex === 1) setNewAssets(prev => { return { ...prev, locks: [] } })
      if (tabIndex === 2) setNewAssets(prev => { return { ...prev, scans: [], videos: [] } })
      if (tabIndex === 3) setNewAssets(prev => { return { ...prev, toys: [] } })
    }
  }

  const onClickZoom = (e) => {
    mixpanel.track('Zoom Background Download', gameMedia.tracking)
  }

  const assetProps = {
    theme,
    themeSettings,
    gameMedia,
    activeAssetIds: inventory,
    videos,
    scans,
    changeActiveAsset,
    activeTab,
    setNewAssets,
    newAssets,
    changeActiveTab,
  }

  return (
    <div className="player-dashboard-v2">
      {activeVideoSrc &&
        <VideoContainer activeVideoSrc={activeVideoSrc} />
      }
      <div>
        <audio id='sound-player' controls={false} loop={false} style={{'display':"none"}}>
          <source src={audio}/>
        </audio>
      </div>
      <div id="dash-container" className={theme} style={wrapperStyles(themeSettings)}>
        <div className="wrapper">
          <themeSettings.header onClickZoom={onClickZoom} playState={playState} socket={socket} tracking={gameMedia.tracking} theme={theme} />
          <AssetViewer themeSettings={themeSettings} activeAsset={activeAsset} theme={theme} />
          <a className="zoomDownloadLink mobile" href={themeSettings.zoomBackgrounds} onClick={onClickZoom}>
            <img src={ZoomImage}/>
            <span>{themeSettings.zoomBackgroundText}</span>
          </a>
          <Assets {...assetProps}/>
          {themeSettings.footer && <themeSettings.footer />}
        </div>
        {isGameActive === false &&
          <PostGame gameId={gameId} />
        }
      </div>
    </div>
  );
};

export default PlayerDashboard;

const PostGame = (props) => {
  const [reviewState, setReviewState] = useState(() => {
    let state = 'pre-rating'
    const rating = localStorage.getItem('rating-' + props.gameId)
    if (rating) {
      rating === '5' ? state = 'five-star-rating' : state = 'low-rating'
    }
    return state
  })

  const submitRating = async (evt) => {
    const rating = evt.target.getAttribute('rating')
    const customerId = getCookie('escp_player_customer_id')
    rating === '5' ? setReviewState('five-star-rating') : setReviewState('low-rating')
    localStorage.setItem('rating-' + props.gameId, rating)
    const response = await axios.post(`${config.raUrl}/game/rating/`, {
        customerId: customerId,
        rating: rating,
        rating_type: '5-Star'
      },
    )
  }

  return (
    <div id="post-game">
      <div className="post-game-content">
        <img className="post-game-bg" src={RugratsPostGameBg} />
        <div className="products">
          <div className="products-bg">
            <img src={RugratsPostGameProductsBg} />
          </div>
          <div className="product">
            <img className="tshirt" src="https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/rugrats/products/rugrats-reptar-and-friends-tshirt.png" />
            <p>Rugrats Reptar & Friends T-shirt</p>
            <a target="_blank" href="https://www.amazon.com/dp/B07RXX2MY2">
              <img src={RugratsPostGameBuyButton} />
              <span>$19.95 - BUY NOW</span>
            </a>
          </div>
          <div className="product">
            <img className="tshirt" src="https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/rugrats/products/i-escaped-tshirt.png" />
            <p>Rugrats I Escaped T-shirt</p>
            <a target="_blank" href="https://www.amazon.com/dp/B096YXKXHW">
              <img src={RugratsPostGameBuyButton} />
              <span>$21.99 - BUY NOW</span>
            </a>
          </div>
          <div className="product">
            <img className="tshirt" src="https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/rugrats/products/rugrats-susie-portrait-tshirt.png" />
            <p>Rugrats Susie Portrait T-shirt</p>
            <a target="_blank" href="https://www.amazon.com/dp/B07MH3245X">
              <img src={RugratsPostGameBuyButton} />
              <span>$19.95 - BUY NOW</span>
            </a>
          </div>
          <div className="product">
            <img className="hat" src="https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/rugrats/products/angelica.png" />
            <p>Funko Pop! Rugrats - Angelica</p>
            <a target="_blank" href="https://www.amazon.com/Funko-Pop-Television-Rugrats-Angelica/dp/B09DL7LLKG">
              <img src={RugratsPostGameBuyButton} />
              <span>$11.99 - BUY NOW</span>
            </a>
          </div>
          <div className="product">
            <img className="tshirt" src="https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/rugrats/products/reptar-fire-breathing-hoodie.png" />
            <p>Rugrats Fire Breathing Reptar Hoodie</p>
            <a target="_blank" href="https://www.amazon.com/dp/B07MNX6JGK">
              <img src={RugratsPostGameBuyButton} />
              <span>$36.55 - BUY NOW</span>
            </a>
          </div>
          <div className="product">
            <img className="hat" src="https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/rugrats/products/susie.png" />
            <p>Funko Pop! Rugrats - Susie</p>
            <a target="_blank" href="https://www.amazon.com/Funko-Pop-Television-Rugrats-Susie/dp/B09DL5W1XQ">
              <img src={RugratsPostGameBuyButton} />
              <span>$11.99 - BUY NOW</span>
            </a>
          </div>
        </div>
        <a className="shop-amazon" target="_blank" href="https://www.amazon.com/stores/page/8726675D-724D-4516-BF5F-99300D30C764?channel=EscapeRoomShop">
          <img src={RugratsPostGameShopAmazon} />
          <span>SHOP RUGRATS GEAR ON AMAZON!</span>
        </a>
        <div className={`review ${reviewState}`}>
          <img className="review-box" src={RugratsPostGameReviewBox} />
          <div className="review-content">
            {reviewState === 'pre-rating' &&
              <>
              <div className="stars">
                <div className="empty">
                  <img src={RugratsPostGameStarEmpty} />
                  <img src={RugratsPostGameStarEmpty} />
                  <img src={RugratsPostGameStarEmpty} />
                  <img src={RugratsPostGameStarEmpty} />
                  <img src={RugratsPostGameStarEmpty} />
                </div>
                <div className="fill">
                  <img rating="5" src={RugratsPostGameStarFill} onClick={submitRating}/>
                  <img rating="4" src={RugratsPostGameStarFill} onClick={submitRating}/>
                  <img rating="3" src={RugratsPostGameStarFill} onClick={submitRating}/>
                  <img rating="2" src={RugratsPostGameStarFill} onClick={submitRating}/>
                  <img rating="1" src={RugratsPostGameStarFill} onClick={submitRating}/>
                </div>
              </div>
              <div className="text">HOW WAS YOUR GAME?</div>
              </>
            }
            {reviewState === 'low-rating' &&
              <div className="text-low-rating">THANKS!</div>
            }
            {reviewState === 'five-star-rating' &&
              <>
              <div className="text-five-star-rating">THANKS!</div>
              <a className="google-link" target="_blank" href="https://g.page/TEG_Remote_Adventures/review?gm">CLICK HERE TO LEAVE US A REVIEW ON GOOGLE!</a>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const AssetViewer = (props) => {
  const { themeSettings, activeAsset, theme } = props
  const { viewerColor } = themeSettings
  useEffect(() => {
    setZoomDownloadLinkDisplay()
    setMediaViewerWidth()
    window.addEventListener('resize', function() {
      setZoomDownloadLinkDisplay()
      setMediaViewerWidth()
    })
  }, [])
  return (
    <div id="viewer" style={{borderColor:viewerColor}}>
      {activeAsset.type === 'default' &&
        <div className="img-wrapper">
          <img src={activeAsset.src} />
        </div>
      }
      {activeAsset && ['inventory', 'toys', 'lock'].includes(activeAsset.type) &&
        <Viewer
          visible={true}
          images={[{src:`${activeAsset.src}`, alt:''}]}
          container={document.getElementById("viewer")}
          rotatable={false}
          noClose={true}
          noImgDetails={true}
          noNavbar={true}
          changeable={false}
          showTotal={false}
          scalable={false}
          defaultScale={1.15}
          zoomSpeed={.2}
          minScale={.5}
        />
      }
      {activeAsset && activeAsset.type === 'scan' &&
        <iframe src={activeAsset.src}></iframe>
      }
      {activeAsset && activeAsset.type === 'video' &&
        <iframe src={activeAsset.src}></iframe>
      }
    </div>
  )
}

const Assets = (props) => {
  const {
    theme, themeSettings, gameMedia, activeAssetIds, videos, scans,
    changeActiveAsset, activeTab, newAssets, changeActiveTab
  } = props
  const [wrapperWidth, setWrapperWidth] = useState(1000)

  let tabStyling = themeSettings.tabStyles(gameMedia, wrapperWidth)

  useEffect(() => {
    const createNewWrapperWidth = () => {
      const wrapper = document.querySelector('.wrapper')
      const newWrapperWidth = wrapper ? wrapper.offsetWidth : 1000
      setWrapperWidth(newWrapperWidth)
    }
    createNewWrapperWidth()
    window.addEventListener('resize', createNewWrapperWidth)
    return () => window.removeEventListener("resize", createNewWrapperWidth)
  }, [])

  const PrevArrow = (props) => {
    const {onClick, className} = props
    const isDisabled = className.includes('slick-disabled') ? ' isDisabled' : ''
    return (
      <div className={`prevAsset${isDisabled}`} onClick={onClick}>
        <img src={themeSettings.prevButtonImage}/>
      </div>
    )
  }
  const NextArrow = (props) => {
    const {onClick, className} = props
    const isDisabled = className.includes('slick-disabled') ? ' isDisabled' : ''
    return (
      <div className={`nextAsset${isDisabled}`} onClick={onClick}>
        <img src={themeSettings.nextButtonImage}/>
      </div>
    )
  }
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      }
    ]
  }

  return (
    <div id="assets">
      {/* tab documentation linked below */}
      {/* https://github.com/reactjs/react-tabs */}
      <Tabs defaultIndex={activeTab.current} onSelect={changeActiveTab}>

        <TabList>
          {gameMedia.baseInventory.length > 0 &&
            themeSettings.tab({
              classes: "inventory-tab tab",
              tabStyling: tabStyling.inventory,
              icon: themeSettings.iconInventory,
              newAssetCount: newAssets.inventory.length,
              label: themeSettings.tabInventoryLabel
            })
          }
          {gameMedia.lockInventory.length > 0 &&
            themeSettings.tab({
              classes: "locks-tab tab",
              tabStyling: tabStyling.locks,
              icon: themeSettings.iconLocks,
              newAssetCount: newAssets.locks.length,
              label: themeSettings.tabLocksLabel
            })
          }
          {(gameMedia.scans.length + gameMedia.videos.length) > 0 &&
            themeSettings.tab({
              classes: "views-tab tab",
              tabStyling: tabStyling.views,
              icon: themeSettings.iconViews,
              newAssetCount: newAssets.scans.length + newAssets.videos.length,
              label: themeSettings.tabViewsLabel
            })
          }
          {theme === "rugrats" &&
            themeSettings.tab({
              classes: "founded-tab tab",
              tabStyling: tabStyling.founded,
              icon: themeSettings.iconFounded,
              newAssetCount: newAssets.toys.length,
              label: "Founded Toys"
            })
          }
        </TabList>

        {gameMedia.baseInventory.length > 0 &&
          <TabPanel>
            <div className="inventory-panel panel slider">
              <Slider {...sliderSettings}>
                {gameMedia.baseInventory.length && gameMedia.baseInventory.map((asset) =>
                  activeAssetIds.includes(asset.id) && (
                    <div key={asset.id} value={asset.id} type="inventory" className="inventory-asset asset">
                      <div className="asset-wrapper" onClick={changeActiveAsset}>
                        <div className="asset-img-wrapper">
                          <img src={asset.data.src} />
                        </div>
                        <p>{asset.displayName}</p>
                      </div>
                    </div>
                  )
                )}
              </Slider>
            </div>
            <SimpleBar className="inventory-panel panel scroll">
                {gameMedia.baseInventory.length && gameMedia.baseInventory.map((asset) =>
                  activeAssetIds.includes(asset.id) && (
                    <div key={asset.id} value={asset.id} type="inventory" className="inventory-asset asset">
                      <div className="asset-wrapper" onClick={changeActiveAsset}>
                        <div className="asset-img-wrapper">
                          <img src={asset.data.src} />
                        </div>
                        <p>{asset.displayName}</p>
                      </div>
                    </div>
                  )
                )}
            </SimpleBar>
          </TabPanel>
        }
        {gameMedia.lockInventory.length > 0 &&
          <TabPanel>
            <div className="locks-panel panel slider">
              <Slider {...sliderSettings}>
                {gameMedia.lockInventory.length && gameMedia.lockInventory.map((asset) =>
                  activeAssetIds.includes(asset.id) && (
                    <div key={asset.id} value={asset.id} type="lock" className="lock-asset asset">
                      <div className="asset-wrapper" onClick={changeActiveAsset}>
                        <div className="asset-img-wrapper">
                          <img src={asset.data.src} />
                        </div>
                        <p>{asset.displayName}</p>
                      </div>
                    </div>
                  )
                )}
              </Slider>
            </div>
            <SimpleBar className="locks-panel panel scroll">
              {gameMedia.lockInventory.length && gameMedia.lockInventory.map((asset) =>
                activeAssetIds.includes(asset.id) && (
                  <div key={asset.id} value={asset.id} type="lock" className="lock-asset asset">
                      <div className="asset-wrapper" onClick={changeActiveAsset}>
                        <div className="asset-img-wrapper">
                          <img src={asset.data.src} />
                        </div>
                        <p>{asset.displayName}</p>
                      </div>
                  </div>
                )
              )}
            </SimpleBar>
          </TabPanel>
        }
        {(gameMedia.scans.length + gameMedia.videos.length) > 0 &&
          <TabPanel>
            <div className="views-panel panel slider">
              <Slider {...sliderSettings}>
                {gameMedia.videos && gameMedia.videos.map((video) =>
                  videos.includes(video.id) && (
                    <div key={video.id} value={video.id} type="video" className="views-asset asset">
                      <div className="asset-wrapper" onClick={changeActiveAsset} style={{backgroundImage:`linear-gradient(rgba(0,0,0,.25), rgba(0,0,0,.5)), url(${video.data.thumbnail})`}}>
                        {video.data.name.toUpperCase()}
                      </div>
                    </div>
                  )
                )}
                {gameMedia.scans && gameMedia.scans.map((scan) =>
                  scans.includes(scan.id) && (
                    <div key={scan.id} value={scan.id} type="scan" className="views-asset asset">
                      <div className="asset-wrapper" onClick={changeActiveAsset} style={{backgroundImage:`linear-gradient(rgba(0,0,0,.25), rgba(0,0,0,.5)), url(${scan.data.thumbnail})`}}>
                        {scan.data.name.toUpperCase()}
                      </div>
                    </div>
                  )
                )}
              </Slider>
            </div>
            <SimpleBar className="views-panel panel scroll">
              {gameMedia.videos && gameMedia.videos.map((video) =>
                videos.includes(video.id) && (
                  <div key={video.id} value={video.id} type="video" className="views-asset asset">
                      <div className="asset-wrapper" onClick={changeActiveAsset} style={{backgroundImage:`linear-gradient(rgba(0,0,0,.25), rgba(0,0,0,.5)), url(${video.data.thumbnail})`}}>
                        {video.data.name.toUpperCase()}
                      </div>
                  </div>
                )
              )}
              {gameMedia.scans && gameMedia.scans.map((scan) =>
                scans.includes(scan.id) && (
                  <div key={scan.id} value={scan.id} type="scan" className="views-asset asset">
                      <div className="asset-wrapper" onClick={changeActiveAsset} style={{backgroundImage:`linear-gradient(rgba(0,0,0,.25), rgba(0,0,0,.5)), url(${scan.data.thumbnail})`}}>
                        {scan.data.name.toUpperCase()}
                      </div>
                  </div>
                )
              )}
            </SimpleBar>
          </TabPanel>
        }
        {theme === "rugrats" &&
          <TabPanel>
            <div className="founded-panel panel slider">
              <Slider {...sliderSettings}>
                {gameMedia.foundedToys.length && gameMedia.foundedToys.map((asset) =>
                  activeAssetIds.includes(asset.id) ? (
                    <div key={asset.id} value={asset.id} type="toys" className="toys-asset asset">
                      <div className="asset-wrapper" onClick={changeActiveAsset}>
                        <div className="asset-img-wrapper">
                          <img src={asset.data.src} />
                        </div>
                        <p>{asset.displayName}</p>
                      </div>
                    </div>
                  ) : (
                    <div key={asset.id} value={asset.id} type="toys" className="toys-asset asset">
                      <div className="asset-wrapper" onClick={changeActiveAsset}>
                        <div className="asset-img-wrapper">
                          <img src={asset.data.src.replace('.png', '-Lost.png')} />
                        </div>
                        <p>{asset.displayName}</p>
                      </div>
                    </div>
                  )
                )}
              </Slider>
            </div>
            <SimpleBar className="founded-panel panel scroll">
              {gameMedia.foundedToys.length && gameMedia.foundedToys.map((asset) =>
                activeAssetIds.includes(asset.id) ? (
                  <div key={asset.id} value={asset.id} type="toys" className="toys-asset asset">
                    <div className="asset-wrapper" onClick={changeActiveAsset}>
                      <div className="asset-img-wrapper">
                        <img src={asset.data.src} />
                      </div>
                      <p>{asset.displayName}</p>
                    </div>
                  </div>
                ) : (
                  <div key={asset.id} value={asset.id} type="toys" className="toys-asset asset">
                    <div className="asset-wrapper" onClick={changeActiveAsset}>
                      <div className="asset-img-wrapper">
                        <img src={asset.data.src.replace('.png', '-Lost.png')} />
                      </div>
                      <p>{asset.displayName}</p>
                    </div>
                  </div>
                )
              )}
            </SimpleBar>
          </TabPanel>
        }

      </Tabs>
    </div>
  )
}

function setMediaViewerWidth() {
  // sets media viewer width so that entire viewer is within view
  // goal to eliminate need to scroll to see entire viewer
  const wrapper = document.querySelector('#dash-container .wrapper')
  const header = document.getElementById('header');
  const assets = document.getElementById('assets');
  const footer = document.getElementById('footer');
  const zoomDownloadLinkMobile = document.querySelector('.zoomDownloadLink.mobile')
  const headerHeight = header.offsetHeight
  const footerHeight = footer ? footer.offsetHeight : 0
  const assetsPaddingTop = parseInt(window.getComputedStyle(assets).paddingTop.slice(0, -2))
  const assetsPaddingBottom = parseInt(window.getComputedStyle(assets).paddingBottom.slice(0, -2))
  const assetsMinHeight = 190 + assetsPaddingTop + assetsPaddingBottom
  const wrapperXPadding = 15
  const zoomDownloadLinkMobileHeight = zoomDownloadLinkMobile.offsetHeight
  const viewerBorderWidth = parseInt(window.getComputedStyle(viewer).borderWidth.slice(0, -2))
  const viewerMaxHeight = window.innerHeight - headerHeight - footerHeight - assetsMinHeight - zoomDownloadLinkMobileHeight - (viewerBorderWidth * 2)
  const viewerMaxWidth = window.innerWidth - (wrapperXPadding * 2)
  const viewerMinWidth = Math.min(viewerMaxWidth, 500)
  const viewerHeightBasedOnMaxWidth = viewerMaxWidth * .5625
  const newViewerWidth = viewerHeightBasedOnMaxWidth > viewerMaxHeight ? viewerMaxHeight / .5625 : viewerMaxWidth
  const newAssetsHeight = window.innerHeight - (headerHeight + footerHeight + (newViewerWidth * .5625) + zoomDownloadLinkMobileHeight + (viewerBorderWidth * 2))
  wrapper.style.maxWidth = Math.max(newViewerWidth, viewerMinWidth) + 'px'
  assets.style.height = Math.max(assetsMinHeight, newAssetsHeight) + 'px'
}

function setZoomDownloadLinkDisplay() {
  // toggle display of mobile and desktop zoom download links
  const wrapper = document.querySelector('.wrapper')
  const zoomDownloadLinkDesktop = document.querySelector('.zoomDownloadLink.desktop')
  const zoomDownloadLinkMobile = document.querySelector('.zoomDownloadLink.mobile')
  if (wrapper.offsetWidth <= 570) {
    zoomDownloadLinkDesktop.style.display = 'none'
    zoomDownloadLinkMobile.style.display = 'flex'
  } else {
    zoomDownloadLinkDesktop.style.display = 'block'
    zoomDownloadLinkMobile.style.display = 'none'
  }
}
