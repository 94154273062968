import {LIST_BOOKINGS, BOOKING_SUCCESS, BOOKING_ERROR, UPDATE_BOOKING} from "../actions";

const initialState = {
  bookings: [],
  total: 0,
  unassignedCount: 0,
  isLoading: false,
  error: null
};

export function bookingReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_BOOKINGS:
      return {
        bookings: [],
        total: 0,
        unassignedCount: 0,
        isLoading: true,
        error: null
      };
    case UPDATE_BOOKING:
      const updatedBooking = state.bookings;
      updatedBooking[action.payload.index] = action.payload.event;
      return {
        bookings: updatedBooking,
        total: state.total,
        unassignedCount: state.unassignedCount,
        isLoading: false,
        error: null
      };
    case BOOKING_SUCCESS:
      return {
        bookings: action.payload.events || state.bookings,
        total: action.payload.total || state.total,
        unassignedCount: action.payload.unassignedCount || state.unassignedCount,
        isLoading: false,
        error: null
      };
    case BOOKING_ERROR:
      return {
        bookings: [],
        total: 0,
        unassignedCount: 0,
        isLoading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}
