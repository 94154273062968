import React, { useEffect, useState } from "react";
import styled from 'styled-components'
import { formatTimer, getInitialTimerState } from 'Components/Timer/utilsTimer.js'
import { Button } from 'react-bootstrap'


const GuideTimer = (props) => {
	const { socket, timerEvents } = props
	const [isTimerPaused, setIsTimerPaused] = useState(true)
	const [timerReady, setTimerReady] = useState(false)
	const [timeRemaining, setTimeRemaining] = useState()
	
	useEffect(() => {
		const [newTimeRemaining, timerState] = getInitialTimerState(timerEvents || [])
		setTimeRemaining(newTimeRemaining)
		setIsTimerPaused(timerState)
		if (timerEvents) {
			setTimerReady(true)
		}
	}, [timerEvents])
	
	useEffect(() => {
		const timerInterval = setInterval(() => {
			if (!isTimerPaused) setTimeRemaining(timeRemaining => Math.max(0, timeRemaining - 1))
		}, 1000);
		return () => clearInterval(timerInterval)
	}, [isTimerPaused])

	const updateTimer = (e) => {
		const time = e.target.getAttribute('time')
		const change = e.target.getAttribute('change')
		let timeChange = time === "minutes"
		? (change === "up" ? 60 : (timeRemaining < 60 ? timeRemaining * -1 : -60))
		: (change === "up" ? 1 : (timeRemaining !== 0 ? -1 : 0))
		if (timeChange !== 0) {
			const data = {event: "timerUpdated", change: timeChange}
			socket.emit('toggle-timer-state', data, () => {
				setTimeRemaining(timeRemaining => Math.max(0, timeRemaining + timeChange))
			})
		}
	}

  const toggleTimerState = () => {
		const event = isTimerPaused ? "start" : "pause"
		const data = {event: event, timestamp: Date.now()}
    socket.emit('toggle-timer-state', data, () => {
			setIsTimerPaused(!isTimerPaused)
    })
  }

  return (
    <TimerContainer>
			<div className="arrows minutes">
				<Button variant="light" time="minutes" change="up" className="arrow up" onClick={updateTimer}></Button>
				<Button variant="light" time="minutes" change="down" className="arrow down" onClick={updateTimer}></Button>
			</div>
			<div className="test"></div>
			<span className="timer">
				{timerReady && formatTimer(timeRemaining)}
			</span>
			<div className="arrows seconds">
				<Button variant="light" time="seconds" change="up" className="arrow up" onClick={updateTimer}></Button>
				<Button variant="light" time="seconds" change="down" className="arrow down" onClick={updateTimer}></Button>
			</div>
			<Button variant={isTimerPaused ? "success" : "danger"} style={{marginLeft:'20px', width:'58px'}} size="sm" onClick={toggleTimerState}>
				{isTimerPaused ? "Start" : "Pause"}
			</Button>
    </TimerContainer>
  )
}

export default GuideTimer

const TimerContainer = styled.div`
	align-items: center;
  background: #fff;
	border-radius: 4px;
	display: flex;
	font-size: 24px;
	height: 44px;
	margin-right: 20px;
	padding: 4px 12px;
	.timer {
		min-width: 65px;
		text-align: center;
	}
	.arrows {
		&.minutes {
			margin-right: 8px;
		}
		&.seconds {
			margin-left: 8px;
		}
	}
	.arrow {
		border-color: #dae0e5;
		display: flex;
		&:focus {
			box-shadow: unset;
		}
		&.up {
			margin-bottom: 4px;
			padding: 2px 6px 3px;
			&:after {
				display: inline-block;
				vertical-align: .255em;
				content: "";
				border-bottom: .3em solid;
				border-right: .3em solid transparent;
				border-top: 0;
				border-left: .3em solid transparent;
			}
		}
		&.down {
			margin-top: 4px;
			padding: 3px 6px 2px;
			&:after {
				display: inline-block;
				vertical-align: .255em;
				content: "";
				border-top: .3em solid;
				border-right: .3em solid transparent;
				border-bottom: 0;
				border-left: .3em solid transparent;
			}
		}
	}
`
