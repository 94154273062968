import React, {useState, useEffect} from "react";

// Styles
import "./ProctorAndGamble.scss";
import {Col, Container, Row} from "react-bootstrap";

// Image Imports
import lockLogo from "../../../../images/LockLogo_Horizontal_White.png";
import TSLogo from "../../../../images/TruthSeekers_Logo.png";
import TSBackground from '../../../../images/TruthSeekers_Background.png';
import TSPrimeLogo from '../../../../images/TruthSeekers_PrimeLogo.png';
import TopLogos from '../../../../images/truth-seeker-top-logos.png';

// Component Imports
import PlayerClueModule from "../../../../Components/Play/PlayerClueModule/PlayerClueModule";
import PlayerMediaModule from "../../../../Components/Play/PlayerMediaModule/PlayerMediaModule";
import PlayerInventoryModule from "../../../../Components/Play/PlayerInventoryModule/PlayerInventoryModule";
// import PlayerZoomDownload from "../../../../Components/Play/PlayerZoomDownload/PlayerZoomDownload";
import PlayerMultiRowInventoryModule from "../../PlayerMultiRowInventoryModule/PlayerMultiRowInventoryModule";


const ProctorAndGamble = (props) => {
  useEffect(() => {
    document.body.style.backgroundColor = "#0441A8";
  }, [])
  return (
    <div className={props.theme}>
      <div className="player-wrapper">
        <Container fluid>
          <Row className="player-logo-section">
            <div className='logo-container'>
              <img
                alt="Logo for The Escape Game"
                className="player-logos-1"
                src={lockLogo}
              />
            </div>
          </Row>
          <Row>
            <Col md="12">
              <PlayerMediaModule
                scans={props.scans}
                socket={props.socket}
                videos={props.videos}
                theme={props.theme}
                gameMedia={props.gameMedia}
              />
              <br/>
              <Row>
                {props.gameMedia.lockInventory && props.gameMedia.lockInventory.length ? <Col lg={6} md={6} sm={12}  className='order-md-1 order-sm-2 order-2'>
                  <PlayerInventoryModule
                    currentInventory={props.inventory}
                    inventory={props.gameMedia.lockInventory}
                    socket={props.socket}
                    theme={props.theme}
                    type={'lock'}
                    title={'LOCKS'}
                  />
                </Col> : ''
                }
                <Col md={props.gameMedia.lockInventory.length ? 6 : 12} className='order-md-2 order-sm-1 order-1'>
                  {/* <PlayerZoomDownload conferenceApp="MICROSOFT TEAMS" theme={props.theme}/> */}
                </Col>
              </Row>
              <Row className="no-show">
                <Col md="7">
                  <PlayerClueModule clue={props.clue} socket={props.socket} game={props.game}/>
                </Col>
                <Col md="5">
                  <Row>
                    <h1>Time</h1>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="my-50">
            <Col md={12}>
              <PlayerInventoryModule
                currentInventory={props.inventory}
                inventory={props.gameMedia.baseInventory}
                socket={props.socket}
                theme={props.theme}
                type={'base'}
                title={'Inventory'}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ProctorAndGamble;
