import Phaser from "phaser";
import Quadrant from "./Quadrant";
import { Vector2 } from "three";
import * as BlockGridSocket from "../blockGridSocket";
export default class BlockGrid extends Phaser.Scene {
    constructor() {
        super({ key: 'BlockGridGameScene', active: false});
        console.log("GameScene constructor");
    }

    create() {
        let scene=this;
        this.canvas = this.sys.canvas;
        this.canvas.style.cursor = 'none';
        this.crossHair = this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, "crosshair");
        this.crossHair.setDepth(5); 
        this.input.on('pointermove', function (pointer) {
            scene.crossHair.x = pointer.x;
            scene.crossHair.y = pointer.y;
        });

        this.quad2Init={x: this.game.renderer.width*0.503, y: this.game.renderer.height*0.117};
        this.quadDev=85;

        this.bg = this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, "bg");
        this.gridImg=this.add.image(this.game.renderer.width * 0.717, this.game.renderer.height / 2, "grid");
        this.logo=this.add.image(this.game.renderer.width * 0.218, this.game.renderer.height * 0.148, "logo");
        this.box=this.add.image(this.game.renderer.width * 0.219, this.game.renderer.height * 0.459, "box");

        this.quad1=new Quadrant(this,this.game.renderer.width*0.757,this.game.renderer.height*0.117,"block",5,0);
        this.quad2=new Quadrant(this,this.game.renderer.width*0.503,this.game.renderer.height*0.117,"block",5,1);
        this.quad3=new Quadrant(this,this.game.renderer.width*0.503,this.game.renderer.height*0.569,"block",5,2);
        this.quad4=new Quadrant(this,this.game.renderer.width*0.757,this.game.renderer.height*0.569,"block",5,3);

        this.quads=[this.quad1,this.quad2,this.quad3,this.quad4];

        this.alreadyRenderedCursors = [];
        this.alreadyRenderedNames = [];
        this.updateCursor();

        // get user state after every 500 ms
        BlockGridSocket.getUpdatedState(this);
        BlockGridSocket.ListenForBlockStateChange(this)

    }

    updateCursor = () => {
        this.cursorInterval = setInterval(() => {
          const cursorX = this.game.input.activePointer.x;
          const cursorY = this.game.input.activePointer.y;
    
          BlockGridSocket.sendCursorUpdate(new Vector2(cursorX, cursorY));
        }, 400);
    };

    updateVisibleCursors = (data) => {
        const scene = this
        let otherUsers = data.usersList.filter((user) => user.id !== BlockGridSocket.getSocetId())
    
        // delete previously rendered cursors and user names
        while(scene.alreadyRenderedCursors.length > 0) {
          scene.alreadyRenderedCursors.pop().destroy(true);
          scene.alreadyRenderedNames.pop().destroy(true);
        } 
    
        otherUsers.forEach(function(user) {
          let xPos = user?.cursors?.x;
          let yPos = user?.cursors?.y;
          let name = user.name ? user.name : "user";
    
          if(xPos && yPos){
            let cursorImage = scene.add.sprite(xPos, yPos, 'crosshair')
            cursorImage.setDepth(5)
            let cursorText = scene.add.text(xPos + 40,yPos + 40, name, { fontFamily: "Arial", fontSize: 25, color: "red" })
            cursorText.setDepth(5)
    
            scene.alreadyRenderedCursors.push(cursorImage)
            scene.alreadyRenderedNames.push(cursorText)
          }
        })
    }

    onBlockClickedByAnotherUser = (quadId, blockId, state) => {
        if (state) {
            this.quads[quadId].quad[blockId].alpha=0.0001;
            this.quads[quadId].quad[blockId].isActive=false;
        } else {
            this.quads[quadId].quad[blockId].alpha=1;
            this.quads[quadId].quad[blockId].isActive=true;
        }
    };

    update() {

    }

    cleanup() {
        clearInterval(this.cursorInterval)
      }



}