import thunk from "redux-thunk"
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './epics';
import userReducer from "./reducers/userReducer";
import {bookingReducer} from "./reducers/bookingReducer";
import {storeReducer} from "./reducers/storeReducer";
import {roomReducer} from "./reducers/roomReducer";
const reducers = combineReducers({
    userReducer, bookingReducer, storeReducer, roomReducer
});
const epicMiddleware = createEpicMiddleware();

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducers,
    composeEnhancer(applyMiddleware(thunk, epicMiddleware)),
);

epicMiddleware.run(rootEpic);

export default store;
