import Phaser from "phaser";

export default class Preload extends Phaser.Scene {
  constructor() {
    super({ key: "Preload", active: true });
  }

  init() {}

  preload() {
    console.log("preload preload");

    //Loading Environment Assets
    this.load.image("bg", "/assets/images/carnival/Environment/Background.png");
    this.load.image("hill", "/assets/images/carnival/Environment/Hill.png");
    this.load.image("scoreBoard", "/assets/images/carnival/Environment/Scoreboard.png");
    this.load.image("crosshair", "/assets/images/carnival/Environment/Reticle.png");

    //Start Scene Assets
    this.load.video('timer', '/assets/video/Timer.mp4', 'loadeddata', false, true);
    this.load.image("hit_targets", "/assets/images/carnival/Hit_The_Targets.png");

    //End Scene Assets
    this.load.image("ending_scoreBoard", "/assets/images/carnival/Environment/EndScene/Scoreboard.png");
    this.load.image("ending_bg", "/assets/images/carnival/Environment/EndScene/Background.png");

    //music
    this.load.audio('music', [
      '/assets/audio/Carnival_Shooter_Music.ogg',
      '/assets/audio/Carnival_Shooter_Music.mp3'
    ]);
    
    //Loading Animal Assets

    //Deer
    this.load.image("deer_right_front", "/assets/images/carnival/Animals/Deer_Front_Right.png");
    this.load.image("deer_right_back", "/assets/images/carnival/Animals/Deer_Back_Left.png");
    this.load.image("deer_left_front", "/assets/images/carnival/Animals/Deer_Front_Left.png");
    this.load.image("deer_left_back", "/assets/images/carnival/Animals/Deer_Back_Right.png");
    this.load.image("deer_left_back_numbered", "/assets/images/carnival/Animals/Deer_Back_R_0.png");
    this.load.image("deer_right_back_numbered", "/assets/images/carnival/Animals/Deer_Back_L_0.png");

    //Ram
    this.load.image("ram_right_front", "/assets/images/carnival/Animals/Ram_Front_Right.png");
    this.load.image("ram_right_back", "/assets/images/carnival/Animals/Ram_Back_Left.png");
    this.load.image("ram_left_front", "/assets/images/carnival/Animals/Ram_Front_Left.png");
    this.load.image("ram_left_back", "/assets/images/carnival/Animals/Ram_Back_RIght.png");
    this.load.image("ram_left_back_numbered", "/assets/images/carnival/Animals/Ram_Back_R_3.png");
    this.load.image("ram_right_back_numbered", "/assets/images/carnival/Animals/Ram_Back_L_3.png");

    //Turkey
    this.load.image("turkey_right_front", "/assets/images/carnival/Animals/Turkey_Front_Right.png");
    this.load.image("turkey_right_back", "/assets/images/carnival/Animals/Turkey_Back_Left.png");
    this.load.image("turkey_left_front", "/assets/images/carnival/Animals/Turkey_Front_Left.png");
    this.load.image("turkey_left_back", "/assets/images/carnival/Animals/Turkey_Back_Right.png");
    this.load.image("turkey_right_back_numbered", "/assets/images/carnival/Animals/Turkey_Back_L_4.png");
    this.load.image("turkey_left_back_numbered", "/assets/images/carnival/Animals/Turkey_Back_R_4.png");

    //Moose
    this.load.image("moose_right_front", "/assets/images/carnival/Animals/Moose_Front_Right.png");
    this.load.image("moose_right_back", "/assets/images/carnival/Animals/Moose_Back_Left.png");
    this.load.image("moose_left_front", "/assets/images/carnival/Animals/Moose_Front_Left.png");
    this.load.image("moose_left_back", "/assets/images/carnival/Animals/Moose_Back_Right.png");
    this.load.image("moose_left_back_numbered", "/assets/images/carnival/Animals/Moose_Back_L_7.png");
    this.load.image("moose_left_back_numbered", "/assets/images/carnival/Animals/Moose_Back_R_7.png");

    this.load.image("cat", "/assets/images/carnival/cat.png");
    this.load.image("dog", "/assets/images/carnival/dog.png");
    this.load.image("fish", "/assets/images/carnival/fish.png");
    this.load.image("lion", "/assets/images/carnival/lion.png");
    this.load.image("flip", "/assets/images/carnival/white.png");

    //assets
    this.load.image("muzzle", "/assets/images/carnival/muzzle.png");

    //sfx
    this.load.audio('fire', [
      '/assets/audio/Gun_Fire.ogg',
      '/assets/audio/Gun_Fire.mp3'
    ]);
    this.load.audio('hit', [
      '/assets/audio/Animal_Hit.ogg',
      '/assets/audio/Animal_Hit.mp3'
    ]);

  }

  create() {
    this.vid = this.add.video(this.game.renderer.width / 2, this.game.renderer.height / 2, 'timer');
    this.vid.displayWidth = this.sys.canvas.width;
    this.vid.displayHeight = this.sys.canvas.height;
    this.vid.play();
    this.timerStarted = false;
    this.music = this.sound.add('music');
    this.music.setLoop(true);

    
  }

  update(){
    if(!this.vid.isPlaying() && !this.timerStarted){
      if(!this.music.isPlaying)
      {
        console.log("HEREE");
        this.music.play();
      }
      this.hitTargets = this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, "hit_targets");
      this.hitTargets.displayWidth = this.sys.canvas.width;
      this.hitTargets.displayHeight = this.sys.canvas.height;
      console.log(this.time.events);
      this.timedEvent = this.time.delayedCall(700, this.StartGame, [], this);
      this.timerStarted = true;
    }
  }

  StartGame (){
    this.scene.start('GameScene'); 
  }

  cleanup() {
    if (this.music.isPlaying) {
      this.music.stop();
    }
  }
  
}
