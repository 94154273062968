import React, { useEffect, useState } from "react"
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import styled from 'styled-components'
import mixpanel from "lib/mixpanel"


const ClueButton = (props) => {
	const { playState, socket, tracking, theme } = props
	const [isClueRequested, setIsClueRequested] = useState(playState.isClueRequested || false)
	const clueIconWhite = "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/clue.png"
	const clueIconRugrats = "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/clue-rugrats.png"
	const clueIcon = theme === "rugrats" ? clueIconRugrats : clueIconWhite

  useEffect(() => {
    socket.on("clue-requested", () => {
      setIsClueRequested(true)
    });
    socket.on("clear-clue-request", () => {
      setIsClueRequested(false)
    });
    return () => {
      socket.off("clue-requested")
      socket.off("clear-clue-request")
    }
  }, [socket]);
  
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {isClueRequested ? "Clue requested!" : "Ask for a clue!"}
    </Tooltip>
  )

  const requestClue = () => {
    if (!isClueRequested) {
			const mixpanelTracking = {
				...tracking
			}
			mixpanel.track('Clue Request', mixpanelTracking)
      socket.emit('request-clue', () => {
        setIsClueRequested(true)
      })
    }
  }

	return (
		<OverlayTrigger
			placement="bottom"
			delay={{ show: 100, hide: 400 }}
			overlay={renderTooltip}
		>
			<StyledClueButton className={isClueRequested ? "requested" : ""} onClick={requestClue} theme={theme}>
				<img alt="Ask for a clue!" src={clueIcon}/>
			</StyledClueButton>
		</OverlayTrigger>
	)
}

export default ClueButton

const StyledClueButton = styled.div`
	background: ${props => props.theme === "rugrats" ? "#60259e" : "linear-gradient(45deg, #f30222, #431650)"};
	${props => props.theme === "rugrats" && "border: 2px solid #edc02a;"}
	border-radius: 50%;
	cursor: pointer;
	height: 45px;
	transition: background-color .5s;
	width: 45px;
	&.requested {
		background: #c3c3c3;
		cursor: default;
	}
	img {
		height: 100%;
		object-fit: contain;
		padding: ${props => props.theme === "rugrats" ? "7px !important" : "9px !important"};
		width: 100%;
	}
`
