import React, { useEffect, useState } from "react";
import styled from 'styled-components'
import * as minigames from 'Components/MiniGames'
import Viewer from 'react-viewer'

export default function MiniGameContainer(props) {
	const { activeMiniGame, gameId } = props
	const MiniGameComponent = minigames[activeMiniGame]
	
	return (
		<StyledMiniGameContainer>
			<div className="minigame-wrapper">
				<div className="iframe-content">
					<div className="iframe-scaler">
						<MiniGameComponent gameId={gameId}/>
					</div>
				</div>
				{activeMiniGame === 'BallSort' &&
					<BallSortSupportingContent />
				}
				{activeMiniGame === 'BlockGrid' &&
					<BlockGridSupportingContent />
				}
			</div>
		</StyledMiniGameContainer>
	)
}

const StyledMiniGameContainer = styled.div`
	background: rgba(11, 11, 11, .8);
	height: 100%;
	left: 0;
	overflow: auto;
	padding: 20px 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1050;
	.minigame-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 auto;
		max-width: 900px;
		min-height: 100%;
		width: 95%;
		z-index: 1045;
	}
	.iframe-content {
		width: 100%;
		position: relative;
		display: inline-block;
		vertical-align: middle;
		margin: 0 auto;
		text-align: left;
	}
	.iframe-scaler {
		height: 0;
		overflow: hidden;
		padding-top: 56.25%;
		width: 100%;
	}
	.supporting-content {
		background: #b1f0ff;
		border-radius: 4px;
		display: flex;
		flex-wrap: wrap;
		margin-top: 20px;
		padding: 20px;
		width: 100%;
		.inventory-asset {
			cursor: pointer;
			padding: 15px;
			width: 25%;
			&:hover img {
				transform: scale(1.05);
			}
		}
		.asset-img-wrapper {
			padding: 10px;
			background: rgba(0, 0, 0, .3);
			border-radius: 4px;
		}
		img {
			transition: transform .2s;
			width: 100%;
		}
		p.item-name {
			text-align: center;
			font-size: .875rem;
			margin: 8px 0 0;
		}
	}
	iframe, #minigame_container {
		background: #000;
		border-width: 0;
		box-shadow: 0 0 8px rgba(0, 0, 0, .6);
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
	.react-viewer {
		height: auto;
	}
`

const BallSortSupportingContent = () => {
	const [activeImage, setActiveImage] =useState()
	const inventory = [
		{
			src: 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/playground/nbh/Inventory_Flags.png',
			name: "Flags in Classroom",
		},
		{
			src: 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/playground/nbh/Inventory_Caterpillar.png',
			name: "Caterpillar in Classroom",
		},
		{
			src: 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/playground/nbh/Inventory_Crayons.png',
			name: "Crayons in Classroom",
		},
		{
			src: 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/playground/nbh/Inventory_CountingisFun.png',
			name: "Counting is Fun",
		},
		{
			src: 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/playground/nbh/Inventory_CoachPoster.png',
			name: "Summer Kick-off Poster",
		},
		{
			src: 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/playground/nbh/Inventory_InLocker.png',
			name: "Locker Interior",
		},
		{
			src: 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/playground/nbh/Inventory_OnPlayset.png',
			name: "Playset Dots",
		},
	]

	const viewImage = (e) => {
		const supportingView = document.getElementById('supporting-viewer')
		supportingView.style.minHeight = supportingView.offsetHeight + 'px'
		setActiveImage(e.target.getAttribute('src'))
	}

	return (
		<div id="supporting-viewer" className="supporting-content">
			{activeImage
				? (
        <Viewer
          visible={true}
          images={[{src:`${activeImage}`, alt:''}]}
          container={document.getElementById("supporting-viewer")}
          rotatable={false}
					onClose={() => { setActiveImage(null); } }
          noImgDetails={true}
          noNavbar={true}
          changeable={false}
          showTotal={false}
          scalable={false}
          defaultScale={1.15}
          zoomSpeed={.2}
          minScale={.5}
        />
				) : (
					inventory.map((item, idx) => (
						<div key={idx} className="inventory-asset asset">
							<div className="asset-img-wrapper" onClick={viewImage}>
								<img src={item.src} alt="Ball Toss Inventory Item"/>
							</div>
							<p className="item-name">{item.name}</p>
						</div>
					))
				)
			}
		</div>
	)
}


const BlockGridSupportingContent = () => {
	const [activeImage, setActiveImage] =useState()

	const mathTestImage = 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/playground/nbh/Inventory_MathTestAnswerKey.png'
	
	useEffect(() => {
		setActiveImage(mathTestImage)
	}, [])

	return (
		<div id="supporting-viewerx" className="supporting-content">
			<Viewer
			visible={true}
			images={[{src:`${activeImage}`, alt:''}]}
			container={document.getElementById("supporting-viewerx")}
			rotatable={false}
			noClose={true}
			noImgDetails={true}
			noNavbar={true}
			changeable={false}
			showTotal={false}
			scalable={false}
			defaultScale={1.15}
			zoomSpeed={.2}
			minScale={.5}
			/>
		</div>
	)
}