import React from "react"

import "./Login.scss"
import OMITBLoginForm from "./LoginForm"

export default function OMITBLogin({
  isPasswordCorrect,
  playerLoginPassword,
  formData,
  setFormData,
  onFormUpdate,
  isSubmitted,
  isValid,
  onSubmit,
  isLoading,
  countries,
}) {
  return (
    <div id="omitb-login-root">
      <div className="above-the-fold">
        <div className="login-content">
          <img className="hulu-teg-logo" src="https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/client/omitb/hulu-teg-logo.png" alt="Hulu x The Escape Game" />
          <div className="left-and-right">
            <div className="left">
              <div className="login-form">
                <img className="logo" src="https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/client/omitb/omitb-logo.png" alt="Only Murders in the Building Escape Game" />
                <OMITBLoginForm
                  isPasswordCorrect={isPasswordCorrect}
                  playerLoginPassword={playerLoginPassword}
                  formData={formData}
                  setFormData={setFormData}
                  onFormUpdate={onFormUpdate}
                  isSubmitted={isSubmitted}
                  isValid={isValid}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  countries={countries}
                />
              </div>
            </div>
            <div className="right">
            </div>
          </div>
        </div>
        <div className="banner">
          <div className="text">
            <div>SEASON 1-2 STREAMING NOW</div>
            <div>NEW SEASON AUG 8</div>
          </div>
          <a className="btn btn-primary" href="https://www.hulu.com/series/only-murders-in-the-building-ef31c7e1-cd0f-4e07-848d-1cbfedb50ddf" target="_blank" rel="noopener noreferrer">WATCH NOW</a>
        </div>
      </div>
      <div className="footer">
        <div className="footer-container">
          <img className="hulu-teg-logo" src="https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/client/omitb/hulu-teg-logo.png" alt="Hulu x The Escape Game" />
          <div className="links">
            <div className="left">
              <a href="https://onlymurdersescapegame.com/" target="_blank" rel="noopener noreferrer">About Only Murders In The Building Escape Game</a>
            </div>
            <div className="right">
              <a href="https://theescapegame.com/blog/what-is-an-escape-room/" target="_blank" rel="noopener noreferrer">What is The Escape Game?</a>
              <a href="https://theescapegame.com/faq/" target="_blank" rel="noopener noreferrer">FAQ</a>
              <div className="terms">
                <a href="https://theescapegame.com/terms/" target="_blank" rel="noopener noreferrer">Terms of Service</a>
                <span> • </span>
                <a href="https://theescapegame.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}