import {
  LOGIN_USER,
  USER_SUCCESS,
  USER_ERROR,
  SIGNUP,
  GET_USER,
  SET_USER,
  GET_USERS,
  SET_COUNTRIES,
  GET_COUNTRIES,
  COUNTRIES_ERROR
} from "../actions/index";

export const loginUser = (payload) => ({
  type: LOGIN_USER,
  payload: payload
});

export const getUser = (payload) => ({
  type: GET_USER,
  payload: payload
});

export const userSuccess = (payload) => ({
  type: USER_SUCCESS,
  payload: payload
});

export const userError = (payload) => ({
  type: USER_ERROR,
  payload: payload
});


export const setUser = (payload) => ({
  type: SET_USER,
  payload: payload
});

export const getCountries = (payload) => ({
  type: GET_COUNTRIES,
  payload: payload
})

export const setCountries = (payload) => ({
  type: SET_COUNTRIES,
  payload: payload
})

export const countriesError = (payload) => ({
  type: COUNTRIES_ERROR,
  payload: payload
});
