import React from "react";


const FooterMBS = () => {
  const logo = "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/mbs/dashboard/footer-logo.png"
  return (
    <div id="footer">
      <img className="logo" alt="Disney" src={logo} />
    </div>
  )
}

export default FooterMBS


