import Phaser from "phaser";
import * as CarnivalSocket from "../websockets/carnival";

export default class Animal extends Phaser.GameObjects.Sprite {
  constructor(scene, x, y, picture, picture_back, movement, number, speed, depth, id, name) {
    super(scene, x, y, picture);
    this.id = id;
    this.name = name;
    this.speed = speed;
    this.animalImage = picture;
    this.picture = scene.add.image(x, y, picture);
    this.pictureBack = picture_back;
    this.picture.setSize(this.picture.width, this.picture.height);
    this.picture.setDepth(depth);
    // this.picture.setInteractive();
    // this.picture.setOrigin(0.5, 0.5);

    this.globalWidth = 1920;

    this.movement = movement;
    this.scene = scene;
    this.number = number;
    this.flipped = false;
    this.dissapeared = false;

    let currentSprite = this;

    this.picture.on("pointerdown", ()=> {
      if(!scene.isFireCooldown)
      {
        currentSprite.movement = 0;
        currentSprite.picture.disableInteractive();
        currentSprite.RotatePicture();
        scene.hitSound.play();
  
        // emit animal killing event
        CarnivalSocket.addTargetShot(currentSprite.id, currentSprite.name, currentSprite.number);
      }
    });
  }

  showKillingAnimation() {
    this.movement = 0;
    this.picture.disableInteractive();
    this.RotatePicture();
  }
  
  MoveAnimal() {
    if (this.movement === 1) {
      this.picture.x += this.speed;
      if (this.picture.x > this.globalWidth + 200) {
        this.picture.x = -this.scene.intervalNum*this.speed;
      }
    } else if (this.movement === -1) {
      this.picture.x -= this.speed;
      if (this.picture.x < -200) {
        this.picture.x = this.globalWidth + this.scene.intervalNum*this.speed;
      }
    } else if (
      this.movement === 0 &&
      this.flipped === true && !this.dissapeared
    ) {
      this.ReducePictureVisibility();
    }
  }

  ReducePictureVisibility() {
    this.picture.alpha -= 0.007;
    if(this.picture.alpha < 0){
      this.dissapeared = true;
    }
  }

  RotatePicture() {
    var timeline = this.scene.tweens.createTimeline();
        
    timeline.add({
        targets     : this.picture ,
        scaleX       : 0,
        ease        : 'Power1',
        onComplete: function () {this.picture.setTexture(this.pictureBack);},
        callbackScope: this,
        duration    : 250,
      });

      timeline.add({
        targets     : this.picture,
        scaleX       : 1,
        ease        : 'Power1',
        callbackScope: this,
        duration    : 250,
      });

      timeline.add({
        targets     : this.picture ,
        scaleX       : 0,
        ease        : 'Power1',
        onComplete: function () {this.picture.setTexture(this.animalImage);},
        callbackScope: this,
        duration    : 250,
      });

      timeline.add({
        targets     : this.picture,
        scaleX       : 1,
        ease        : 'Power1',
        callbackScope: this,
        duration    : 250,
      });

      timeline.add({
        targets     : this.picture ,
        scaleX       : 0,
        ease        : 'Power1',
        onComplete: function () {this.picture.setTexture(this.pictureBack);},
        callbackScope: this,
        duration    : 250,
      });

      timeline.add({
        targets     : this.picture,
        scaleX       : 1,
        ease        : 'Power1',
        onComplete: function () {this.flipped = true; if(this.number!== 0){this.scene.add.text(this.picture.x,this.picture.y,this.number)}},
        callbackScope: this,
        duration    : 250,
      });

      timeline.play();  
  }
  Start(){
    this.picture.setInteractive();
  }
}
