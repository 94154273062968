/* eslint-disable */
import React, {useEffect, useState} from 'react'

import {
    Container,
    Row,
    Col,
    Button,
} from "react-bootstrap";
import BeforeGame from "./BeforeGame"
import AfterGame from "./AfterGame"
import DuringGame from "./DuringGame"
import axios from 'axios'
import { config } from "enviroment.js"
import './GuideSideBar.scss'
import { faTimes, faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const ESGSideBar = (props) => {
    const { pushPanelToTop, setOpenPanel, gameId, initialESGFormData, openPanelESG, starttime } = props
    const [activeForm, setActiveForm] = useState(1) 
    const [ESGFormData, setESGFormData] = useState({})
    const [selectOptions, setSelectOptions] = useState({})
    const [companies, setCompanies] = useState([])
    const [users, setUsers] = useState([])
    const [setupErrorCount, setSetupErrorCount] = useState(0)
    const [roomEntriesCount, setRoomEntriesCount] = useState(0)
    const [memorableMomentCount, setMemorableMomentCount] = useState(0)
    const [wrongCount, setWrongCount] = useState(0)
	const [actualStartTime, setActualStartTime] = useState()

    useEffect(() => {
        if (initialESGFormData) setESGFormData(initialESGFormData)
    }, [initialESGFormData])
    
    useEffect(() => {
		axios.get(config.raUrl + `/esg/getSelectOptions`)
			.then((res) => {
                setSelectOptions(res.data)
			}).catch((err) => {
                console.log("error caught: ", err)
			})
		axios.get(config.raUrl + `/esg/getCompanies`)
			.then((res) => {
                setCompanies(res.data)
			}).catch((err) => {
                console.log("error caught: ", err)
			})
		axios.get(config.raUrl + `/esg/getUsers`)
			.then((res) => {
                setUsers(res.data)
			}).catch((err) => {
                console.log("error caught: ", err)
			})
		axios.get(config.raUrl + `/esg/${gameId}/getActivities`)
			.then((res) => {
                if (res.data['1']) setSetupErrorCount(res.data['1'].length)
                if (res.data['3']) setRoomEntriesCount(res.data['3'].length)
                if (res.data['12']) setMemorableMomentCount(res.data['12'].length)
                if (res.data['13']) setWrongCount(res.data['13'].length)
			}).catch((err) => {
                console.log("error caught: ", err)
			})
    }, [])
   
  return (
    // <div className={pushPanelToTop ? "sidebar-custom top-fix" : "sidebar-custom top-mar"}
    <div className="sidebar-custom top-fix"
        style={openPanelESG ? {} : {transform: 'translateX(100%)'}}
    >
        <Container fluid>
        <Row className="cancel-btn" onClick={() => setOpenPanel(false)}>
            <FontAwesomeIcon icon={faTimes} />
        </Row>

        <Row className="heading panel-row">
            ESG
        </Row>

        <Row className="btn-row">
            <Col>
                <Button className={activeForm === 1 ? "game-state-btn-selected" : "game-state-btn"} onClick={() => setActiveForm(1)} variant="light">
                    Before Game
                </Button>
            </Col>

            <Col>
                <Button className={activeForm === 2 ? "game-state-btn-selected" : "game-state-btn"} onClick={() => setActiveForm(2)} variant="light">
                    During Game
                </Button>
            </Col>

            <Col className="panel-last-btn">
                <Button className={activeForm === 3 ? "game-state-btn-selected" : "game-state-btn"} onClick={() => setActiveForm(3)} variant="light">
                    After Game
                </Button> 
            </Col>
        </Row>

        {activeForm !== 0 && (
            <Row className="form-section">
                <Col>
                    {activeForm === 1 && (
                        <BeforeGame 
                            ESGFormData={ESGFormData}
                            setESGFormData={setESGFormData}
                            gameId={gameId}
                            selectOptions={selectOptions}
                            companies={companies}
                            setCompanies={setCompanies}
                            starttime={starttime}
                            users={users}
                            actualStartTime={actualStartTime}
                            setActualStartTime={setActualStartTime}
                        />
                    )}
                    {activeForm === 2 && (
                        <DuringGame
                            ESGFormData={ESGFormData}
                            setESGFormData={setESGFormData}
                            gameId={gameId}
                            selectOptions={selectOptions}
                            setupErrorCount={setupErrorCount}
                            setSetupErrorCount={setSetupErrorCount}
                            roomEntriesCount={roomEntriesCount}
                            setRoomEntriesCount={setRoomEntriesCount}
                            memorableMomentCount={memorableMomentCount}
                            setMemorableMomentCount={setMemorableMomentCount}
                            wrongCount={wrongCount}
                            setWrongCount={setWrongCount}
                        />
                    )}
                    {activeForm === 3 && (
                        <AfterGame
                            ESGFormData={ESGFormData}
                            setESGFormData={setESGFormData}
                            gameId={gameId}
                            selectOptions={selectOptions}
                            actualStartTime={actualStartTime}
                        />
                    )}
                </Col>
            </Row>
        )}
       
    </Container>
    </div>
  );
}

export default ESGSideBar
