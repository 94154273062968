import React from "react";
import styled from 'styled-components'
import ProductContainer from 'Pages/PlayerDashboard/PostGame/Products/ProductContainer.js'
import mixpanel from "lib/mixpanel"


const Locations = (props) => {
	const imageLocationsMap = "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/prison-break/post-game/locations-map.png"
	const imageLocationsPin = "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/prison-break/post-game/locations-pin.png"
	const link = "https://theescapegame.com/"
	
	const clickEvent = (e) => {
		e.preventDefault()
		const mixpanelTracking = {
			...props.tracking,
			"advertisement_copy": e.currentTarget.parentNode.querySelector('.mixpanel-copy').innerText,
			"advertisement_cta_copy": e.currentTarget.innerText,
			"advertisement_cta_link": e.currentTarget.getAttribute('href'),
			"advertisement_image": e.currentTarget.parentNode.querySelector('.mixpanel-image').getAttribute('src'),
			"advertisement_product": "TEG"
		}
		mixpanel.track('Advertisement Click', mixpanelTracking)
		window.location.href = link
	}

	return (
		<ProductContainer>
			<Text className="mixpanel-copy">The Escape Game has 20 locations, nationwide!</Text>
			<ImageLocationsMap className="mixpanel-image" src={imageLocationsMap} />
			<ImageLocationsPin src={imageLocationsPin} />
			<Button href={link} className="mixpanel-cta" onClick={clickEvent}>Find a location</Button>
		</ProductContainer>
	)
}

export default Locations


const ImageLocationsMap = styled.img`
	width: 100%;
`
const ImageLocationsPin = styled.img`
	margin: 10px 0 30px;
	max-width: 70px;
	width: 100%;
`
const Text = styled.p`
	color: #000;
	font-size: 24px;
	line-height: 1.2;
	margin: 0 0 30px;
	max-width: 200px;
	width: 100%;
`
const Button = styled.a`
	background: linear-gradient(45deg, #f30222, #431650);
	border-radius: 9999px;
	display: block;
	color: #fff;
	cursor: pointer;
	font-size: 24px;
	padding: 8px 12px;
	transition: opacity .2s;
	&:hover {
		color: #fff;
		opacity: .75;
		text-decoration: none;
	}
`
