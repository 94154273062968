import { combineEpics } from 'redux-observable';
import {
  loginUserEpic,
  getUserEpic,
  getCountriesEpic
} from './userEpics';

import {
  listBookingEpic,
  assignBookingEpic,
  resendInviteEpic
} from './bookingEpics';

import {
  listStoreEpic
} from './storeEpics';

import {
  listRoomEpic
} from './roomEpics';

export const rootEpic = combineEpics(loginUserEpic, getUserEpic, listBookingEpic, listStoreEpic, listRoomEpic, assignBookingEpic, resendInviteEpic, getCountriesEpic);
