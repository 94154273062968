import React from 'react'
import mixpanel from "lib/mixpanel"

import './PlayerZoomDownload.scss'

// Image imports
import ZoomImage from '../../../images/remote_adventures_zoom_download.png'
import DepthsZoomImage from '../../../images/depths_zoom_bg_icon.png'

const PlayerZoomDownload = ({ theme, conferenceApp, tracking }) => {
  let downloadLink
  let gameName

  switch (theme) {
    case "ruins":
    case "ruins-forbidden-treasure":
      downloadLink =
        "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/downloads/ruins_zoom_backgrounds.zip";
      gameName = "RUINS: FORBIDDEN TREASURE";
      break;
    case "gold-rush":
      downloadLink =
        "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/downloads/goldrush_zoom_backgrounds.zip";
      gameName = "GOLD RUSH";
      break;
    case "the-heist":
      downloadLink =
        "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/downloads/heist_zoom_backgrounds.zip";
      gameName = "THE HEIST";
      break;
    case "prison-break":
      downloadLink = 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/downloads/prisonbreak_zoom_backgrounds.zip'
      gameName= 'PRISON BREAK'
      break;
    case "proctor-and-gamble":
      downloadLink = 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/downloads/prisonbreak_zoom_backgrounds.zip'
      gameName= ''
      break;
    case "depths":
      downloadLink = 'https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/downloads/depths_zoom_backgrounds.zip'
      gameName= 'THE DEPTHS'
      break;
    default:
      break;
  }

  const onClickZoom = (e) => {
    mixpanel.track('Virtual Background Download', tracking)
  }

  return (
    <a className={`${theme} download-link`} href={downloadLink} onClick={onClickZoom}>
      {theme === "depths" ? (
        <img className="image-icon" src={DepthsZoomImage} alt="Download Background Icon" />
      ) : (
        <img className="image-icon" src={ZoomImage} alt="Download Background Icon" />
      )}
      DOWNLOAD CUSTOM {gameName} {conferenceApp} BACKGROUNDS
    </a>
  )
}

export default PlayerZoomDownload
