import React, {useState, useEffect} from 'react';
import {Navbar, Dropdown} from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import './Header.scss';
import {useDispatch, useSelector} from "react-redux";
import { getUser } from '../../../redux/actionCreators';
import {removeCookie} from '../../../common/utils/auth';

const Header = (props) => {
  const userState = useSelector(state => state.userReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser({
      onComplete: () => { },
      onError: () => {
        props.history.push('/login');
      }
    }))
  }, []);

  const settings = () => {
    props.history.push('/settings');
  }
  const logout = () => {
    removeCookie();
    props.history.push('/login');
  }
  const home = () => {
    props.history.push('/atc');
  }
  return (<div>
    <Navbar bg="dark" variant="dark">
      <Navbar.Brand onClick={() => home()}>Remote Adventures</Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Dropdown  alignRight>
          <Dropdown.Toggle variant="info" id="dropdown-basic">
            {userState.user && userState.user.firstName}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => settings()}>Settings</Dropdown.Item>
            <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar.Collapse>
    </Navbar>
  </div>)
}
export default withRouter(Header);
