import {
  LIST_STORES,
  STORE_SUCCESS,
  STORE_ERROR
} from "../actions/index";

export const listStores = (payload) => ({
  type: LIST_STORES,
  payload: payload
});

export const storeSuccess = (payload) => ({
  type: STORE_SUCCESS,
  payload: payload
});

export const storeError = (payload) => ({
  type: STORE_ERROR,
  payload: payload
});
