import {
  FORGOT_PASSWORD_SUCCESS,
  GET_USER,
  LOGIN_USER, SET_USER, SIGNUP, USER_ERROR, USER_SUCCESS, SET_COUNTRIES
} from '../actions';

const initialState = {
  user: null,
  isLoading: false,
  isLoggedIn: false,
  error: null
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      return {
        user: null,
        isLoading: true,
        isLoggedIn: false,
        error: null
      };
    case SIGNUP:
      return {
        user: null,
        consultants: [],
        isLoading: true,
        isLoggedIn: false,
        forgotPasswordEmailSent: false,
        passwordChanged: false,
        error: null
      };
    case GET_USER:
      return {
        user: state.user,
        isLoading: false,
        isLoggedIn: true,
        error: null
      };
    case USER_SUCCESS:
      return {
        user: action.payload.user || state.user,
        isLoading: false,
        isLoggedIn: true,
        error: null
      };
    case SET_USER:
      return {
        user: action.payload.user,
        isLoading: false,
        isLoggedIn: false,
        error: null
      };
    case USER_ERROR:
      return {
        user: null,
        isLoading: false,
        isLoggedIn: false,
        error: action.payload.msg
      };
     case SET_COUNTRIES:
      return {
        countries: action.payload.countries
      };
    default:
      return state;
  }
}
