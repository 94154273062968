/* eslint-disable */
import React, {useState} from 'react'

import {
    Container,
    Row,
    Col,
    Button,
} from "react-bootstrap";
import NumericInput from 'react-numeric-input'
import 'rc-time-picker/assets/index.css';
import useWindowSize from "../../../utils/useWindowSize";

// Styles
import './GuideSideBar.scss';
import SetupError from './SubForms/SetupError';
import RoomEntry from './SubForms/RoomEntry';
import MemorableMoment from './SubForms/MemorableMoment';
import SomethingWentWrong from './SubForms/SomethingWentWrong';
import axios from 'axios';
import { config } from "enviroment.js";

const DuringGame = (props) => {
    const {
        ESGFormData,
        setESGFormData,
        gameId,
        selectOptions,
        setupErrorCount,
        setSetupErrorCount,
        roomEntriesCount,
        setRoomEntriesCount,
        memorableMomentCount,
        setMemorableMomentCount,
        wrongCount,
        setWrongCount
    } = props
    const [showLoading, setShowLoading] = useState(false)
    const size = useWindowSize();
    // SubFormType
    // 0 for none
    // 1 for Setup Error
    // 3 for Room Entries
    // 12 for Memorable Moment
    // 13 for Something Went Wrong
    const [subFormType, setSubFormType] = useState(0) 

    const handleFormSubmit = async (formData) => {
        formData['x_event_id'] = gameId
		const response = await axios.post(config.raUrl + `/esg/updateDuringForm`, formData)
    }

    const updateCluesOrHints = async (value, valueAsString, input) => {
        const data = {
            [input.getAttribute('id')]: value
        }
        axios.post(config.raUrl + `/esg/${gameId}/updateCluesOrHints`, data)
			.then((res) => {
				setESGFormData(prev => {
                    return {
                        ...prev,
                        during: {
                            ...(prev.during || {}),
                            [input.getAttribute('id')]: value
                        }
                    }
                })
			}).catch((err) => {
					console.log("error caught: ", err)
			})
    }
   
  return (
        <Container fluid>

            {subFormType === 0 && (
                <>
                    {/* <Row className="select-row">
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className={size.width > 576 ? "col-left" : "meeting-col"}>
                            <Button className="during-btn" onClick={() => setSubFormType(1)} variant="light" block>
                                Setup Error
                                <div className="during-counter">
                                    {setupErrorCount}
                                </div>
                            </Button>
                        </Col>  

                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className={size.width > 576 ? "col-right" : "meeting-col"}>
                            <Button className="during-btn"  onClick={() => setSubFormType(3)} variant="light" block>
                                Room Entry
                                <div className="during-counter">
                                    {roomEntriesCount}
                                </div>
                            </Button>
                        </Col> 
                    </Row> */}

                    <Row>
                        <Col xs={12} className={"meeting-col mb-4"}>
                            <div className={"mb-1 text-center"}>Clues</div>
                            <NumericInput
                                id="clues_count"
                                mobile
                                className="form-control"
                                onChange={updateCluesOrHints}
                                min={0}
                                value={ESGFormData?.during?.clues_count || 0}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} className={"meeting-col mb-4"}>
                            <div className={"mb-1 text-center"}>Hints</div>
                            <NumericInput
                                id="hints_count"
                                mobile
                                className="form-control"
                                onChange={updateCluesOrHints}
                                min={0}
                                value={ESGFormData?.during?.hints_count || 0}
                            />
                        </Col>
                    </Row>

                    <Row className="select-row">
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className={size.width > 576 ? "col-left" : "meeting-col"}>
                            <Button className="during-btn" onClick={() => setSubFormType(12)} variant="light" block>
                                Memorable<br />Moment
                                <div className="during-counter">
                                    {memorableMomentCount}
                                </div>
                            </Button>
                        </Col>  

                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className={size.width > 576 ? "col-right" : "meeting-col"}>
                            <Button className="during-btn"  onClick={() => setSubFormType(13)} variant="light" block>
                                Something<br />Went Wrong
                                <div className="during-counter">
                                    {wrongCount}
                                </div>
                            </Button>
                        </Col> 
                    </Row>
                </>
            )}

            {/* {subFormType === 1 && (
                <SetupError 
                    setSetupErrorCount={setSetupErrorCount}
                    setupErrorCount={setupErrorCount}
                    setSubFormType={setSubFormType}
                    handleFormSubmit={handleFormSubmit}
                    showLoading={showLoading}
                    selectOptions={selectOptions}
                />
            )}

            {subFormType === 3 && (
                <RoomEntry 
                    setRoomEntriesCount={setRoomEntriesCount}
                    roomEntriesCount={roomEntriesCount}
                    setSubFormType={setSubFormType}
                    handleFormSubmit={handleFormSubmit}
                    showLoading={showLoading}
                    selectOptions={selectOptions}
                />
            )} */}

            {subFormType === 12 && (
                <MemorableMoment 
                    setMemorableMomentCount={setMemorableMomentCount}
                    memorableMomentCount={memorableMomentCount}
                    setSubFormType={setSubFormType}
                    handleFormSubmit={handleFormSubmit}
                    showLoading={showLoading}
                />
            )}

            {subFormType === 13 && (
                <SomethingWentWrong 
                    setWrongCount={setWrongCount}
                    wrongCount={wrongCount}
                    setSubFormType={setSubFormType}
                    handleFormSubmit={handleFormSubmit}
                    showLoading={showLoading}
                    selectOptions={selectOptions}
                />
            )}

        </Container>
  );
}

export default DuringGame
