/* eslint-disable */

import React, { useState, useEffect } from "react";

// Packages
import UiFx from "uifx";
import { INITIAL_STATE, TEG_APP } from "../../lib/constants";
import { fetchGameAndSetGameMedia, getCookie, PLAYER_COOKIE_PREFIX, validatePlayer } from "../../lib/data";

// Styles
import "./PlayerDashboard-v1.scss";
import GoldRush from "../../Components/Play/Themes/GoldRush";
import TheHeist from "../../Components/Play/Themes/TheHeist";
import Ruins from "../../Components/Play/Themes/Ruins";
import PrisonBreak from "../../Components/Play/Themes/PrisonBreak";
import TruthSeekers from "../../Components/Play/Themes/TruthSeekers";
import ProctorAndGamble from "../../Components/Play/Themes/ProctorAndGamble";
import Depths from "../../Components/Play/Themes/Depths";


// Audio Imports
import inventoryAudio from "../../audio/inventory_sound.mp3";
import clueAudio from "../../audio/clue_sound.wav";
import scanAudio from "../../audio/scan_sound.wav";
import PlayerAuthModule from "../../Components/Play/PlayerAuthModule/PlayerAuthModule";

const inventoryUploadAudio = new UiFx(inventoryAudio, {
  volume: 0.25,
});
const clueSendAudio = new UiFx(clueAudio, {
  volume: 0.25,
});
const scanSendAudio = new UiFx(scanAudio, {
  volume: 0.25,
});

let socket;

const PlayerDashboard = (props) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const customTheme = params.get('theme');
  const gameId = props.match.params.game;
  const playState = props.playState
  const theme = props.theme
  const socket = props.socket
  const [clue, setClue] = useState("");
  const [gameMedia, setGameMedia] = useState(INITIAL_STATE.GAME_MEDIA);
  const [scans, setScans] = useState([]);
  const [sounds, setSounds] = useState([]);
  const [videos, setVideos] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [game, setGame] = useState(gameId);
  const [isLoading, setIsLoading] = useState(true);
  const [audio, setAudio] = useState('');
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isAuthLoading, setAuthLoading] = useState(true);

  const hydrateFromServer = (stateFromServer) => {
    console.log(stateFromServer, 'state from server');
    let state = stateFromServer;
    if (typeof stateFromServer === 'string')
      state = JSON.parse(stateFromServer);

    if (state) {
      if (state.inventory) setInventory(state.inventory);
      if (state.scans) setScans(state.scans);
      if (state.activeClue) setClue(state.activeClue);
      if (state.videos) setVideos(state.videos);
      if(state.sounds) setSounds(state.sounds);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    hydrateFromServer(playState);
    fetchGameAndSetGameMedia(gameId, setGameMedia);
  }, [playState, gameId]);

  useEffect(() => {
    socket.on("clue", (clue) => {
      setClue(clue);
      clueSendAudio.play();
      console.log(`Clue received from TEG: ${clue}.`);
    });
  }, [clue]);

  useEffect(() => {
    socket.on("scan-add", (scan) => {
      setScans((scans) => [...scans, scan]);
      scanSendAudio.play();
      console.log(`Received scan from TEG: ${scan}.`);
      console.log(scans);
    });

    socket.on("scan-remove", (mediaId) => {
      setScans((scans) => scans.filter((scan) => scan !== mediaId));
      console.log(`Received scan removal from TEG: ${mediaId}.`);
      console.log(scans);
    });
  }, []);

  useEffect(() => {
    socket.on("sound-add", (soundItem) => {
      const sound = soundItem.id;
      setAudio(soundItem.data.src);
      const soundElement = document.getElementById('sound-player');
      soundElement.setAttribute('src', soundItem.data.src);
      soundElement.play().then(() => {
      }).catch(() => {
        if (confirm("Click ok to play sounds!")) {
          soundElement.play()
        } else {
          soundElement.play()
        }
      });
      setSounds((sounds) => [...sounds, sound]);
      console.log(`Received sound from TEG: ${sound}.`);
      console.log(sounds);
    });

    socket.on("sound-remove", (mediaId) => {
      setSounds((sounds) => sounds.filter((sound) => sound !== mediaId));
      console.log(`Received sound removal from TEG: ${mediaId}.`);
      console.log(sounds);
      const soundElement = document.getElementById('sound-player');
      soundElement.pause();
    });
  }, []);

  useEffect(() => {
    socket.on("video-add", (video) => {
      setVideos((videos) => [...videos, video]);
      console.log(`Video received from TEG: ${video}.`);
    });

    socket.on("video-remove", (mediaId) => {
      setVideos((videos) => videos.filter((video) => video !== mediaId));
      console.log(`Video received from TEG: ${mediaId}.`);
    });
  }, []);

  useEffect(() => {
    socket.on("inventory-add", (item) => {
      //setInventory([ ...inventory, item ]);
      setInventory((inventory) => [...inventory, item]);
      inventoryUploadAudio.play();
      console.log(`Received inventory from TEG: ${item}.`);
    });

    socket.on("inventory-remove", (inventoryId) => {
      setInventory((inventory) =>
        inventory.filter((item) => item !== inventoryId)
      );
      console.log(`Received scan removal from TEG: ${inventoryId}.`);
    });
  }, []);

  useEffect(() => {
    checkAuthFromCookies()
  }, [])

  const checkAuthFromCookies = async () => {
    const [authGameId, authGamePass] = getCookie(`${PLAYER_COOKIE_PREFIX}_auth`) ? getCookie(`${PLAYER_COOKIE_PREFIX}_auth`).split('_') : [undefined, undefined];
    const host_token = getCookie(`host_token`);
    if((authGameId && (gameId === authGameId))) {
      const gameRes = await validatePlayer(authGameId, authGamePass, host_token);
      if(gameRes.success) {
        setAuthenticated(true);
      }
    } else if (host_token && host_token !== '') {
      const hostLogin = await validatePlayer(gameId, '', host_token);
      if(hostLogin.success) {
        setAuthenticated(true);
      }
    }
    setAuthLoading(false);
  }

  if (isAuthLoading || isLoading) {
    return (
      <div className="loading">
        <div className="lds-dual-ring"></div>
      </div>
    );
  }

  if(!isAuthenticated) {
    return <PlayerAuthModule gameId={gameId} setAuthenticated={setAuthenticated} theme={theme} />
  }

  return (
    <div>
      <div>
        <audio id='sound-player' controls={false} loop={false} style={{'display':"none"}}>
          <source src={audio}/>
        </audio>
      </div>
      {(() => {
        if (isLoading) {
          return (
            <div className="loading">
              <div className="lds-dual-ring"></div>
            </div>
          );
        }
        const themeProps = {
          gameId,
          clue,
          inventory,
          videos,
          theme,
          game,
          gameMedia,
          isLoading,
          socket,
          scans,
          sounds
        }
        switch (theme) {
          case 'gold-rush':
            return <GoldRush {...themeProps}></GoldRush>;
          case 'the-heist':
            return <TheHeist {...themeProps}> </TheHeist>;
          case 'ruins-forbidden-treasure':
            return <Ruins {...themeProps}></Ruins>;
          case 'ruins':
            return <Ruins {...themeProps}></Ruins>;
          case 'prison-break':
            return <PrisonBreak {...themeProps}></PrisonBreak>;
          case 'truth-seekers':
            return <TruthSeekers {...themeProps}></TruthSeekers>;
          case 'proctor-and-gamble':
            return <ProctorAndGamble {...themeProps}></ProctorAndGamble>
          case 'depths':
            return <Depths {...themeProps}></Depths>
          // default:
          //   return <h5>No theme match found. Please provide correct theme!</h5>
        }
      })()}
    </div>
  );
};

export default PlayerDashboard;
