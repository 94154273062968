import {mergeMap, map, catchError} from 'rxjs/operators';
import { from } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import {getQueryString} from '../../common/utils/helper';
import {
 LIST_BOOKINGS, ASSIGN_BOOKING, RESEND_INVITE
} from '../actions/bookingActions';
import {bookingSuccess, bookingError, roomSuccess, roomError} from '../actionCreators';
import {config} from '../../enviroment';
import {getCookie} from '../../common/utils/auth';
import {LIST_ROOMS} from "../actions";
const header = { 'Content-Type': 'application/json' };
const getAuth = () => {
  return {
    ...header,
    'Authorization': `Bearer ${getCookie('token')}`
  }
}

export const listBookingEpic = action$ => action$.pipe(
  ofType(LIST_BOOKINGS),
  mergeMap(action =>
    ajax.getJSON(`${config.raUrl}/events?${getQueryString(action.payload)}`, getAuth()).pipe(
      map(response => bookingSuccess(response)),
      catchError(error => Promise.resolve(bookingError({error: error.response})))
    )
  ),
)


export const assignBookingEpic = action$ => action$.pipe(
  ofType(ASSIGN_BOOKING),
  mergeMap((action) => from(new Promise((resolve, reject) => {
    ajax.put(`${config.raUrl}/events/${action.payload.eventId}/assign`, action.payload, getAuth())
      .subscribe((userResponse) => {
        resolve(userResponse.response);
        action.payload.onComplete(userResponse.response);
      }, (err) => {
        action.payload.onError(err.response);
        reject(err.response);
      })
  })).pipe(
    map(response =>  bookingSuccess(response)),
    catchError(error => Promise.resolve(bookingSuccess(error)))
  ))
)

export const resendInviteEpic = action$ => action$.pipe(
  ofType(RESEND_INVITE),
  mergeMap((action) => from(new Promise((resolve, reject) => {
    ajax.put(`${config.raUrl}/events/${action.payload.eventId}/resend/invite`, action.payload, getAuth())
      .subscribe((userResponse) => {
        resolve(userResponse.response);
        action.payload.onComplete(userResponse.response);
      }, (err) => {
        reject(err.response);
      })
  })).pipe(
    map(response =>  roomSuccess(response)),
    catchError(error => Promise.resolve(roomError(error)))
  ))
)
