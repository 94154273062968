/* eslint-disable */
import React, { useState } from 'react'

// Styles
import './PlayerClueModule.scss'
import { Row, Button } from 'react-bootstrap'

const PlayerClueModule = (props) => {
  const [clueRequest, setClueRequest] = useState('clue-request')

  const sendClueRequest = (e) => {
    e.preventDefault()

    if (clueRequest) {
      props.socket.emit('clue-request', clueRequest, () => {
        console.log(`Clue request sent to TEG: ${clueRequest}`)
      })
    }
  }

  return (
    <div>
      <Row>
        <Button className="clue-button" value={clueRequest} onClick={(e) => sendClueRequest(e)}>
          I NEED A CLUE
        </Button>
      </Row>
      <Row>
        <div className="clue-box">{props.clue}</div>
      </Row>
    </div>
  )
}

export default PlayerClueModule
