import React, {useEffect, useState} from 'react';
import axios from 'axios'
import {useDispatch, useSelector} from 'react-redux';
import { Row, Col, Table, Button, Container, Card, ButtonGroup, DropdownButton, Dropdown, Toast } from 'react-bootstrap';
import Select from 'react-select';
import Drawer from './Drawer';
import Header from './Header';
import './ATCDashboard.scss';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faSpinner, faCaretDown, faCaretUp, faSort } from '@fortawesome/free-solid-svg-icons'
import {listBookings} from '../../redux/actionCreators';
import Pagination from 'rc-pagination';
import {config} from 'enviroment.js'
import {getQueryString} from 'common/utils/helper.js'
import {getCookie} from 'common/utils/auth'

import 'rc-pagination/assets/index.css';
import {formatAMPM} from '../../common/utils/time';
import moment from 'moment';


const ATCDashboard = (props) => {
  const [toast, setToast] = useState({
    show: false,
    msg: null
  })
  const [dateFilter, setDateFilter] = useState({
    start: '',
    end: '',
    bookingStart: '',
    bookingEnd: ''
  });
  const [selectedEvent, setSelectedEvent] = useState({});
  const [visible, setVisible] = useState(true);
  const [rowIndex, setRowIndex] = useState(-1);
  const bookingState = useSelector(state => state.bookingReducer);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    start: '',
    end: '',
    bookingStart: '',
    bookingEnd: '',
    type: 'pending-any',
    limit: 10,
    offset: 0,
    page: 1,
    sortBy: 'starttime',
    order: 'ASC'
  });


  // new state
  const [bookings, setBookings] = useState([])
  const [totalBookings, setTotalBookings] = useState(null)
  const [unassignedCount, setUnassignedCount] = useState(null)
  const [stores, setStores] = useState(null)
  const [loadingEvents, setLoadingEvents] = useState(false)
  const [loading, setLoading] = useState({bookings:false, notify:[], transfer:[]})


  useEffect(() => {
    setLoadingEvents(true)
    axios.get(`${config.raUrl}/events/?${getQueryString(filters)}`, {
      headers: {
        'Authorization': `Bearer ${getCookie('token')}`,
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      setBookings(res.data.events)
      setTotalBookings(res.data.total)
      setUnassignedCount(res.data.unassignedCount)
      setLoadingEvents(false)
    })
  }, [filters])

  useEffect(() => {
    axios.get(`${config.raUrl}/stores/`, {
      headers: {
        'Authorization': `Bearer ${getCookie('token')}`,
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      setStores(res.data.stores)
    })
  }, [])

  // useEffect(() => {
  //   if(rowIndex !== -1 && bookings.length > 0 && loading.bookings === false) {
  //     setSelectedEvent(bookings[rowIndex]);
  //   }
  // }, [rowIndex, bookings, loading.bookings])

  const onRowClick = (index, event) => {
    setVisible(true);
    setSelectedEvent(event);
    setRowIndex(index);
  }


  const onPageChange = (page) => {
    const filter = {
      search: filters.search,
      type: filters.type,
      start: filters.start,
      end: filters.end,
      bookingStart: filters.bookingStart,
      bookingEnd: filters.bookingEnd,
      limit: 10,
      offset: (page -1) * filters.limit,
      page: page,
      sortBy: filters.sortBy,
      order:  filters.order
    }
    setFilters(filter);
  }


  const transferEvent = (eventObj, callback) => {
    axios.put(`${config.raUrl}/events/${eventObj.eventId}/assign/`, {
      eventId: eventObj.eventId,
      storeId: eventObj.storeId,
      roomId: eventObj.roomId
    }, {
      headers: {
        'Authorization': `Bearer ${getCookie('token')}`,
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      axios.get(`${config.raUrl}/events/?${getQueryString(filters)}`, {
        headers: {
          'Authorization': `Bearer ${getCookie('token')}`,
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        setBookings(res.data.events)
        setTotalBookings(res.data.total)
        setUnassignedCount(res.data.unassignedCount)
        callback()
      })
    }).catch(err => {
      console.log(err.response)
      callback(err.response.data)
    })
  }

  const notifyEvent = (eventObj, callback) => {
    axios.put(`${config.raUrl}/events/${eventObj.eventId}/notify/`, {
      eventId: eventObj.eventId,
      storeId: eventObj.storeId,
      roomId: eventObj.roomId
    }, {
      headers: {
        'Authorization': `Bearer ${getCookie('token')}`,
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      axios.get(`${config.raUrl}/events/?${getQueryString(filters)}`, {
        headers: {
          'Authorization': `Bearer ${getCookie('token')}`,
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        setBookings(res.data.events)
        setTotalBookings(res.data.total)
        setUnassignedCount(res.data.unassignedCount)
        callback()
      })
    }).catch(err => {
      console.log(err.response)
      callback(err.response.data)
    })
  }

  
  const onFilterChange = (e) => {
    const filter = {
      ...filters
    }
    filter.type = e.target.value;
    filter.offset = 0;
    filter.limit = 10;
    filter.page = 1;
    setFilters(filter);
    setSelectedEvent({});
  }

  const updateListInBackground = () => {
    setBookings()
    dispatch(listBookings(filters));
  }


  const getFormattedDate = (date, col) => {
    if(col === 'start' || col === 'bookingStart') {
      return moment(date).startOf('day').utc().format()
    }
    if(col === 'end' || col === 'bookingEnd') {
      return moment(date).endOf('day').utc().format()
    }
  }

  const getEasyToReadDate = (date, col) => {
    if(col === 'start' || col === 'bookingStart') {
      return moment(date).format("MM/DD/YYYY")
    }
    if(col === 'end' || col === 'bookingEnd') {
      return moment(date).format("MM/DD/YYYY");
    }
  }

  const onSortChange = (sortColumn) => {
    const filter = {
      ...filters
    }
    filter.sortBy = sortColumn;
    if (filter.order === '') {
      filter.order = 'ASC';
    } else if (filter.order === 'ASC') {
      filter.order = 'DESC';
    } else if (filter.order === 'DESC') {
      filter.order = '';
      filter.sortBy = '';
    }
    setFilters(filter);
  }

  const getSortIcon = (col) => {
    if (col === filters.sortBy && filters.order === 'ASC') {
      return faCaretUp
    } else if (col === filters.sortBy && filters.order === 'DESC') {
      return faCaretDown
    } else {
      return faSort
    }
  }


  const onDateRangeChange = (e, picker) => {
    const dateValues = {...dateFilter};
    const filter = {...filters}
    setSelectedEvent({});
    if(picker.startDate) {
      filter['start'] = getFormattedDate(picker.startDate, 'start');
      dateValues['start'] = picker.startDate;
      filter['clearArrival'] = false;
    }
    if(picker.endDate) {
      filter['end'] = getFormattedDate(picker.endDate, 'end');
      dateValues['end'] = picker.endDate;
      dateValues['clearArrival'] = false;
    }
    if(picker.bookingStart) {
      filter['bookingStart'] = getFormattedDate(picker.bookingStart, 'bookingStart');
      dateValues['bookingStart'] = picker.bookingStart;
      dateValues['clearBooking'] = false;
    }
    if(picker.bookingEnd) {
      filter['bookingEnd'] = getFormattedDate(picker.bookingEnd, 'bookingEnd');
      dateValues['bookingEnd'] = picker.bookingEnd;
      dateValues['clearBooking'] = false;
    }
    if(picker.clearArrival) {
      filter['start'] = '';
      filter['end'] = '';
      dateValues['clearArrival'] = true;
    }
    if(picker.clearBooking) {
      filter['bookingStart'] = '';
      filter['bookingEnd'] = '';
      dateValues['clearBooking'] = true;
    }
    setDateFilter(dateValues);
    setFilters(filter);
  }

  const onClearArrivalDate = () => {
    onDateRangeChange({}, {clearArrival: true});
  }

  const onClearBookingDate = () => {
    onDateRangeChange({}, {clearBooking: true});
  }

  const onBookingRangeChange = (e, picker) => {
    onDateRangeChange(
      {},
      { bookingStart: picker.startDate, bookingEnd: picker.endDate }
    );
  }

  return (
    <div style={{position: 'relative'}}>
      <Toast className="alert-dismissible" onClose={() => setToast({...toast, show:false})} show={toast.show} delay={5000} autohide>
        <Toast.Body>{toast.msg}</Toast.Body>
        <button onClick={() => setToast({...toast, show:false})} type="button" class="close">
          <span aria-hidden="true">×</span>
          <span class="sr-only">Close alert</span>
        </button>
      </Toast>
      <Header></Header>
      <Container fluid className="content">
        <Row>
          <Col>
            <div className="flex-content">
              <div className="partial-view">
                <br />
                <Row>
                  <Col md={2}>
                    <Card>
                      <Card.Body className="stats-box">
                        <h5>{unassignedCount}</h5>
                        <p className="paragraph-text">Unassigned</p>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={10}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col lg={6}>
                            <Row>
                              <Col lg={2} xl={1} sm={2} xs={1}>
                                <div className="d-inline">
                                  <FontAwesomeIcon className="calender-icon" size="2x" icon={faCalendar}/>
                                </div>
                              </Col>
                              <Col lg={10} xl={11} sm={10} xs={11}>
                                <div className="d-inline">
                                  <DateRangePicker onApply={onDateRangeChange} onCancel={onClearArrivalDate}
                                    initialSettings={{ locale: {autoUpdateInput: false, cancelLabel: "Clear", }, }}
                                  >
                                    <input placeholder={"Arrival"} type="text" className="form-control"
                                      onChange={() => {}}
                                      value={dateFilter.clearArrival === undefined || dateFilter.clearArrival === true
                                        ? ""
                                        : `${getEasyToReadDate(dateFilter.start, "start")} - ${getEasyToReadDate(dateFilter.end, "end")}`
                                      }
                                    />
                                  </DateRangePicker>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6}>
                            <select name="filter" defaultValue="pending-any" className="form-control" onChange={(e) => onFilterChange(e)} >
                              <option value="">All</option>
                              <option value="assigned">Assigned</option>
                              <option value="pending-all">Not Transferred Or Notified</option>
                              <option value="pending-any">Pending</option>
                              <option value="pending-notify">Transferred But Not Notified</option>
                              <option value="unassigned">Unassigned</option>
                              <option value="recentlyAssigned">Recently Assigned</option>
                              <option value="skipped">Skipped</option>
                            </select>
                          </Col>
                        </Row>
                        {/*Second Row*/}
                        <Row bsPrefix="row mt-3">
                          <Col lg={6}>
                            <Row>
                              <Col lg={2} xl={1} sm={2} xs={1}>
                                <div className="d-inline">
                                  <FontAwesomeIcon className="calender-icon" size="2x" icon={faCalendar} />
                                </div>
                              </Col>
                              <Col lg={10} xl={11} sm={10} xs={11}>
                                <div className="d-inline">
                                  <DateRangePicker onApply={onBookingRangeChange} onCancel={onClearBookingDate}
                                    initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: "Clear", }, }}
                                  >
                                    <input placeholder={"Booking"} type="text" className="form-control" onChange={() => {}}
                                      value={dateFilter.clearBooking === undefined || dateFilter.clearBooking === true
                                        ? ""
                                        : `${getEasyToReadDate(dateFilter.bookingStart, "start")} - ${getEasyToReadDate(dateFilter.bookingEnd, "end")}`
                                      }
                                    />
                                  </DateRangePicker>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Table hover size="sm" className="appointments-list">
                  <thead>
                    <tr>                      
                      <th className="cursor-pointer" onClick={() => onSortChange("createdOn")} >
                        Booking {' '}<FontAwesomeIcon size="1x" icon={getSortIcon("createdOn")}/>
                      </th>
                      <th className="cursor-pointer" onClick={() => onSortChange("starttime")} >
                        Arrival {' '}<FontAwesomeIcon size="1x" icon={getSortIcon("starttime")} />
                      </th>

                      <th className="cursor-pointer" onClick={() => onSortChange("title")} >
                        Experience {' '}<FontAwesomeIcon size="1x" icon={getSortIcon("title")} />
                      </th>
                      <th>Store</th>
                      <th>Room</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {loadingEvents
                      ? <div><FontAwesomeIcon size="1x" spin={true} icon={faSpinner} /></div>
                      : bookings.map((event, index) => (
                          <EventRow 
                            key={index}
                            onRowClick={onRowClick}
                            event={event}
                            index={index}
                            stores={stores}
                            transferEvent={transferEvent}
                            notifyEvent={notifyEvent}
                            selectedEvent={selectedEvent}
                            setToast={setToast}
                          />
                        ))
                    }
                  </tbody>
                </Table>
                <div className="text-right">
                  {loadingEvents === false ? (
                    <Pagination
                      className="pagination-section"
                      total={totalBookings}
                      current={filters.page}
                      pageSize={filters.limit}
                      onChange={(page) => onPageChange(page)}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="drawer-wrapper">
                {visible ? (
                  <Drawer refreshEventsList={updateListInBackground} event={selectedEvent} />
                ) : (
                  ""
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default ATCDashboard;


const EventRow = (props) => {
  const {event, index, stores, transferEvent, onRowClick, notifyEvent, selectedEvent, setToast} = props
  const [roomError, setRoomError] = useState(null)
  const [roomValue, setRoomValue] = useState(null)
  const [eventRooms, setEventRooms] = useState([])
  const [storeError, setStoreError] = useState(null)
  const [storeValue, setStoreValue] = useState(null)
  const [loadingTransferBtn, setLoadingTransferBtn] = useState(false)
  const [loadingNotifyBtn, setLoadingNotifyBtn] = useState(false)

  const getStoreOptions = () => {
    return stores.map((store) => {
      return { value: store.title, label: store.title, id: store.storeId }
    })
  }

  useEffect(() => {
    setStoreValue((prev) => {
      if (event.atcEventId) return {
        value: event.atcStoreTitle,
        label: event.atcStoreTitle,
        id: event.atcStoreId
      }
      return null
    })
    setRoomValue((prev) => {
      if (event.atcEventId) return {
        value: event.atcExperienceName,
        label: event.atcExperienceName,
        id: event.atcExperienceId
      }
      return null
    })
  }, [event])

  const onRoomSelect = (room) => {
    setRoomValue(room)
    setRoomError(null)
  }

  const onStoreSelect = (store) => {
    setStoreValue(store)
    setStoreError(null)
    axios.get(`${config.raUrl}/experiences/${store.id}`, {
      headers: {
        'Authorization': `Bearer ${getCookie('token')}`,
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      setEventRooms(res.data.experiences.map((exp) => {
        return { value: exp.name, label: exp.name, id: exp.experiences_id }
      }))
    })
    
  }

  const onTransferClick = (e) => {
    if (!storeValue) {
      setStoreError("Select Store!")
      return
    }
    if (!roomValue) {
      setRoomError("Select Room!")
      return
    }
    setLoadingTransferBtn(true)
    const eventObj = {
      eventId: event.eventId,
      storeId: storeValue,
      roomId: roomValue
    }
    transferEvent(eventObj, function(data) {
      if (data) setRoomError(data.message)
      if (!data) console.log("Event transferred successfully!")
      setLoadingTransferBtn(false)
    })
  }

  const onSkipClick = (e) => {
    console.log('register skip click')
  }

  const onNotifyClick = (e) => {
    console.log('register notify click')
    if (event.atcStatus !== 2) {
      setRoomError("Must transfer event before sending notification!")
      return
    }
    setLoadingNotifyBtn(true)
    const eventObj = {
      eventId: event.eventId,
      storeId: storeValue,
      roomId: roomValue
    }
    notifyEvent(eventObj, function(data) {
      if (data) setRoomError(data.message)
      if (!data) setToast({show: true, msg: "Notification sent successfully!"})
      setLoadingNotifyBtn(false)
    })
  }

  const onEventClick = (e, index) => {
    onRowClick(index, event)
  }

  return (
    <tr onClick={(e) => onEventClick(e, index)} className={selectedEvent?.eventId === event.eventId ? "bg-info" : ""}>

      <td>
        {`${new Date(event.createdOn).getMonth() + 1
        }/${new Date(event.createdOn).getDate()}/${new Date(event.createdOn).getFullYear()}`}{" "}
        {formatAMPM(new Date(event.createdOn))}
      </td>

      <td>{moment(event.starttime).utc().format("MM/DD/YYYY hh:mm a")}</td>

      <td>{event.title}</td>

      <td>
        <div className={`text-left ${storeError && "error-box"}`}>
          <Select value={storeValue} onChange={onStoreSelect} options={getStoreOptions()} />
          <span className="text-danger">{storeError}</span>
        </div>
      </td>

      <td>
        <div className={`text-left ${roomError && "error-box"}`}>
          <Select value={roomValue} onChange={onRoomSelect} options={eventRooms} />
          <span className="text-danger">{roomError}</span>
        </div>
      </td>

      <td>
        {event.atcStatus === 1 && (
          <DropdownButton variant="outline-secondary" as={ButtonGroup} id="bg-vertical-dropdown-1"
            title={loadingTransferBtn ? <FontAwesomeIcon size="1x" spin={true} icon={faSpinner} /> : "Transfer"}
          >
            <Dropdown.Item eventKey="2" onClick={onTransferClick}>
              Transfer
            </Dropdown.Item>
            <Dropdown.Item eventKey="3" onClick={onSkipClick}>
              Skip
            </Dropdown.Item>
          </DropdownButton>
        )}
        {event.atcStatus === 2 && (
          <Button variant="outline-secondary" onClick={onTransferClick}>
            {loadingTransferBtn ? <FontAwesomeIcon size="1x" spin={true} icon={faSpinner} /> : "Re-Transfer" }
          </Button>
        )}
        {event.atcStatus === 3 && (
          <DropdownButton variant="outline-secondary" as={ButtonGroup} id="bg-vertical-dropdown-1"
            title={loadingTransferBtn ? <FontAwesomeIcon size="1x" spin={true} icon={faSpinner} /> : "Skipped"}
          >
            <Dropdown.Item eventKey="2" onClick={onTransferClick}>
              Transfer
            </Dropdown.Item>
          </DropdownButton>
        )}
      </td>

      <td>
        {event.atcNotifyStatus === 1 && (
          <Button variant="outline-secondary" onClick={onNotifyClick} >
            {loadingNotifyBtn ? (<FontAwesomeIcon size="1x" spin={true} icon={faSpinner} />) : ("Notify")}
          </Button>
        )}
        {event.atcNotifyStatus === 2 && (
          <Button variant="outline-secondary" onClick={onNotifyClick} >
            {loadingNotifyBtn ? (<FontAwesomeIcon size="1x" spin={true} icon={faSpinner} />) : ("Re-Notify")}
          </Button>
        )}
        {event.atcNotifyStatus === 3 && (
          <DropdownButton variant="outline-secondary" as={ButtonGroup} id="bg-vertical-dropdown-1"
            title={loadingNotifyBtn ? (<FontAwesomeIcon size="1x" spin={true} icon={faSpinner} />) : ("Skipped")}
          >
            <Dropdown.Item eventKey="2" onClick={onNotifyClick}>
              Notify
            </Dropdown.Item>
          </DropdownButton>
        )}
      </td>

    </tr>
  )
}
