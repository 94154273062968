import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App';
import { Provider } from 'react-redux';
import store from './redux'
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: "https://4054bfbc73964a7a9fea3c88158c8853@o598822.ingest.sentry.io/5743379",
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // set between 0.0 and 1.0
  tracesSampleRate: 0.0,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications'
  ]
});

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}> <App></App></Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
