/* eslint-disable */
import React, {useState, useEffect} from 'react'
import mixpanel from "lib/mixpanel"

// Styles
import './GuideInventoryModule.scss'
import Button from 'react-bootstrap/Button'


const GuideInventoryModule = (props) => {
  const {currentInventory, inventory = [], setInventory, socket, title = 'Inventory', tracking} = props
  const toggleInventory = (e, value) => {
    const currentTarget = e.currentTarget
    if (value) {
      if (currentInventory.includes(value)) {
        socket.emit('inventory-remove', value, () => {
          setInventory(currentInventory.filter((inventoryId) => inventoryId !== value))
        })
      } else {
        socket.emit('inventory-add', value, () => {
          setInventory([...currentInventory, value])
          const titleMapping = {
            "Inventory": "inventory",
            "Locks": "lock",
            "Founded Toys": "founded toys"
          }
          const mixpanelTracking = {
            ...tracking,
            "item_id": value,
            "item_name": currentTarget.querySelector('.item-name')?.innerText,
            "item_type": titleMapping[title]
          }
          mixpanel.track('Item Sent', mixpanelTracking)
        })
      }
    }
  }

  return (
    <div className="inventory-list">
      <h2>{title}</h2>
      <div className="inventory-list-items inventory-item-carousel">
        {!inventory.length && <p>There are no items to display</p>}
        {inventory &&
          inventory.map((item) => {
            const classes = `guide-inventory-item-button ${currentInventory.includes(item.id) ? " item-sent" : ""
              }`;

            return (
              <Button
                key={item.id}
                value={item.id}
                className={classes}
                onClick={(e) => toggleInventory(e, item.id)}
              >
                {item.data &&
                  <img
                    className="guide-inventory-item-image"
                    src={item.data.src}
                  />
                }
                {item.internalName ? <p className="item-name">{item.internalName}</p> : null}
              </Button>
            );
          })}
      </div>
    </div>
  );
}

export default GuideInventoryModule
