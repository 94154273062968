import io from "socket.io-client";
import { TEG_APP } from "../lib/constants";

let CarnivalSocket;

export const connectSocket = () => {
  CarnivalSocket = io(TEG_APP.ENDPOINT);
};

export const getSocetId = () => {
  return CarnivalSocket.id;
};

export const joinCarnivalGame = (gameId) => {
  let user = JSON.parse(localStorage.getItem("userInfo"))
  CarnivalSocket.emit("join_carnival", gameId, user?.firstName, () =>
    console.log(`Sent join emission to server.`)
  );
};

export const getUpdatedState = (GameScene) => {
  CarnivalSocket.on("state_update", (data) => {
    GameScene.updateVisibleCursors(data.usersList)
    GameScene.updateScoreBoard(data.targetsHit)
    return data;
  });
};

export const sendCursorUpdate = (cursorData) => {
  CarnivalSocket.emit("send_cursor_update", cursorData);
};

export const addTargetShot = (targetId, targetName, targetNumber) => {
  CarnivalSocket.emit("add_target_hit", {
      targetId,
      targetName,
      targetNumber
  });
};

export const targetGotShot = (GameScene) => {
  CarnivalSocket.on("target_got_hit", (data) => {
    GameScene.targetHitListener(data)
  });
};

export const increaseScore = (score) => {
  CarnivalSocket.emit("increase_user_score", score);
};


export const disconnect = () => {
  if (!CarnivalSocket) return true;

  CarnivalSocket.removeAllListeners();
  CarnivalSocket.disconnect();
};

