import React from "react"
import logo from "images/rugrats-logo.png"
import ClueButton from 'Components/Play/Headers/ClueButton.js'


const HeaderRugrats = (props) => {
  const { onClickZoom, playState, socket, tracking, theme} = props
  const downloadLink = "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/rugrats/zoom-backgrounds/search-for-the-losted-toys-backgrounds.zip"
  const virtualBackgrounds = "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/client/rugrats/virtual-backgrounds-image-link.png"

  return (
    <div id="header">
      <img className="logo" alt="Rugrats" src={logo} />
      <div className="right">
        <a className="zoomDownloadLink desktop" href={downloadLink} onClick={onClickZoom}>
          <img className="zoom" alt="Download Zoom Background" src={virtualBackgrounds} />
        </a>
        <ClueButton playState={playState} socket={socket} tracking={tracking} theme={theme} />
      </div>
    </div>
  )
}

export default HeaderRugrats
