import React, {useState, useEffect} from "react";

// Default Backgrounds
import DefaultBgGoldRush from "../../../images/gr_background.png";
import DefaultBgHeist from "../../../images/heist_background.jpg";
import DefaultBgPrisonBreak from "../../../images/pb_background.png";
import DefaultBgRuins from "../../../images/ruins_background.jpg";
import DefaultBgDepths from "../../../images/depths_background.png";

// Vday Backgrounds
import VdayBgGoldRush from "../../../images/gr_background_vday.png";
import VdayBgPrisonBreak from "../../../images/pb_background_vday.png";

// Other
import CustomLogoTopic from "../../../images/custom_logo_topic.png";
import VdayHeartsLeft from "../../../images/hearts-left.png";
import VdayHeartsRight from "../../../images/hearts-right.png";


const Backgrounds = {
    "default": {
        "gold-rush": DefaultBgGoldRush,
        "the-heist": DefaultBgHeist,
        "prison-break": DefaultBgPrisonBreak,
        "ruins-forbidden-treasure": DefaultBgRuins,
        "depths": DefaultBgDepths,
    },
    "vday": {
        "gold-rush": VdayBgGoldRush,
        "prison-break": VdayBgPrisonBreak
    }
}

const NOW = new Date()

const Events = [
  // remember - month is zero indexed
  {
      "name": "vday",
      "when": [new Date(NOW.getFullYear(), 1, 12, 17, 0, 0), new Date(NOW.getFullYear(), 1, 15, 0, 0, 0)],
  }
]

export const getBackground = (theme) => {
    let activeEvent = getEvent();
    return Backgrounds[activeEvent][theme] || Backgrounds["default"][theme];
}

export const getEvent = () => {
    let currentEvent = "default";
    Events.every( (event, idx) => {
      if (NOW >= event["when"][0] && NOW <= event["when"][1]) {
        currentEvent = event["name"];
        return false;
      } else {
        return true;
      }
    })
    return currentEvent;
}

export const VdayHearts = () => {
    let activeEvent = getEvent()
    return (
        <>
        {activeEvent === "vday" &&
            <>
            <img src={VdayHeartsRight} style={{position:"absolute", top: "-10%", right: "-1%", width: "20%", zIndex: 2}} alt="Valentine's Day Hearts" />
            {/* <img src={VdayHeartsLeft} style={{position:"absolute", bottom: "-10%", left: "-1%", width: "20%", zIndex: 2}} alt="Valentine's Day Hearts" /> */}
            </>
        }
        </>
    )
}

export const CustomGraphic = (props) => {
    // custom graphic positions
    // viewer-top-right - (default position if placement is not provided) graphic will be absolutely positioned in top right of media viewer
    // locks-right - graphic will be absolutely positioned on the right of locks module
    const { eventId = "", placement = "viewer-top-right" } = props
    const activeEvent = getEvent()
    const topicEventIds = ["603410af230d0b6228b282ea", "602bff05230d0b62289100f1", "602bfccc230d0b62288c6eef"]
    const ibmEventIds = [
        "60be76e082cc3763692d1805",
        "60be746c82cc37636925e3d4",
        "60be73f082cc376369249a35",
        "60be75bd82cc37636929bc48",
        "60be973d82cc376369887086",
        "60be980982cc3763698adb1e",
        "60be764f82cc3763692b5496",
        "60be74bb82cc37636926ddb9",
        "60be760882cc3763692a9124",
    ]

    const [windowWidth, setWindowWidth] = useState(null)

    useEffect(() => {
        const updateWindowWidthState = () => {
            setWindowWidth(window.innerWidth)
        }
        if (ibmEventIds.includes(eventId)) {
            updateWindowWidthState()
            window.addEventListener('resize', updateWindowWidthState)
        }
        return () => window.removeEventListener('resize', updateWindowWidthState)
    }, [ibmEventIds, eventId])

    if (topicEventIds.includes(eventId)) {
        return (
            <img src={CustomLogoTopic} style={{position:"absolute", top: "-6%", right: "-1%", width: "20%", zIndex: 2}} alt="Valentine's Day Hearts" />
        )
    } else if (ibmEventIds.includes(eventId)) {
        const imageSrc = "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/assets/images/client/ibm/IBM-logo.png"
        if (placement  === "viewer-top-right") {
            return (
                <img src={imageSrc} style={{position:"absolute", top: "-5%", right: "-.5%", width: "20%", maxWidth: '200px', zIndex: 2}} alt="IBM" />
            )
        } else if (placement === "locks-right") {
            if (windowWidth >= 768) {
                return (
                    <img src={imageSrc} style={{position:"absolute", top: "50%", right: "-150px", width: "200px", transform: "translateY(-30%)", zIndex: 2}} alt="IBM" />
                )
            } else {
                return (
                    <img src={imageSrc} style={{position:"absolute", top: "-5%", right: "-.5%", width: "20%", maxWidth: '200px', zIndex: 2}} alt="IBM" />
                )
            }
        }
    } else if (activeEvent === "vday") {
        return (
            <img src={VdayHeartsRight} style={{position:"absolute", top: "-10%", right: "-1%", width: "20%", zIndex: 2}} alt="Valentine's Day Hearts" />
        )
    } 
    return (
        <></>
    )
}
