/* eslint-disable */
import React, {useState} from 'react'
import {
    Row,
    Col,
    Button,
    InputGroup,
    FormControl,
    Spinner
} from "react-bootstrap";
import FormSelect from "../FormSelect";
import '../GuideSideBar.scss';

const SomethingWentWrong = (props) => {
    const { wrongCount, setWrongCount, setSubFormType, showLoading, handleFormSubmit, selectOptions } = props
    const [formData, setFormData] = useState({type_id: 13})
    const [formErrors, setFormErrors] = useState()

    const validateForm = (formData) => {
        if (!formData.reason) return "reason"
        if (!formData.description.trim()) return "description"
        if (!formData.activity_impact) return "activity_impact"
        return null
    }

    const formChange = (field, value) => {
        setFormData({...formData, [field]: value})
        setFormErrors(null)
    }

    const updateType = (choice) => {
        formChange('reason', choice)
    }

    const updateImpact = (choice) => {
        formChange('activity_impact', choice)
    }

    const submitForm = async (e) => {
        e.preventDefault();
        const hasErrors = validateForm(formData)
        if (hasErrors) {
            setFormErrors(hasErrors)
            return
        }
        formData['reason'] = JSON.stringify([formData.reason.value])
        formData['activity_impact'] = JSON.stringify([formData.activity_impact.value])
        handleFormSubmit(formData)
        setWrongCount(wrongCount + 1)
        setFormData(null)
        setSubFormType(0)
    }
   
  return (
        <form onSubmit={submitForm}>
            <Row>
                <Col className="heading-label">
                    Add Something Went Wrong
                </Col>
            </Row>
            <Row className="select-row">
                <Col className="meeting-col">
                    <FormSelect 
                        options={selectOptions.ESG_WRONG_TYPE}
                        updateStateFunction={updateType}
                        value={formData.reason}
                        label="Type*"
                        error={formErrors === "reason" && "This field is required"}
                    />
                </Col>  
            </Row> 
            
            <Row className="select-row">
                <Col className="meeting-col">
                    <div className="select-label">Description*</div>
                    {formErrors === "description" &&
                        <div className="invalid-feedback d-block">This field is required</div>
                    }
                    <InputGroup>
                        <FormControl required as="textarea" aria-label="With textarea"
                            onChange={e => formChange('description', e.target.value)}
                            value={formData.description}
                        />
                    </InputGroup>
                </Col>  
            </Row>

            <Row className="select-row">
                <Col className="meeting-col">
                    <FormSelect 
                        options={selectOptions.ESG_WRONG_IMPACT}
                        updateStateFunction={updateImpact}
                        value={formData.activity_impact}
                        label="Impact on Guest Experience*"
                        error={formErrors === "activity_impact" && "This field is required"}
                    />
                </Col>  
            </Row> 

            <Row className="select-row last-row">
                <Col className="meeting-col">
                    <Button className={"cancel-form-btn"} onClick={() => setSubFormType(0)} variant="light">
                        Cancel
                    </Button>
                    <Button type="submit" className={"game-state-btn"} variant="light" disabled={showLoading}>
                        Submit {' '}
                        {showLoading && (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                    </Button>
                </Col>  
            </Row>

        </form>
  );
}

export default SomethingWentWrong
