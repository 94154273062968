/* eslint-disable */
import React, { useEffect, useState } from 'react'
import io from 'socket.io-client'
import { useLocation } from 'react-router-dom'
import { INITIAL_STATE, TEG_APP } from '../../lib/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons'
import { fetchGameAndSetGameMedia, fetchAssets, verifyHost, getGameData, setCookie, fetchTrackingData } from '../../lib/data'
import { config } from 'enviroment.js'
import UiFx from "uifx"
import clueAudio from "audio/clue_sound.wav"
import mixpanel from "lib/mixpanel"
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'

// Styles
import './GuideDashboard.scss'
import { Container, Row, Col, Navbar, Button, Popover, OverlayTrigger, InputGroup, FormControl } from 'react-bootstrap'

// Components
import ESGSideBar from "../../Components/Guide/GuideSideBar/ESGSideBar";
import useWindowSize from "../../utils/useWindowSize";
import GuideTimer from 'Components/Timer/GuideTimer'
import LegacyGrid from 'Pages/GuideDashboard/LegacyGrid'
import ItemGrid from 'Pages/GuideDashboard/ItemGrid'

// Images
import raLogo from "../../images/ra_logo.png";

let socket

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const clueRequestAudio = new UiFx(clueAudio, {
  volume: 0.25,
});

const GuideDashboard = (props) => {
  const gameId = props.match.params.game
  const query = useQuery();
  const [gameMedia, setGameMedia] = useState(INITIAL_STATE.GAME_MEDIA)
  const [items, setItems] = useState([])
  const [rooms, setRooms] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const [filter, setFilter] = useState(0)
  const [inventory, setInventory] = useState([])
  const [scans, setScans] = useState([])
  const [theme, setTheme] = useState('')
  const [videos, setVideos] = useState([])
  const [minigames, setMinigames] = useState([])
  const [sounds, setSounds] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [isUserAuthorized, setUserAuthorized] = useState(true)
  const [isGameActive, setIsGameActive] = useState(null)
  const [openPanelnfo, setOpenPanelnfo] = useState(false);
  const [openPanelESG, setOpenPanelESG] = useState(false);
  const [pushPanelToTop, setPushPanelToTop] = useState(false);
  const [initialESGFormData, setInitialESGFormData] = useState({})
  const [isClueRequested, setIsClueRequested] = useState(false)
  const [timerEvents, setTimerEvents] = useState()
  const [tracking, setTracking] = useState()
  // const [clueRequestStatus, setClueRequestStatus] = useState('idle');
  const { esgForm, itemGrid, miniGames } = useFlags()
  const ldClient = useLDClient()

  const size = useWindowSize();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (gameId) {
      fetchTrackingData(gameId, setTracking)
    }
  }, [gameId])

  useEffect(() => {
    if (tracking && ldClient) {
      ldClient.identify({
        key: 'remote-adventures',
        anonymous: true,
        custom: {
          event_id: tracking?.event_id,
          x_event_id: tracking?.x_event_id,
          experience_id: tracking?.experience_id,
          store_id: tracking?.store_id,
          theme: tracking?.theme
        }
      }, null, () => {
        setLoading(false)
      })
    }
  }, [tracking]);

  function handleScroll() {
    const pos = document.body.scrollHeight - document.documentElement.scrollTop;
    const curr_pos = pos - document.documentElement.offsetHeight;

    if (curr_pos < -20 ) {
      setPushPanelToTop(true);
    }
    else if (curr_pos > -20 ) {
      setPushPanelToTop(false);
    }
  }

  const hydrateFromServer = (stateFromServer) => {
    let state = stateFromServer;
    if(typeof state === 'string') {
      state = JSON.parse(stateFromServer)
    }

    if (state) {
      setInventory(state.inventory || [])
      setScans(state.scans || [])
      setVideos(state.videos || [])
      setSounds(state.sounds || [])
      setIsGameActive(typeof state.isGameActive === 'undefined' ? true : state.isGameActive)
      setInitialESGFormData(state.esg || {})
      setIsClueRequested(state.isClueRequested || false)
      setTimerEvents(state.timerEvents || [])
    }
  }

  const validateHostData = async (eventId, pass) => {
    const { isValid, token } = await verifyHost(eventId, pass);
    if(isValid) {
      setCookie('host_token', token);

      socket = io(TEG_APP.ENDPOINT)

      socket.emit('join', gameId, () => console.log(`Sent join emission to server.`))
      socket.on("clue-requested", () => {
        setIsClueRequested(true)
        clueRequestAudio.play()
      });  
      socket.on('play-state', ({ playState }) => {
        hydrateFromServer(playState);
        setTheme(playState.theme_class);
      })
      const assets = await fetchGameAndSetGameMedia(gameId, setGameMedia, itemGrid)
      if (itemGrid) {
        setItems(assets.items)
        setRooms(assets.rooms)
        setFilter(assets.rooms[0].roomId)
      }
      // setLoading(false)
    } else {
      // setLoading(false)
      setUserAuthorized(false)
    }
    return isValid;
  }

  useEffect(() => {
    if (!isLoading) {
      if (query.get('auth') && query.get('auth') !== '') {
        validateHostData(gameId, query.get('auth'));
      }else {
        setLoading(false)
        setUserAuthorized(false)
      }   
    }
    
    return () => {
      if (socket) {
        socket.emit('disconnect')
        socket.off()
      }
    }
  }, [TEG_APP.ENDPOINT, gameId, isLoading])

  useEffect(() => {
    if (items) {
      const filteredItems = filter === 0 ? items : items.filter(item => item.roomId === parseInt(filter))
      let uniqueFilteredItems =  [ ...new Set(filteredItems.map(obj => obj.id )) ].map(id => {
        return { id: id, ...filteredItems.find(s => s.id === id)}
      })
      if (!miniGames) {
        uniqueFilteredItems = uniqueFilteredItems.filter(item => item.item_type !== 'minigame')
      }
      setFilteredItems(uniqueFilteredItems)
    }
  }, [items, filter, miniGames])


  if(isLoading) {
    return (
      <div className="loading">
        <div className="lds-dual-ring"></div>
      </div>
    );
  }

  if(!isUserAuthorized) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <p>Logo</p>
          </Col>
        </Row>
        <Row>You are not authorized to view this page.</Row>
      </Container>
    );
  }

  const itemCardStyles = (item) => {
    let bgColor = "#f5f3f3"
    let shadow = "unset"
    if (["inventory", "lock"].includes(item.item_type)) {
      if (inventory.includes(item.id)) {
        bgColor = "#efd4d4"
        shadow = "0 .5rem 1rem rgba(0,0,0,.15)"
      }
    }
    if (item.item_type === "video") {
      if (videos.includes(item.id)) {
        bgColor = "#efd4d4"
        shadow = "0 .5rem 1rem rgba(0,0,0,.15)"
      }
    }
    if (item.item_type === "scan") {
      if (scans.includes(item.id)) {
        bgColor = "#efd4d4"
        shadow = "0 .5rem 1rem rgba(0,0,0,.15)"
      }
    }
    if (item.item_type === "minigame") {
      if (minigames.includes(item.id)) {
        bgColor = "#efd4d4"
        shadow = "0 .5rem 1rem rgba(0,0,0,.15)"
      }
    }
    return {
      width:"100%",
      maxWidth:"18%",
      cursor: "pointer",
      margin: "1%",
      backgroundColor: bgColor,
      boxShadow: shadow
    }
  }

  const changeFilter = (e) => {
    const newFilter = parseInt(e.target.value)
    if (newFilter !== filter) setFilter(parseInt(e.target.value))
  }

  const toggleAsset = (item) => {
    if (item) {
      const itemType = ["inventory", "lock"].includes(item.item_type) ? "inventory" : item.item_type
      const itemObj = { id: item.id, action: ""}
      const oldItemArray = eval(itemType === "inventory" ? itemType : itemType + "s")
      let newItemArray = []
      if (oldItemArray.includes(item.id)) {
        itemObj.action = itemType + "-remove"
        newItemArray = oldItemArray.filter((id) => id !== item.id)
      } else {
        itemObj.action = itemType + "-add"
        newItemArray = [...oldItemArray, item.id]
      }
      socket.emit('toggle-asset', itemObj, () => {
        if (itemType === "inventory") {
          setInventory(newItemArray)
        } else if (itemType === "scan") {
          setScans(newItemArray)
        } else if (itemType === "video") {
          setVideos(newItemArray)
        } else if (itemType === "minigame") {
          setMiniGames(newItemArray)
        }
      })
    }
  }
        
  const togglePostGame = (evt) => {
    socket.emit('toggle-post-game', isGameActive, () => {
      setIsGameActive(!isGameActive)
      mixpanel.track('End Game', { ...gameMedia.tracking })
    })
  }

  const toggelESGPanel = () => {
    setOpenPanelnfo(false)
    setOpenPanelESG(!openPanelESG)
  }

  const clearClue = () => {
    if (isClueRequested) {
			const mixpanelTracking = {
				...gameMedia.tracking
			}
			mixpanel.track('Clue Request Fulfilled', mixpanelTracking)
      socket.emit('clear-clue-request', () => {
        setIsClueRequested(false)
      })
    }
  }

  return (
    <div className={(openPanelESG || openPanelnfo) && size.width > 768 ? "col-8 p-0" : "col-12 p-0"} id="page-content-wrapper" >
    <Button className={`clue-requested-btn${isClueRequested ? " show" : ""}`} onClick={clearClue}>
      <img src="https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/clue.png"/>
      <div className="text">
        <p>Clue Requested!</p>
        <span>Click to Clear</span>
      </div>
    </Button>
    <Navbar bg="dark" className="justify-content-between">
      <Navbar.Brand>
        <img
          src={raLogo}
          width="90"
          className="d-inline-block"
          alt="Remote Adventures"
        />
        {theme !== 'mbs' && isGameActive !== null &&
          <Button variant={isGameActive ? "danger" : "success"} style={{marginLeft:'20px'}} onClick={togglePostGame}>
            {isGameActive ? "End Game" : "Reopen Game"}
          </Button>
        }
      </Navbar.Brand>
      <div className="d-flex align-items-center">
        <GuideTimer timerEvents={timerEvents} socket={socket} />
        <PlayerPasswordInfo playerPassword={gameMedia.playerPassword} gameId={gameId} />
        {esgForm &&
          <Button className="game-info-btn left-mar" onClick={() => toggelESGPanel()} variant="light">
            e
          </Button>
        }
      </div>
    </Navbar>

    <ESGSideBar
      pushPanelToTop={pushPanelToTop}
      setOpenPanel={setOpenPanelESG}
      password={gameMedia.playerPassword}
      gameId={gameId}
      initialESGFormData={initialESGFormData}
      openPanelESG={openPanelESG}
      starttime={gameMedia.starttime}
    />
    
    {itemGrid ? (
      <ItemGrid
        rooms={rooms}
        filter={filter}
        changeFilter={changeFilter}
        filteredItems={filteredItems}
        itemCardStyles={itemCardStyles}
        toggleAsset={toggleAsset}
      />
    ) : (
      <LegacyGrid
        gameMedia={gameMedia}
        socket={socket}
        inventory={inventory}
        setInventory={setInventory}
        theme={theme}
        scans={scans}
        videos={videos}
        sounds={sounds}
        minigames={minigames}
        setScans={setScans}
        setVideos={setVideos}
        setSounds={setSounds}
        setMinigames={setMinigames}
      />
    )}


    </div>
  );
}

export default GuideDashboard


const PlayerPasswordInfo = (props) => {

  const playerURL = `${config.clientUrl}/play/${
    props.gameId
  }?auth=${props.playerPassword}`;

  const gameInfoPopover = playerPasswordPopover({
    password: props.playerPassword,
    dashboardUrl: playerURL,
  });

  return (
    <div className="position-relative">
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={gameInfoPopover}
        rootClose
      >
        <Button className="game-info-btn" variant="light">
          i
        </Button>
      </OverlayTrigger>
    </div>
  );
};

const playerPasswordPopover = (props) => {
  const [isCopying, setCopying] = useState({});

  const textToCopy = {
    url: props.dashboardUrl,
    pass: props.password,
    both: `To access your player dashboard, please visit: ${props.dashboardUrl}`,
  };

  const onCopy = (x) => {
    navigator.clipboard.writeText(textToCopy[x]);

    setCopying({ ...isCopying, [x]: true });

    setTimeout(() => {
      setCopying({ ...isCopying, [x]: false });
    }, 2000);
  };

  return (
    <Popover className="password-popover" id="popover-basic">
      <Popover.Title as="h3">Game Info</Popover.Title>
      <Popover.Content>
        <div className="info-heading">Player Dashboard:</div>
        <InputGroup className="mb-3">
          <FormControl
            disabled={false}
            aria-label="Player Dashboard Link"
            aria-describedby="basic-addon2"
            value={props.dashboardUrl}
            onFocus={(e) => e.target.select()}
            onChange={(e) => e.target.select()}
          />
          <InputGroup.Append>
            <Button onClick={() => onCopy("url")} variant="outline-secondary">
              <FontAwesomeIcon icon={isCopying["url"] ? faCheck : faCopy} />
            </Button>
          </InputGroup.Append>
        </InputGroup>
        <Row>
          <Col md={{ span: 10, offset: 1 }} >
            <Button
              onClick={() => onCopy("both")}
              className="copy-btn"
              variant="outline-secondary"
            >
              {isCopying["both"] ? "Copied!" : "Copy Instructions"}
            </Button>
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  );
};