import Phaser from "phaser";
import * as BallSortSocket from "../ballSortSocket";

export default class Ball extends Phaser.GameObjects.Sprite {
    constructor(scene, x, y, sprite) {
      super(scene, x, y, sprite);
      this.scene = scene;
      this.xVal = x;
      this.yVal = y;
      this.sprite = sprite;
      this.alpha=0.5;
      this.onTube=false;
      this.tube = '';
      this.isDragging=false;
      this.userSocketId=null;
      this.setInteractive();
      scene.input.setDraggable(this);
      scene.add.existing(this);
  
      this.on("pointerover", () => {
        this.alpha=1;
      });
      this.on("pointerout", () => {
        this.alpha=0.5;
      });
      this.on("pointerdown", function(pointer,gameObjectX,gameObjectY) {
        this.x=pointer.x;
        this.y=pointer.y;
        this.userSocketId=BallSortSocket.getSocetId();
      });

      this.on('drag', function (pointer, dragX, dragY) {
      // scene.input.on('drag', function (pointer, gameObject, dragX, dragY) {
        // console.log(gameObject) // call the drag point here 
        this.isDragging=true;
        this.x = pointer.x;
        this.y = pointer.y;    
        BallSortSocket.userStartedDraggingBall(this.sprite)
      });

      this.on('dragend', function (pointer, dragX, dragY) {
      // scene.input.on('dragend', function (pointer, gameObject, dragX, dragY) {
        this.ballDrop();
        BallSortSocket.userStoppedDraggingBall(this.sprite)
      });
    }

    ballDrop()
    {
      this.isDragging=false;
      // if (BallSortSocket.getSocetId() === this.userSocketId) {
        if(!this.onTube)
        {
          this.x=this.xVal;
          this.y=this.yVal;
        }
        else
        {

          this.tube.dropBall(this.texture.key);
          this.x=this.xVal;
          this.y=this.yVal;
          this.onTube=false;
          // add_ball_to_tube
          console.log("ball drop", this)
          BallSortSocket.addBallToTube(this.tube.id, this.sprite)
        }
      // }
    }

  }
  