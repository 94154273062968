/* eslint-disable */
import React, {useState} from 'react'
import Select from 'react-select';
  
// Styles
import './GuideSideBar.scss'

const FormSelect = (props) => {
  
    let {
        options,
        label,
        isMulti,
        updateStateFunction,
        value,
        defaultValue,
        error
    } = props
   
  return (
        <>
            <div className="select-label">{label}</div>
            {error &&
              <div className="invalid-feedback d-block">{error}</div>
            }
            <Select
                isMulti={isMulti}
                value={value}
                onChange={updateStateFunction}
                options={options}
                theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#eee',
                      primary: 'black',
                    },
                })}
            />
            {/* <input
              tabIndex={-1}
              autoComplete="off"
            //   className="hidden-"
              style={{ opacity: 0, height: 0, float: "left", padding: 0 }}
              value={value}
              required={true}
            /> */}
        </>
  );
}

export default FormSelect
