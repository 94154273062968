/* eslint-disable */
import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {
    Container,
    Row,
    Col,
    Form,
    InputGroup,
    FormControl,
  } from "react-bootstrap";
import FormSelect from "./FormSelect";
import { config } from "enviroment.js";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

  
// Styles
import './GuideSideBar.scss';

const AfterGame = (props) => {
    let {
        gameId,
        ESGFormData,
        setESGFormData,
        selectOptions,
        actualStartTime,
    } = props
    const [actualEndTime, setActualEndTime] = useState()
    const [whyNoReviewText, setWhyNoReviewText] = useState()
    const [totalGameTime, setTotalGameTime] = useState()
    
	const format = 'YYYY-MM-DD HH:mm:ss'; 

	useEffect(() => {
        if (ESGFormData?.after?.actual_end) setActualEndTime(ESGFormData?.after?.actual_end)
		if (ESGFormData?.after?.review_ask_no_reason) setWhyNoReviewText(ESGFormData?.after?.review_ask_no_reason)
    }, [ESGFormData])
    
	useEffect(() => {
        const timeOutId = setTimeout(() => updateWhyNoReview(whyNoReviewText), 1000);
        return () => clearTimeout(timeOutId);
    }, [whyNoReviewText]);

	const updateAfterForm = (updatedESGFormData) => {
		axios.post(config.raUrl + `/esg/${gameId}/updateAfterForm`, updatedESGFormData.after)
			.then((res) => {
				setESGFormData(updatedESGFormData)
			}).catch((err) => {
					console.log("error caught: ", err)
			})
	}
    
	const updateGameResult = (e) => {
		let updatedESGFormData = {...ESGFormData}
		if (!updatedESGFormData.after) updatedESGFormData.after = {}
		updatedESGFormData.after.game_result = e.value
		updateAfterForm(updatedESGFormData)
    }
    
	const updateActualEndState = (value) => {
		if (value) {
			setActualEndTime(value)
		} else {
			setActualEndTime(null)
		}
    }
    
    useEffect(() => {
        if (actualStartTime && actualEndTime) {
            const startTime = moment(actualStartTime)
            const endTime = moment(actualEndTime)
            const duration = moment.duration(endTime.diff(startTime))
            const hours = parseInt(duration.asHours())
            const minutes = parseInt(duration.asMinutes()) % 60
            setTotalGameTime(hours + ':' + minutes.toString().padStart(2, '0'))
        }
    }, [actualStartTime, actualEndTime])

	const updateActualEnd = () => {
		if (actualEndTime && ESGFormData?.after?.actual_end !== actualEndTime) {
			let updatedESGFormData = {...ESGFormData}
			if (!updatedESGFormData.after) updatedESGFormData.after = {}
			updatedESGFormData.after.actual_end = actualEndTime
			updateAfterForm(updatedESGFormData)
		}
	}

	const updateRating = (e) => {
		let updatedESGFormData = {...ESGFormData}
		if (!updatedESGFormData.after) updatedESGFormData.after = {}
		updatedESGFormData.after.experience_rating = e.value
		updateAfterForm(updatedESGFormData)
    }

	const updateParticipation = (e) => {
		let updatedESGFormData = {...ESGFormData}
		if (!updatedESGFormData.after) updatedESGFormData.after = {}
		updatedESGFormData.after.participation = e.value
		updateAfterForm(updatedESGFormData)
    }

	const updateReviewAsk = (e) => {
		let updatedESGFormData = {...ESGFormData}
		if (!updatedESGFormData.after) updatedESGFormData.after = {}
		updatedESGFormData.after.review_ask = e.value
		updateAfterForm(updatedESGFormData)
    }

	const updateReviewPlatform = (e) => {
		let updatedESGFormData = {...ESGFormData}
		if (!updatedESGFormData.after) updatedESGFormData.after = {}
		updatedESGFormData.after.review_platform = e.value
		updateAfterForm(updatedESGFormData)
    }

    const updateWhyNoReview = (whyNoReviewText) => {
		let updatedESGFormData = {...ESGFormData}
		if (!updatedESGFormData.after) updatedESGFormData.after = {}
		updatedESGFormData.after.review_ask_no_reason = whyNoReviewText
		updateAfterForm(updatedESGFormData)
	}


	const updateReplayPitched = (e) => {
		let updatedESGFormData = {...ESGFormData}
		if (!updatedESGFormData.after) updatedESGFormData.after = {}
		updatedESGFormData.after.replay_pitched = e.value
		updateAfterForm(updatedESGFormData)
    }
    
	const getGameResult = (gameResultState) => {
		return (selectOptions.ESG_GAME_RESULT || []).filter(gameResult => {
			return gameResult.value === gameResultState
		})
	}

	const getRating = (ratingState) => {
		return (selectOptions.ESG_EXPERIENCE_RATING || []).filter(rating => {
			return rating.value === ratingState
		})
	}

	const getParticipation = (participationState) => {
		return (selectOptions.ESG_PARTICIPATION || []).filter(participation => {
			return participation.value === participationState
		})
	}

	const getReviewAsk = (reviewAskState) => {
		return (selectOptions.ESG_REVIEW_ASK || []).filter(reviewAsk => {
			return reviewAsk.value === reviewAskState
		})
	}

	const getReviewPlatform = (reviewPlatformState) => {
		return (selectOptions.ESG_REVIEW_PLATFORM || []).filter(reviewPlatform => {
			return reviewPlatform.value === reviewPlatformState
		})
	}

	const getReplayPitched = (replayPitchedState) => {
		return (selectOptions.ESG_PITCH_REPLAY || []).filter( replayPitched => {
			return replayPitched.value === replayPitchedState
		})
	}
   
  return (
    <form>
        <Container fluid >
            <Row className="select-row">
                <Col className="meeting-col">
                    <FormSelect 
                        options={selectOptions.ESG_GAME_RESULT}
                        updateStateFunction={updateGameResult}
                        value={getGameResult(ESGFormData?.after?.game_result || null)}
                        label="Game Result*"
                        isMulti={false}
                    />
                </Col>  
            </Row> 

            <Row className="select-row">
                <Col className="meeting-col">
                    <div className="select-label">
                        Time Game Ended 
                    </div>
                    <TimePicker
                        showSecond={false}
                        onChange={updateActualEndState}
                        onClose={updateActualEnd}
                        use12Hours
                        inputReadOnly
                        value={actualEndTime ? moment(actualEndTime) : null}
                    />
                </Col>  
                {totalGameTime &&
                    <Col className="meeting-col">
                        <div className="select-label" style={{textAlign: 'center'}}>
                            Total Game Time 
                        </div>
                        <div style={{textAlign: 'center'}}>{totalGameTime}</div>
                    </Col>
                }
            </Row>

            <Row className="select-row">
                <Col className="meeting-col">
                    <FormSelect 
                        options={selectOptions.ESG_EXPERIENCE_RATING}
                        updateStateFunction={updateRating}
                        value={getRating(ESGFormData?.after?.experience_rating || null)}
                        label="How do you think these guests would rate their experience?*"
                        isMulti={false}
                    />
                </Col>  
            </Row> 

            <Row className="select-row">
                <Col className="meeting-col">
                    <FormSelect 
                        options={selectOptions.ESG_PARTICIPATION}
                        updateStateFunction={updateParticipation}
                        value={getParticipation(ESGFormData?.after?.participation || null)}
                        label="Did everyone actively participate in the game?*"
                        isMulti={false}
                    />
                </Col>  
            </Row> 

            <Row className="select-row">
                <Col className="meeting-col">
                    <FormSelect 
                        options={selectOptions.ESG_REVIEW_ASK}
                        updateStateFunction={updateReviewAsk}
                        value={getReviewAsk(ESGFormData?.after?.review_ask || null)}
                        label="Did we ask this team for a review?*"
                        isMulti={false}
                    />
                </Col>  
            </Row> 

            {ESGFormData?.after?.review_ask === 'YES' &&
                <Row className="select-row">
                    <Col className="meeting-col">
                        <FormSelect 
                            options={selectOptions.ESG_REVIEW_PLATFORM}
                            updateStateFunction={updateReviewPlatform}
                            value={getReviewPlatform(ESGFormData?.after?.review_platform || null)}
                            label="Which review platform?*"
                            isMulti={false}
                        />
                    </Col>  
                </Row>
            }

            {ESGFormData?.after?.review_ask === 'NO' &&
                <Row className="select-row">
                    <Col className="meeting-col">
                    <div className="select-label">Why did we not ask for a review?*</div>
                    <InputGroup>
                        <FormControl required as="textarea" aria-label="With textarea"
                            onChange={e => setWhyNoReviewText(e.target.value)}
                            value={whyNoReviewText}
                        />
                    </InputGroup>
                    </Col>  
                </Row>
            }

            <Row className="select-row">
                <Col className="meeting-col">
                    <FormSelect 
                        options={selectOptions.ESG_PITCH_REPLAY}
                        updateStateFunction={updateReplayPitched}
                        value={getReplayPitched(ESGFormData?.after?.replay_pitched || null)}
                        label="Did we pitch a replay?*"
                        isMulti={false}
                    />
                </Col>  
            </Row> 

            {/* <Row className="select-row last-row">
                <Col className="meeting-col">
                    <InputGroup >
                        <Form.Group required>
                            <Form.File 
                                onChange={(e) => setGamePhoto(e.target.files[0])}
                                label="Upload The End-Game Photo"
                                accept=".png,.jpg,.jpeg,.webp"
                            />
                        </Form.Group>
                    </InputGroup>
                </Col>  
            </Row>  */}

        </Container>
    </form>
  );
}

export default AfterGame
