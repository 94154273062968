import React from "react"
import PlayerTimer from 'Components/Timer/PlayerTimer.js'
import ClueButton from 'Components/Play/Headers/ClueButton.js'


const HeaderV3 = (props) => {
  const { themeSettings, onClickZoom, socket, playState, tracking, theme } = props
  const tegLogo = "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/teg-logo.png"
  const zoomIcon = "https://s3.us-east-2.amazonaws.com/remoteadventures.theescapegame.com/themes/zoom-icon.png"

  return (
    <div id="header">
      <div className="left" style={themeSettings.headerLeftImageStyles} >
        <img className="teg-logo" alt="The Escape Game" src={themeSettings.headerLeftImage || tegLogo} />
      </div>
      {themeSettings.headerCenterImage &&
        <div className="center">
          <img alt="Custom Logo" src={themeSettings.headerCenterImage}/>
        </div>
      }
      <div className="right">
        <a className="zoomDownloadLink" href={themeSettings.zoomBackgrounds} onClick={onClickZoom}>
          <img alt="Zoom" src={zoomIcon}/>
          <span>Download<br/>Virtual Backgrounds</span>
        </a>
        <ClueButton playState={playState} socket={socket} tracking={tracking} theme={theme}/>
        <PlayerTimer socket={socket} timerEvents={playState.timerEvents} />
      </div>
    </div>
  )
}

export default HeaderV3
